import { Component } from "react";
import { MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, Card, CardHeader, CardBody, Table, TableHead, TableBody, MDBIcon, MDBInput } from "mdbreact";
import {ProductsAPI, SettingsAPI, MultichannelAPI} from "../../Api/connect";
//import MarketplaceValidator from "../../validators/marketplaces/marketplaces";
import ConnectImages from "../../components/images";
//import Images from "../../components/images";

class CreateListings extends Component {

state = {
    activeStep: 1,
    warning: {
        validate: false,
        publish: false
    },
    products: [],
    perPage: 50,
    perSearch: 300,
    searchQuery: "",
    searchedProducts: [],
    selectedProducts: [],
    activeListings: [],
    selectedDistributor: [],
    settings: {},
    marketplaceSettings: {},
    isPublishing: false
}

async componentDidMount() {
    this.getProducts();
    this.getListings();
    try {
        const settings = await SettingsAPI.getSettings();
        const marketplaceInfos = await MultichannelAPI.getMarketplaceInfos({
            marketplaces: Object.keys(settings.settings.marketplaces)
        });
        console.log(marketplaceInfos);
        this.setState({settings: settings.settings, marketplaceInfos})
    } catch (error) {
        console.log(error)
    }
   
}

getProducts = async () => {
    try {
        const products = await ProductsAPI.getProducts({limit: this.state.perPage});
        this.setState({products})
    } catch (error) {
        console.log(error)
    } 
}

async getListings() {
    try {
        const activeListings = await MultichannelAPI.getListings();
        this.setState({activeListings})
    } catch (error) {
        console.log(error)
    } 
}

publishProducts = async () => {
    try {
        let marketplaceSettings = {};
        this.state.selectedMarketplaces.forEach(m => marketplaceSettings[m] = this.state.marketplaceSettings[m])
        const b = {
            marketplaceSettings,
            productIds: this.state.selectedProducts.map(p => p.id)
        };
        this.setState({isPublishing: true})
        const res = await MultichannelAPI.publishProducts(b);
        console.log(res)
        this.setState({
            publishResponse : res,
            searchedProducts: []
        })
        this.getProducts();
    this.getListings();
    } catch (error) {
        console.log(error)
    }
}

changeSearchQuery = e => {
    const {value} = e;
    if(e.key === 'Enter' && this.state.searchQuery.length > 2) {
        this.searchProducts()
    } else {
        this.setState({
            searchQuery: value
        })
    }
}

changeMarketplaceSettings = (marketplace, option, value) => {
    if (this.state.marketplaceSettings[marketplace] 
        && this.state.marketplaceSettings[marketplace][option] 
        && this.state.marketplaceSettings[marketplace][option] === value
        ) {
    } else {
        this.setState(state => ({
            marketplaceSettings: {
                ...state.marketplaceSettings,
                [marketplace]: {
                    ...state.marketplaceSettings[marketplace],
                    [option]: value
                }
            }
        }))
    }
}

searchProducts = async (e) => {
    const {value} = e.target;
    if(e.key === 'Enter'){
        if(value.length < 1) {
            this.setState({
                searchQuery: ""
            })
            this.getProducts()
        } else {
            this.setState({
                searchQuery: value
            })
            try {
                const b = {
                    q: value,
                    limit: this.state.perSearch
                };
                const searchedProducts = await ProductsAPI.searchProducts(b)
                this.setState(state => ({
                    searchedProducts: searchedProducts,
                    products: searchedProducts
                }))
            } catch (error) {
                console.log(error)
            }
        }

    }
}


selectProduct = (id, selected) => {
    console.log(selected)
    if (selected) {
        this.setState(state => ({
            selectedProducts: [...state.selectedProducts.filter(p => p.id !== id)]
        }))
    } else if (this.state.selectedProducts.filter(p => p.id === id).length < 1){
        this.setState(state => ({
            selectedProducts: [
                ...state.selectedProducts,
                this.state.products.filter(p => p.id === id)[0]
            ]
        }))
    }
}

selectMarketplace = marketplace => {
    if (this.state.selectedMarketplaces.indexOf(marketplace) >= 0) {
        // Remove
        this.setState(state => ({
            selectedMarketplaces: state.selectedMarketplaces.filter(m => m !== marketplace)
        }))
    } else {
        // Add
        this.setState(state => ({
            selectedMarketplaces: [
                ...state.selectedMarketplaces,
                marketplace
            ]
        }))
    }
}


renderProducts(products) {
    return products.map(product => {
        const isSelected = this.state.selectedProducts.filter(p => p.id === product.id).length > 0;
        //const matchingListing = this.state.activeListings.filter(l => l.productId === product.id);
        const stock = product.stock && Object.values(product.stock).reduce((a, b) => a + b);
        return ({
            img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
            sku: product.sku,
            name: product.name,
            stock: <span style={{color: stock > 0 ? "black": "red"}}>{stock}</span>,
            button: <MDBBtn
            className="my-0"
            color={isSelected ? " teal white-text" : " grey white-text"}
            size="sm" id={product.id.toString()}
            onClick={() => this.selectProduct(product.id, isSelected)}>Select</MDBBtn>

        })
                });

}

validateProducts(products, marketplaces) {
        // const warnings = 0;
        return products.map(product => ({
                img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
                sku: product.sku,
                name: product.name,
                stock: product.stock && Object.values(product.stock).reduce((accumulator, currentValue) => accumulator + currentValue),
                qty: <div className="mx-auto" style={{maxWidth: "100px"}}><MDBInput type="number" valueDefault="0" /></div>
        }));
      
}

renderPublishProducts(products, marketplaces) {
    // const warnings = 0;
    return products.map(product => ({
            img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
            sku: product.sku,
            name: product.name,
            stock: product.stock && Object.values(product.stock).reduce((accumulator, currentValue) => accumulator + currentValue),
            status: marketplaces.map(m => (
                <p>
                    {ConnectImages[m]}
                    {!this.state.publishResponse ? "" : this.state.publishResponse[m].responses.filter(r => r.productId === product.id)[0].status === 200 ? <MDBIcon className="green-text ml-3" icon="check" /> : <MDBIcon className="red-text ml-3" icon="times-circle" />}
                </p>
            ))
    }));
  
}



changeActiveStep = a => {
    this.setState({activeStep: a})
}

render() {
    const data_checkboxes = {
        columns: [
          {
            'label': 'Image',
            'field': 'img',
            'sort': 'asc'
          },
          {
            'label': 'SKU',
            'field': 'sku',
            'sort': 'asc'
          },
          {
            'label': 'Name',
            'field': 'name',
            'sort': 'asc'
          },
          {
            'label': 'Stock',
            'field': 'stock',
            'sort': 'asc'
          },
          {
            'label': 'Select',
            'field': 'button',
            'sort': 'asc'
          }
        ]
      };
      const data_checkboxesValidate = {
        columns: [
          {
            'label': 'Image',
            'field': 'img',
            'sort': 'asc'
          },
          {
            'label': 'SKU',
            'field': 'sku',
            'sort': 'asc'
          },
          {
            'label': 'Name',
            'field': 'name',
            'sort': 'asc'
          },
          {
            'label': 'Stock',
            'field': 'stock',
            'sort': 'asc'
          },
          {
            'label': 'Quantity',
            'field': 'qty',
            'sort': 'asc'
          }
        ]
      };
  return (
    <section>
      <MDBStepper>
        <MDBStep className="completed">
        <a href="#!" onClick={() => this.changeActiveStep(1)}>
            <span className="circle">1</span>
            <span className="label">Products</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.activeStep === 2 ? "active" : this.state.activeStep > 2 && "completed" }>
        <a href="#!" onClick={() => this.changeActiveStep(2)}>
            <span className="circle">2</span>
            <span className="label">Quantity</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.warning.validate ? "warning" : this.state.activeStep === 3 ? "active" : this.state.activeStep > 3 && "completed" }>
        <a href="#!" onClick={() => this.changeActiveStep(3)}>
            <span className="circle">3</span>
            <span className="label">Fullfillment Partner</span>
          </a>
        </MDBStep>
        <MDBStep className={this.state.warning.publish ? "warning" : this.state.activeStep === 4 ? "active" : ""}>
        <a href="#!" onClick={() => this.changeActiveStep(4)}>
            <span className="circle">4</span>
            <span className="label">Report</span>
          </a>
        </MDBStep>
      </MDBStepper>

          {this.state.activeStep === 1 &&
          (<section>
            <MDBRow>
            <MDBCol size="12" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
              <MDBRow>
            <MDBCol size="6">
                <Card className="w-100">
                        <CardHeader color="stylish-color">All Products</CardHeader>
                        <CardBody className="p-0">
                        <MDBRow>
                            <MDBCol size="9">
                            <div className="md-form my-1 ml-md-4">
                            <MDBInput label="Search"
                                    id="search"
                                    onKeyPress={this.searchProducts}
                            />
                            </div>
                            </MDBCol>
                            <MDBCol size="3">
                                <MDBBtn size="sm" color=" teal white-text mt-md-2" onClick={this.searchProducts}>Search</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                            <Table fixed className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.searchedProducts.length > 0 ? this.renderProducts(this.state.searchedProducts) : this.state.products && this.renderProducts(this.state.products)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
            <MDBCol size="6">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Selected Products</CardHeader>
                        <CardBody className="p-0">
                        <Table fixed className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.products && this.renderProducts(this.state.selectedProducts, true)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}

             {this.state.activeStep === 2 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Validate Products</CardHeader>
                        <CardBody className="p-0">
                        <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxesValidate.columns} />
                                <TableBody rows={this.state.products && this.validateProducts(this.state.selectedProducts, this.state.selectedMarketplaces)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="6" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          <MDBCol size="6" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}

          {this.state.activeStep === 3 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Select Fullfillment Partner</CardHeader>
                        <CardBody className="text-center">
                        <h4 className="mb-md-4">Fullfillment Partner</h4>
                        <MDBRow>
                            <MDBCol id="amazon_fba" size="12" md="3" className="mx-auto">
                                    <Card className={this.state.selectedDistributor.indexOf("amazon_fba") >= 0 && "thick-border-success"}>
                                        <CardHeader style={{height: "65px"}}>
                                        {ConnectImages["amazon"]}
                                        </CardHeader>
                                        <CardBody>
                                        Amazon FBA
                                        </CardBody>
                                    </Card>
                                </MDBCol>
                        </MDBRow>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="6" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          <MDBCol size="6" className="text-right">
                <MDBBtn color="success" onClick={() => this.changeActiveStep(this.state.activeStep + 1)}>Continue</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}


          {this.state.activeStep === 4 &&
            (<section>
            <MDBRow>
            <MDBCol size="12">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Reports</CardHeader>
                        <CardBody>
                        <MDBRow>
                            <MDBCol className="text-center">
                                <MDBBtn size="lg"
                                onClick={this.publishProducts}
                                disabled={this.state.isPublishing}
                                color="green"
                                className="mb-3"
                                >Download LabelPrinter CSV</MDBBtn>
                                 <MDBBtn size="lg"
                                onClick={this.publishProducts}
                                disabled={this.state.isPublishing}
                                color="green"
                                className="mb-3"
                                >Download Amazon Report</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        </CardBody>
                    </Card>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          <MDBCol size="12" className="text-left">
                <MDBBtn color="danger" onClick={() => this.changeActiveStep(this.state.activeStep - 1)}>Back</MDBBtn>
            </MDBCol>
          </MDBRow>
          </section>
             )}
    </section>
    );
  };
}

export default CreateListings;