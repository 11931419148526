import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    Table,
    TableBody,
    Button,
    MDBInput,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOption,
    MDBSelectOptions,
    MDBPagination,
    MDBPageItem,
    MDBPageNav,
    MDBRow,
    MDBCol,
    MDBPopover, MDBPopoverBody, MDBPopoverHeader
 } from "mdbreact";
 import { withTranslation } from 'react-i18next';
import {ProductsAPI, SettingsAPI} from "../../Api/connect";
import EditCreateProductModal from "../../components/products/ProductEditCreate";
//import CreateProductModal from "../../components/products/ProductCreate";
//import EditProductModal from "../../components/products/ProductEdit";
import ImportProductsModal from "../../components/products/ImportProducts";
import DeleteProductModal  from "../../components/products/DeleteProducts";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";


class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            selectedProducts: [],
            selectedProduct: "",
            isLoading: false,
            categories: [],
            settings: {},
            limit: 35,
            nextLimit: 35,
            from: 0,
            totalItems: 0,
            searchQuery: "",
            selectAll: false,
            modals: {
                editCreateProduct: {
                    isOpen: false,
                    type: "create"
                },
                deleteProducts: {
                    isOpen: false
                },
                EditAttribute: {
                    isOpen: false
                },
                importProducts: {
                    isOpen: false
                }
            }
        }
    }

    async componentDidMount() {
        this.getProducts();
        this.getCategories();
        // try {
        //     const settings = await SettingsAPI.getSettings();
        //     this.setState({settings});
        // } catch (error) {
        //     console.log(error)
        // }
       
    }

    async getProducts(from) {
        this.setState({isLoading: true})
        try {
            const esRes = await ProductsAPI.searchProducts({
                limit: this.state.nextLimit !== this.state.limit ? this.state.nextLimit : this.state.limit,
                from: from !== undefined ? from : this.state.from,
                q: this.state.searchQuery
            })
            this.setState(state => ({
                limit: this.state.nextLimit !== this.state.limit ? this.state.nextLimit : this.state.limit,
                products: esRes.products,
                totalItems: esRes.count,
                lastItem: state.limit + (from >= 0 ? from : state.from),
                from: from !== undefined  ? from : state.from
            }))
        } catch (error) {
            console.log(error)
        }
        this.setState({isLoading: false})
    }

    async getCategories() {
        try {
            const categories = await ProductsAPI.getCategories();
            this.setState({categories})
        } catch (error) {
            console.log(error)
        } 
    }

    // Search

    search = async q => {
        try {
            const esRes = await ProductsAPI.searchProducts({
                q: q.target ? this.state.searchQuery : q
            })
            console.log(esRes.products);
            this.setState({
                products: esRes.products,
                totalItems: esRes.count,
                lastItem: this.state.limit + this.state.from
            })
        } catch (error) {
            console.log(error)
        } 
    }

    changeSearchInput = e => this.setState({searchQuery: e.target.value})

    searchInputKeyPress = e => {
        if(e.key === 'Enter'){
            if(e.target.value.length < 1) {
                this.getProducts(this.state.qs);
            } else {
                this.search(e.target.value);
            }
        } 
    }

    // Pagination

    prevPage = () => {
        this.getProducts(this.state.lastItem >= this.state.limit ? this.state.lastItem - this.state.limit : this.state.from);
        window.scrollTo(0, 200);
    }

    nextPage = () => {
        this.getProducts(this.state.lastItem);
        window.scrollTo(0, 200);
    }

    toPage = (startKey) => {
        console.log(startKey);
        this.getProducts(startKey);
        window.scrollTo(0, 200);
    }

    renderPages = () => {
        const {totalItems, limit, lastItem} = this.state;
        let pageItems = [];
            for (let index = 0; index < (totalItems / limit); index++) {
               pageItems.push(<MDBPageItem
                                key={index}
                                active={lastItem / limit === index + 1 ? true : false}
                                onClick={() => {this.toPage(index * limit)}}
                                >
                        <MDBPageNav>
                            {index + 1}
                        </MDBPageNav>
                        </MDBPageItem>)
            }
        return pageItems;
    }

    
    selectProduct = (e, selectedId) => {
        const id = selectedId || e.target.id;
        if (this.state.selectedProducts.find(p => p.id === parseInt(id))) {
            this.setState(state => ({
                selectedProducts: state.selectedProducts.filter(p => p.id !== parseInt(id)),
                selectedProduct: ""
            }))
        } else {
            this.setState(state => ({
                selectedProducts: selectedId ? [this.state.products.find(p => p.id === parseInt(id))] : [...state.selectedProducts, this.state.products.find(p => p.id === parseInt(id))],
                selectedProduct: parseInt(id)
            }))
        }
    }

    selectAll = () => {
        // If any selected
        if(this.state.selectedProducts.length === this.state.products.length) {
            this.setState({selectedProducts: [], selectAll: false})
        } else {
            this.setState((state => ({
                selectedProducts: state.products,
                selectAll: true
            })))
        }
    }

    editProduct(id) {
        this.selectProduct({target: {id}});
        setTimeout(() => this.toggleModal("editCreateProduct", "update"), 100)
    }
    
    renderProducts(products) {

        return products.map(product => ({
                check: <div className="form-check">
                            <input type="checkbox" checked={this.state.selectedProducts.filter(p => p.id === product.id).length === 1 ? "checked" : false} id={product.id.toString()} onChange={this.selectProduct} className="form-control form-check-input my-0" value="" />
                            <label className="form-check-label" htmlFor={product.id.toString()} data-error="" data-success="" id={product.id.toString()}></label>
                        </div>,
                img: product.image ? <img src={product.image} alt={product.name} height="50" /> : <img src="http://cdn.eu.com/connect/img/image-placeholder.jpg" alt={product.name} height="50" />,
                sku: product.sku,
                name: product.name,
                // category: product.category && product.category.name,
                // ek: product.price && product.price.ek ? product.price.ek + " €" : "",
                stock: product.bundleItems ? Math.min(...product.bundleItems.map(bi => Object.values(bi.stock).reduce((accumulator, currentValue) => accumulator + currentValue))) : product.stock && Object.keys(product.stock).length > 0 && Object.values(product.stock).reduce((accumulator, currentValue) => accumulator + currentValue),
                action: <span><Link to={"/products/edit/" + product.id}><MDBIcon icon="edit" /></Link>
                                    <MDBIcon className="ml-3" icon="copy" />
                                    <MDBIcon className="ml-3 red-text" icon="trash" onClick={() => {this.selectProduct(null, product.id.toString());this.toggleModal("deleteProducts")}} /></span>
        }));

    }


    toggleModal = (id, type) => {
        if (this.state.modals[id].isOpen) {
            setTimeout(async () => {
                if (this.state.searchQuery) {
                    try {
                        const products = await ProductsAPI.searchProducts({
                            q: this.state.searchQuery
                        })
                        this.setState({products})
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    this.getProducts(this.state.from);
                }
            }, 500)
        }
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen,
                    type
                }
            }
        }));
    }

    render() {

        // const data_checkboxes = {
        //     columns: [
        //       {
        //         'label': '#',
        //         'field': 'check',
        //         'sort': 'asc',
        //         width: 20
        //       },
        //       {
        //         'label': 'Image',
        //         'field': 'img',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'SKU',
        //         'field': 'sku',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'Name',
        //         'field': 'name',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'Category',
        //         'field': 'category',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'Cost',
        //         'field': 'ek',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'Stock',
        //         'field': 'stock',
        //         'sort': 'asc'
        //       },
        //       {
        //         'label': 'Action',
        //         'field': 'action',
        //         'sort': 'asc'
        //       }
        //     ]
        //   };
        const { t } = this.props;

        return (
            <section>
            <MDBRow>
                <MDBCol>
                    <span className="float-left teal-grey-text ml-2" style={{cursor: "pointer"}} onClick={() => this.toggleModal("editCreateProduct", "create")}><MDBIcon icon="plus" style={{fontSize: "small"}} /> {t("gen.create", "Create")}</span>
                    <span className="float-right teal-grey-text" style={{cursor: "pointer"}}><MDBIcon icon="file-export" style={{fontSize: "small"}} /> {t("gen.export", "Export")}</span>
                    <span className="float-right teal-grey-text" style={{cursor: "pointer"}} onClick={() => this.toggleModal("importProducts")}><MDBIcon icon="file-import" style={{fontSize: "small"}} /> {t("gen.import", "Import")} |&nbsp;</span> 
                </MDBCol>
            </MDBRow>
         {/* <Row>
             <Col md="12">
             <Card>
                <CardHeader color="stylish-color">Actions</CardHeader>
                <CardBody>
                <Row>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("editCreateProduct", "create")}>
                            {t("gen.create", "Create")}<MDBIcon icon="plus" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("deleteProducts")}>
                            {t("gen.delete", "Delete")}<MDBIcon icon="trash" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("editCreateProduct", "edit")}>
                            {t("gen.edit", "Edit")}<MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("editCreateProduct", "copy")}>
                            {t("gen.copy", "Copy")}<MDBIcon icon="copy" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center disabled">
                            {t("gen.editWithBlueprint", "Edit with blueprint")}<MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center disabled">
                            {t("gen.editHistory", "Edit history")}<MDBIcon icon="history" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("importProducts")}>
                            {t("gen.import", "Import")}<MDBIcon icon="file-import" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                            {t("gen.export", "Export")}<MDBIcon icon="file-export" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
                </CardBody>
            </Card>
             </Col>
         </Row> */}
                <Row className="py-3">
               <Col md="12">
                 <Card>
                   <CardBody className="pt-0">
                   <Row>
                   <Col size="1">
                        <Button outline size="sm" className="mt-4" onClick={() => this.getProducts()}>
                            <MDBIcon icon="sync" className="mr-0" />
                        </Button>
                    </Col>
                       <Col size="3">
                           <Row>
                               <Col>
                               <MDBSelect getValue={value=> console.log(value)}>
                                <MDBSelectInput selected={t("gen.category", "Category")} />
                                <MDBSelectOptions search>
                                <MDBSelectOption disabled>{t("gen.category", "Category")}</MDBSelectOption>
                                {this.state.categories.length > 0 && this.state.categories.map(c => <MDBSelectOption key={c.id} value={c.id}>{c.name}</MDBSelectOption>)}
                                </MDBSelectOptions>
                                </MDBSelect>
                               
                               </Col>
                               
                           </Row>
                       </Col>
                       <Col size="8">
                            <Row>
                                <Col size="10">
                                    <MDBInput label={t("gen.search", "Search")}
                                    id="search"
                                    onChange={this.changeSearchInput}
                                    onKeyPress={this.searchInputKeyPress}
                                    />
                                </Col>
                                <Col size="2">
                                <Button outline size="sm" className="mt-4" onClick={this.search}>
                                    <MDBIcon icon="search" className="mr-0" />
                                </Button>
                                </Col>
                            </Row>
                       </Col>
                   </Row>
                    <Table responsive className="text-center">
                        {/* <TableHead  color="teal" textWhite columns={data_checkboxes.columns} /> */}
                        <thead data-test="table-head" className="text-white teal">
                            <tr>
                                <th className="" style={{width: "20px"}}>
                                
                                <MDBInput checked={this.state.selectAll} label=" " type="checkbox" id="checkbox1" containerClass="form-check checkbox-white" labelClass="form-check-label" onChange={() => this.selectAll()} />
                                </th>
                                {this.state.selectedProducts.length > 0 ? 
                                <>
                                <th colspan="2" className="text-left py-0" style={{paddingRight: "3em", minWidth: "300px"}}>
                                    <div style={{
                                        backgroundColor: "rgb(255 255 255 / 20%)",
                                        color: "#fff",
                                        height: "40px",
                                        marginBottom: "7.5px",
                                        marginLeft: "25px",
                                        borderRadius: "3px"
                                    }} className="p-2 d-flex justify-content-around">
                                    <span className="my-auto" onClick={() => this.toggleModal("deleteProducts")} style={{cursor: "pointer"}}><MDBIcon icon="trash" /> {t("gen.delete", "Delete")}</span>
                                    |
                                    <span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="copy" /> {t("gen.copy", "Copy")}</span>
                                    
                                    </div>
                                </th>
                                </>
                                
                                :
                                <>
                                <th className="">{t("gen.image", "Image")}</th>
                                <th className="">{t("gen.sku", "SKU")}</th>
                                </>
                                }
                                <th className="">{t("gen.name", "Name")}</th>
                                {/* <th className="">{t("gen.category", "Category")}</th>
                                <th className="">{t("gen.cost", "Cost")}</th> */}
                                <th className="">{t("gen.stock", "Stock")}</th>
                                <th className="" style={{minWidth: "100px"}}>{t("gen.action", "Action")}</th>
                            </tr>
                        </thead>
                        <TableBody rows={this.state.products && this.renderProducts(this.state.products)} />
                       
                    </Table>
                    {this.state.isLoading && <Loader /> }
                    {!this.state.isLoading && this.state.products.length === 0  &&
                        <div className="text-center w-100">
                            <img className="rounded-0 mx-auto" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ch/illustration/Create_Product.png" alt="Create Product" />
                            <h6>Keine Produkte gefunden. </h6>
                            <span className="teal-grey-text ml-2" style={{cursor: "pointer"}} onClick={() => this.toggleModal("editCreateProduct", "create")}><MDBIcon icon="plus" style={{fontSize: "small"}} /> {t("gen.create", "Create")}</span>
                        </div>
                    }
                        <Row className="w-100">
                            <Col size="11">
                                <MDBPagination color="teal" style={{maxWidth: "100%", justifyContent: "center", display: "flex"}}>
                                    <MDBPageItem>
                                    <MDBPageNav aria-label="Previous" onClick={this.prevPage}>
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                    </MDBPageItem>
                                    {this.renderPages()}
                                    <MDBPageItem>
                                    <MDBPageNav onClick={this.nextPage}>
                                        &raquo;
                                    </MDBPageNav>
                                    </MDBPageItem>
                                </MDBPagination>
                                </Col>
                                <Col size="1">
                                <MDBInput
                                    containerClass="my-md-0 w-70 float-left"
                                    label={t("pagination.items", "Items")}
                                    value={this.state.nextLimit}
                                    onChange={e => this.setState({nextLimit: e.target.value})}
                                />
                                <MDBIcon
                                    icon="sync"
                                    className="mr-0 float-right mt-3 teal-text"
                                    onClick={() => this.getProducts()}
                                    style={{width: "30%"}}    
                                />
                            </Col>
                        </Row>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
             <EditCreateProductModal isOpen={this.state.modals.editCreateProduct.isOpen} toggle={this.toggleModal} settings={this.state.settings} type={this.state.modals.editCreateProduct.type} productId={this.state.selectedProduct} />
             <DeleteProductModal isOpen={this.state.modals.deleteProducts.isOpen} products={this.state.selectedProducts} toggle={this.toggleModal} />
             <ImportProductsModal isOpen={this.state.modals.importProducts.isOpen} toggle={this.toggleModal} settings={this.state.settings} />
            </section>
         );
    }
  
};

export default (withTranslation()(Products))