import { configureStore } from "@reduxjs/toolkit";
import userData from "../features/userData"
import marketplaces from "../features/marketplaces"
import fulfillers from "../features/fulfillers"
import shippingProviders from "../features/shippingProviders";


export const store = configureStore({
  reducer: {
      userData,
      marketplaces,
      fulfillers,
      shippingProviders
  }
})