import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBSelectV5 } from 'mdbreact';
import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';

export default memo(({ data, isConnectable }) => {

  return <>
    <MDBCard>
      <MDBCardBody className="p-2">
        <MDBCardText>{data.optionData.name}</MDBCardText>
        {data.optionData.options.length > 0 && data.optionData.options.map(o => {
          switch (o.type) {
              case "select":
              return <>
              <MDBSelectV5
                  style={{width: "100%"}}
                  className="m-0"
                  getValue={(value) => {
                      if(value.length > 0) {
                          setActions(oldActions => oldActions.map((a,indexNewItem) => indexNewItem === index ? {...a, options: {...a.options, [o.handle]: {name: o.options.find(op => value[0] === op.handle).name, value: value[0]}}} : a))
                      }    
                  }}
                  options={[
                  {
                      value: "0",
                      text: "Select action type",
                      disabled: true
                  },
                  ...o.options.map(op => ({value: op.handle, text: op.name}))
          
                  ]}
                  selected={o.options?.[o.handle]?.name ? o.options[o.handle].name : "Select " + o.name}
              />
              </>
              break;
              default: 
              console.log("cant find option")
              break;
          }
        })}
      </MDBCardBody>
    </MDBCard>
    <Handle
        type="target"
        position="top"
        id="input"
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
    <Handle
        type="source"
        position="bottom"
        id="a"
        style={{ left: 10, background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="bottom"
        id="b"
        style={{ right: 10, left: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
  </>
});