import { Component, Suspense, useEffect, useState  } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import {
  Input,
  Navbar,
  NavbarNav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Fa,
  SideNavCat,
  SideNavNav,
  SideNavLink,
  MDBSideNav,
} from "mdbreact";
import LangSelect from "./langSelect";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";


class Navigation extends Component {
    constructor(props) {
      super(props);
      this.state = {
        username: props.userData.userNameCapped,
        modules: props.userData.modules,
        toggleStateA: false,
        breakWidth: 1300,
        windowWidth: 0,
        sideNavLeft: false,
        sideNavRight: false,
        slimInitial: true
      };
    }
  
    async componentDidMount(props) {
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
      if (window.width > 800) {
        this.setState({
          sideNavLeft: true
        });
      }
      try {
      } catch (error) {
        console.log("Error getting username", error);
      }
    }

    componentWillReceiveProps(props) {
      console.log("newprops", props)
      this.setState({
        username: props.userData.userNameCapped,
        modules: props.userData.modules
      })
    }
  
    componentWillUnmount() {
      window.removeEventListener("resize", this.handleResize);
    }
  
    handleResize = () =>
      this.setState({
        windowWidth: window.innerWidth
      });

    handleLogout = () => {
        Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log("Error: " +err));
      }

    handleGetUser = () => {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => console.log(user))
        .catch(err => console.log(err));
    }

    sidenavToggle = sidenavId => () => {
      const sidenavNr = `sideNav${sidenavId}`
      this.setState({
        [sidenavNr]: !this.state[sidenavNr]
      });
    };

  
    render() {
      const navStyle = {
        paddingLeft:
          this.state.windowWidth > this.state.breakWidth ? "210px" : "16px",
        color: "white",
        backgroundColor: this.state?.modules?.customColor || "#009688"
      };
      const mainStyle = {
        margin: "0 6%",
        paddingTop: "5.5rem",
        paddingLeft:
          this.state.windowWidth > this.state.breakWidth ? "240px" : "0"
      };
      const specialCaseNavbarStyles = {
        WebkitBoxOrient: "horizontal",
        flexDirection: "row"
      };
      // const SideNavFooterStyle = {
      //   fontSize: ".7rem",
      //   flexDirection: "row"
      // };
      const { t } = this.props;

      return (
        <Router>
        <div className="fixed-sn">
          {/* <SideNav
            logo="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ytcc/logo200.png"
            triggerOpening={this.state.toggleStateA}
            breakWidth={this.state.breakWidth}
            mask="strong"
          > */}
          <MDBSideNav fixed mask="strong" triggerOpening={this.state.sideNavLeft} breakWidth={1300}>
          <li>
              <div className="logo-wrapper sn-ad-avatar-wrapper" style={{height: "auto"}}>
              
                <a href="#!">
                {this.state?.modules?.customLogo ?
                  <img alt="" src={this.state.modules?.customLogo} />
                :
                <img alt="" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ch/logo/logoElement+11x.png" />
                }
                  
                </a>
              </div>
            </li>
            <Input
              type="text"
              default={t("nav.search", "Search")}
              hint={t("nav.search", "Search")}
              style={{
                color: "#fff",
                padding: "8px 10px 8px 30px",
                boxSizing: "border-box",
              }}
            />
            <SideNavNav>
            <li>
            <Link className="collapsible-header Ripple-parent arrow-r white-text" to="/">
            <i data-test="fa" className="fa fa-home mr-2"></i>
            Dashboard
            {/* <i data-test="fa" className="fa fa-angle-down rotate-icon"></i> */}
            {/* <div data-test="waves" className="Ripple " style="top: 0px; left: 0px; width: 0px; height: 0px;"></div> */}
            </Link>
            
            </li>
            {this.state?.modules?.orders ? 
                <li>
                  <Link className="collapsible-header Ripple-parent arrow-r white-text" to="/orders">
                  <i data-test="fa" className="fa fa-cubes mr-2"></i>
                  {t("nav.orders", "Orders")}
                  </Link>
                </li>
                : <li></li>
              }
              
              {/* <SideNavCat
                name={t("nav.orders", "Orders")}
                id="orders"
                icon="cubes"
                className={!this.state?.modules?.orders ? "d-none" : ""}
                disabled={!this.state?.modules?.orders}
                tag={!this.state?.modules?.orders ? "div" : "li"}
              >
                <SideNavLink to="/orders">{t("nav.all_orders", "All Orders")}</SideNavLink>
              </SideNavCat> */}
                  <SideNavCat
                    name={t("nav.products")}
                    id="products"
                    icon="archive"
                    className={!this.state?.modules?.products ? "d-none" : ""}
                    disabled={!this.state?.modules?.products}
                    tag={!this.state?.modules?.products ? "div" : "li"}
                  >
                    <SideNavLink to="/products">{t("nav.products_all", "All Products")}</SideNavLink>
                    <SideNavLink className={!this.state?.modules?.products_dropshipping ? "d-none" : ""}>{t("nav.dropship_network", "Drop-Shipping Network")}</SideNavLink>
                    <SideNavLink to ="/products/attributes">{t("nav.attributes", "Attributes")}</SideNavLink>
                    <SideNavLink to ="/products/categories">{t("nav.categories", "Categories")}</SideNavLink>
                    <SideNavLink to ="/products/distribute" className={!this.state?.modules?.products_distribute ? "d-none" : ""}>{t("nav.distribute", "Distribute")}</SideNavLink>
                  </SideNavCat>
                
              <SideNavCat
                name={t("nav.pricing")}
                id="pricing"
                icon="dollar-sign"
                className={!this.state?.modules?.pricing ? "d-none" : ""}
                disabled={!this.state?.modules?.pricing}
                tag={!this.state?.modules?.pricing ? "div" : "li"}
              >
                <SideNavLink to="/pricing/rules">{t("nav.pricing_rules", "Pricing Rules")}</SideNavLink>
                {/* <SideNavLink>Drop-Shipping Network</SideNavLink>
                <SideNavLink to ="/products/attributes">Attributes</SideNavLink>
                <SideNavLink to ="/products/categories">Categories</SideNavLink> */}
              </SideNavCat>
              <SideNavCat
                name={t("nav.multichannel", "Multichannel")}
                id="multichannel"
                icon="code-branch"
                className={!this.state?.modules?.multichannel ? "d-none" : ""}
                disabled={!this.state?.modules?.multichannel}
                tag={!this.state?.modules?.multichannel ? "div" : "li"}
              >
                <SideNavLink to="/multichannel/activelistings">{t("nav.active_listings", "Active Listings")}</SideNavLink>
                <SideNavLink to="/multichannel/createlistings">{t("nav.create_listing", "Create Listings")}</SideNavLink>
                
              </SideNavCat>
              
              <SideNavCat
                name={t("nav.support", "Support")}
                id="support"
                icon="envelope"
                className={!this.state?.modules?.support ? "d-none" : ""}
                disabled={!this.state?.modules?.support}
                tag={!this.state?.modules?.support ? "div" : "li"}
              >
                <SideNavLink>{t("nav.support.perf_dashboard", "Performance dashboard")}</SideNavLink>
                <SideNavLink to="/support/tickets">{t("nav.support.tickets", "Tickets")}</SideNavLink>
                <SideNavLink>{t("nav.support.reviews", "Marketplace reviews")}</SideNavLink>
                <SideNavLink>{t("nav.support.social", "Social Media")}</SideNavLink>
                
              </SideNavCat>
              <SideNavCat
                name={t("nav.customers", "Customers")}
                id="customers"
                icon="users"
                className={!this.state?.modules?.customers ? "d-none" : ""}
                disabled={!this.state?.modules?.customers}
                tag={!this.state?.modules?.customers ? "div" : "li"}
              >
                <SideNavLink>{t("nav.customers.all", "All customers")}</SideNavLink>
                <SideNavLink>{t("nav.customers.create", "Create customer manually")}</SideNavLink>
              </SideNavCat>
              <SideNavCat
                name={t("nav.marketing", "Marketing")}
                id="marketing"
                icon="rocket"
                className={!this.state?.modules?.marketing ? "d-none" : ""}
                disabled={!this.state?.modules?.marketing}
                tag={!this.state?.modules?.marketing ? "div" : "li"}
              >
                <SideNavLink>{t("nav.marketing.ads", "Ads")}</SideNavLink>
                <SideNavLink>{t("nav.marketing.seo", "Search Engine Optimisation")}</SideNavLink>
                <SideNavLink>{t("nav.marketing.pricecomparison", "Preisvergleichsportale")}</SideNavLink>
              </SideNavCat>
              <SideNavCat
                name={t("nav.statistics", "Statistics")}
                id="statistics"
                icon="chart-line"
                className={!this.state?.modules?.statistics ? "d-none" : ""}
                disabled={!this.state?.modules?.statistics}
                tag={!this.state?.modules?.statistics ? "div" : "li"}
              >
                <SideNavLink>{t("nav.stats.products", "Products")}</SideNavLink>
                <SideNavLink>{t("nav.stats.customers", "Customers")}</SideNavLink>
                <SideNavLink>{t("nav.stats.marketplaces", "Marketplaces")}</SideNavLink>
              </SideNavCat>
              <SideNavCat
                name={t("nav.financial", "Financial")}
                id="financial"
                icon="euro-sign"
                className={!this.state?.modules?.financial ? "d-none" : ""}
                disabled={!this.state?.modules?.financial}
                tag={!this.state?.modules?.financial ? "div" : "li"}
              >
                <SideNavLink to="/financial/bills">{t("nav.fin.bills", "Bills")}</SideNavLink>
                <SideNavLink>{t("nav.fin.report", "Report")}</SideNavLink>
                <SideNavLink>{t("nav.fin.match_payment", "Payment-Matching")}</SideNavLink>
              </SideNavCat>
              <SideNavCat
                name={t("nav.tools", "Tools")}
                id="tools"
                icon="toolbox"
                className={!this.state?.modules?.tools ? "d-none" : ""}
                disabled={!this.state?.modules?.tools}
                tag={!this.state?.modules?.tools ? "div" : "li"}
              >
              <SideNavLink to="/tools/product-earnings">{t("nav.tools.product_earnings", "Product earnings")}</SideNavLink>
              </SideNavCat>
              <SideNavCat
                name={t("nav.settings", "Settings")}
                id="settings"
                icon="cogs"
              >
                {/* <SideNavLink>{t("nav.settings.general", "General")}</SideNavLink>
                <SideNavLink to="/settings/users">{t("nav.settings.users", "Users")}</SideNavLink> */}
                <SideNavLink to="/settings/locations">{t("nav.settingsLocations", "Locations")}</SideNavLink>
                <SideNavLink to="/settings/marketplaces">{t("nav.settings.marketplaces", "Marketplaces")}</SideNavLink>
                <SideNavLink to="/settings/fulfillment">{t("nav.fulfillment", "Fulfillment")}</SideNavLink>
                <SideNavLink to="/settings/shipping">{t("nav.settings.shipping_providers", "Shipping providers")}</SideNavLink>
                {/* <SideNavLink to="/settings/accounting">{t("nav.settings.accounting", "Accounting")}</SideNavLink>
                <SideNavLink to="/settings/partners">{t("nav.settings.partners", "Partners")}</SideNavLink>
                <SideNavLink>{t("nav.settings.payment_providers", "Payment providers")}</SideNavLink>
                <SideNavLink>{t("nav.settings.search_engines", "Search Engines")}</SideNavLink>
                <SideNavLink>{t("nav.settings.price_comparison_sites", "Preisvergleichsportale")}</SideNavLink>
                <SideNavLink>{t("nav.settings.ads", "Ad accounts")}</SideNavLink>
                <SideNavLink>{t("nav.settings.fin_exports", "Finanical exports")}</SideNavLink> */}
              </SideNavCat>
              
            </SideNavNav>
          </MDBSideNav>
          <Navbar double expand="md" fixed="top" scrolling style={navStyle}>
            <NavbarNav left>
              <NavItem>
                <div
                  onClick={this.handleToggleClickA}
                  key="sideNavToggleA"
                  style={{
                    lineHeight: "32px",
                    marginRight: "1em",
                    verticalAlign: "middle"
                  }}
                >
                  <Fa icon="bars" color="white" size="2x" />
                </div>
              </NavItem>
              <NavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}>
                ChannelHive E-Commerce Platform
              </NavItem>
            </NavbarNav>
            <NavbarNav right style={specialCaseNavbarStyles}>
            <NavItem>
            <LangSelect />
            </NavItem>
              <NavItem>
                <Dropdown>
                  <DropdownToggle nav caret>
                    <Fa icon="user" className="d-inline-inline" />{" "}
                    <div className="d-none d-md-inline">{this.state.username || "Account"}</div>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={this.handleLogout}>{t("nav.logout", "Logout")}</DropdownItem>
                    {/* <DropdownItem onClick={this.handleGetUser}>Get user</DropdownItem>
                    <DropdownItem href="#!">Something else here</DropdownItem>
                    <DropdownItem href="#!">Something else here</DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </NavbarNav>
          </Navbar>
          <main style={mainStyle}>
            <div className="mt-5">
              {this.props.children}
            </div>
          </main>
        </div>
      </Router>
      );
    }
  }
  const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(withTranslation()(Navigation))