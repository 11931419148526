import { Component, useEffect, useState } from "react";
import Navigation from "./navigation";
import Routes from "./Routes";
import {Amplify, Auth, API} from "aws-amplify";
import { Authenticator, useAuthenticator, useTheme, View, Image, Text, Heading, Button, Theme , AmplifyProvider } from "@aws-amplify/ui-react";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import awsConfig from "./aws-config";
import "./i18n";
import { store } from "./app/store";
import { Provider, useDispatch } from "react-redux";
import { setUserData } from "./features/userData";
import { setMarketplaces } from "./features/marketplaces";
import { setFulfillers } from "./features/fulfillers";
import { setShippingProviders } from "./features/shippingProviders";
import { useTranslation } from "react-i18next";


Amplify.configure(awsConfig);




const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="ChannelHive logo"
        style={{width: "50%"}}
          src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ch/logo/logoElement+11x.png"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={`${tokens.colors.neutral["80"]}`}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};


const AppBox = (props) => {
  console.log("USER", props.user)
  const { route} = useAuthenticator((context) => [context.route]);
  console.log("Route", route)
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState("")

  const dispatchUserData = async () => {
    const modules = await API.get("ConnectApi", "user/modules");
    let managedAccounts;
    if(props.user.signInUserSession.accessToken.payload["cognito:groups"]?.[0] === "reseller") {
      managedAccounts = (await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "managedAccounts"}})).accounts
    }
    if(modules.colorTheme) document.body.classList.add(modules.colorTheme);
    dispatch(setUserData({
      group: props.user.signInUserSession.accessToken.payload["cognito:groups"]?.[0],
      managedAccounts,
      modules,
      userName: props.user.signInUserSession.accessToken.payload.username,
      userNameCapped: props.user.signInUserSession.accessToken.payload.username.charAt(0).toUpperCase() + props.user.signInUserSession.accessToken.payload.username.slice(1)
    }))
  }

  const dispatchActiveProviders = async () => {
    const activeProdviders = await API.get("ConnectApi", "settings/activeproviders")
    dispatch(setMarketplaces(activeProdviders.marketplaces))
    dispatch(setFulfillers(activeProdviders.fulfillers))
    dispatch(setShippingProviders(activeProdviders.shippingProviders))
  }

 

  useEffect(() => {
    if(props.user.username !== currentUser && props.user.username.length > 0) {
      setCurrentUser(props.user.username)
      dispatchUserData()
      dispatchActiveProviders()
    }
  }, [props.user.username])
  
  return(
  <Navigation>
    <Routes />
  </Navigation>
  )
}

export default function App() {
  const { t } = useTranslation();
  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        placeholder: t("login.username","Username"),
      },
    },
    signUp: {
      given_name : {
        labelHidden: false,
        placeholder: t("login.firstName","First name"),
        id: "firstName",
        order: 1
      },
      family_name: {
        labelHidden: false,
        placeholder: t("login.lastName","Last name"),
        order: 1
      },
      username: {
        labelHidden: false,
        placeholder: t("login.username","Username"),
        order: 1
      },
      email: {
        labelHidden: false,
        placeholder: t("login.email","Email"),
        order: 1
      },
      phone_number : {
        labelHidden: false,
        placeholder: t("login.phone","Phone"),
        order: 1
      },
      password: {
        labelHidden: false,
        label: t("login.password", "Password:"),
        placeholder: t("login.enterpassword", "Enter your Password:"),
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        labelHidden: false,
        label: t("login,confirmpassword", "Confirm Password:"),
        order: 3,
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: false,
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        labelHidden: false,
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        placeholder: "Enter your Confirmation Code:",
        label: "New Label",
        isRequired: false,
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "Enter your Password Please:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "test issuer",
        totpUsername: "amplify_qr_test_user",
      },
      confirmation_code: {
        labelHidden: false,
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        labelHidden: false,
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };
 
  return (
    <Provider store={store}>
      <Authenticator  components={components} formFields={formFields} >
              {({ user }) => {
                console.log("USR", user)
                return (
                  <Authenticator.Provider><AppBox user={user} /></Authenticator.Provider>
                
              )
              }}
      </Authenticator>
    </Provider>

  );
}



// class App extends Component {
//   constructor(props) {
//     super(props);
  
//     this.state = {
//       isAuthenticated: false,
//       isAuthenticating: true,
//       userGroup: "user"
//     };
//   }
  

//   async componentDidMount() {
//     try {
//       const session = await Auth.currentSession()
//       const { route } = useAuthenticator((context) => [context.route]);
//       console.log("Route", route)
//       // console.log("session", session);
//       const userModules = await API.get("ConnectApi", "user/modules")
//       const userData = {
//         group: session.accessToken.payload["cognito:groups"]?.[0],
//         modules: userModules,
//         userName: session.accessToken.payload.username,
//         userNameCapped: session.accessToken.payload.username.charAt(0).toUpperCase() + session.accessToken.payload.username.slice(1)
//       }
//       store.dispatch(setUserData(userData))
//       this.userHasAuthenticated(true);
//     }
//     catch(e) {
//       console.log(e);
//       if (e === "No current user") {
//       }
//     }
  
//     this.setState({ isAuthenticating: false });
//   }

//   userHasAuthenticated = authenticated => {
//     this.setState({ isAuthenticated: authenticated });
//   }

//   handleLogout = async () => {
//     await Auth.signOut();
//     this.userHasAuthenticated(false);
//     window.location.href = window.location.origin
//   }

//   render() {
//     const childProps = {
//       isAuthenticated: this.state.isAuthenticated,
//       userHasAuthenticated: this.userHasAuthenticated,
//       userGroup: this.state.userGroup,
//       logout: this.handleLogout
//     };

//     return (
//       <Provider store={store}>
//         <AmplifyAuthContainer>
//           <AmplifyAuthenticator>
//             {!this.state.isAuthenticating &&
//               <Navigation props={childProps}>
//                 <Routes  childProps={childProps} />
//               </Navigation>
//             }
//           </AmplifyAuthenticator>  
//         </AmplifyAuthContainer>
//       </Provider> 
//     );
//   }
// }