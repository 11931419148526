import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, Card, CardHeader, CardBody, Input } from 'mdbreact';
import TreeMenu from 'react-simple-tree-menu';
import {ProductsAPI} from "../../Api/connect";

const buildTree = function (data, root) {
    var o = {};
    data.forEach(function(a) {
        a = Object.assign({}, a);
        if (o[a.id] && o[a.id].nodes) {
            a.nodes = o[a.id].nodes;
        }
        o[a.id] = { [a.id]: a };
        o[a.parent] = o[a.parent] || {};
        o[a.parent][a.parent] = o[a.parent][a.parent] || {};
        o[a.parent][a.parent].nodes = o[a.parent][a.parent].nodes || {};
        o[a.parent][a.parent].nodes[a.id] = a;
    });
    return o[root][root].nodes;
  };


class CreateCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: []
        }
    }

    async componentDidMount() {
        this.getCategories();
    }

    async getCategories() {
        try {
            const categories = await ProductsAPI.getCategories();
            this.setState({categories})
        } catch (error) {
            console.log(error)
        } 
    }
    
    formCategories = (categories) => {
        const cats = categories.map(c => {
            return ({
                index: c.id,
                id: c.id,
                parent: c.parentID,
                label: c.name,
                short: c.short,
                name: c.name
            })
        });
        return buildTree(cats, "0");
    }


    selectCategory(id, name) {
        this.props.selectCategory(id, name)
    }

    
    render= () => {
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("selectCategory")}} centered size="md">
          <MDBModalHeader toggle={() => {this.props.toggle("selectCategory")}}>Select Category</MDBModalHeader>
          <MDBModalBody>
          <MDBRow>
              <MDBCol>
                <Card className="h-100">
                <CardHeader color="stylish-color">Categories</CardHeader>
                    <CardBody className="pt-0">
                    {this.state.categories.length > 0 && 

                        <TreeMenu
                        data={this.formCategories(this.state.categories)}
                        onClickItem={({ key, label, ...props }) => {
                            this.selectCategory(parseInt(props.id, 10), props.name);
                        }}
                        debounceTime={125}
                        >
                            {({ search, items }) => (
                                <>
                                <Input
                                style={{
                                    border: "none"
                                }}
                                aria-label="Type and search"
                                type="search"
                                label="Type and search"
                                onChange={e => search(e.target.value)} placeholder="Type and search" />
                                <ul
                                style={{
                                    listStyleType: "none",
                                    paddingLeft: 0,
                                    borderTop: "1px solid #ccc"
                                }}
                                >
                                    {items.map(props => {
                                    return <li
                                            key={props.id}
                                            parent={props.parent}
                                            level={props.level}
                                            opennodes={props.opennodes}
                                            index={props.index}
                                            id={props.id}
                                            onClick={props.onClick}
                                            style={{
                                            padding: " .75rem  1rem  .75rem " + (3.5 + 1.25 * props.level) + "rem",
                                            cursor: "pointer",
                                            color: props.active ? "white" : "#333",
                                            background: props.active ? "#009688" : "none",
                                            borderBottom: "1px solid #ccc"
                                            }}
                                            role={"button"}
                                            aria-pressed={props.active}
                                        >
                                        <div
                                        style={{
                                            position: "absolute",
                                            left: 2 + 1.25 * props.level + "rem",
                                            width: "2rem",
                                            height: "2rem"
                                        }}
                                        >
                                        <div
                                        role="img"
                                        aria-label="Toggle"
                                        >
                                        {props.hasNodes && (props.isOpen ? "-" : "+")}
                                        </div>
                                        </div>
                                        {props.label}
                                        </li>
                                    })}
                                </ul>
                                </>
                            )}
                        </TreeMenu>
                    }
                    
                    </CardBody>
                </Card>
              </MDBCol>
          </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("selectCategory")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={() => {this.props.toggle("selectCategory")}}>Select</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}



export default CreateCategory;