import { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardImage,
} from 'mdbreact';
import { useTranslation } from "react-i18next";
import {API} from "aws-amplify";


export default function DeleteAttributes(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState({update: false});




  const deleteAttributes = async() => {
    if(isLoading.update) return;
    setIsLoading({update: true});
    try {
      await API.del("ConnectApi", "attributes", {queryStringParameters: {ids: props.attributes.map(p => p.id)}})
    } catch (error) {
      console.log(error)
    }
    setIsLoading({update: false});
    props.toggle("deleteAttributes", true);
  }


  return <MDBModal
    isOpen={props.isOpen}
    toggle={() => {
      props.toggle("deleteAttributes");
    }}
    centered
    size="l">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}} className="mb-4">
    <MDBCardImage
        className='red white-text d-flex justify-content-center align-items-center flex-column p-2'
        tag='div'
      >{t("modals.deleteAttributes", "Delete Attributes")}
      </MDBCardImage>
    </MDBCard>
    <p className="mb-0">{t("modals.deleteAttributes.intro", "Confirm the deletion of the following attributes:")}</p>
    <span style={{fontSize: "small"}}>{t("modals.deleteAttributes.subText", "Attribute deletion does not effect generated variants but will delete the attribute connection and search functionality.")}</span>
    {props.attributes.length > 0 && <ul>
      {props.attributes.map(a => <li key={a.id}>{a.name}</li>)}
    </ul>}
    </MDBModalBody>
    <MDBModalFooter>
    <MDBBtn outline color="danger" onClick={() => {props.toggle("deleteAttributes")}}>Cancel</MDBBtn>
      <MDBBtn
      onClick={() => deleteAttributes()}
      disabled={isLoading.update}
      color="danger"
      >
      {isLoading.update ?
          <div className="spinner-grow spinner-small white-text" role="status"></div>
      :
          "Delete"
      }</MDBBtn>
    </MDBModalFooter>
  </MDBModal>;
}