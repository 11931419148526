export const apiName = "ConnectApi";
export const mediaPath = "https://ytec-connect-media.s3.eu-central-1.amazonaws.com/public/";
export const paths = {
    settings: {
        getMarketplaces: "settings",
        getAccountMarketplaces: "/settings/marketplaces",
        update: "settings",
        partners: {
            all: "settings/partners",
            single: "settings/partners/partner"
        },
        accounting: "settings/accounting/accounts"
    },
    orders: {
        getAll: "orders",
        instructShipping: "orders/shipping/instruct",
        update: "orders/updatestatus",
        search: "orders/search",
        export: "orders/export",
        importTracking: "orders/import/tracking",
        cancel: "orders/cancel",
        payment: {
            capture: "orders/payment/capture"
        },
        invoice: {
            create: "orders/invoice/create",
            sendEmail: "orders/invoice/sendemail"
        },
        print: {
            slip: "orders/print/slip"
        },
        label: {
            create: "orders/labels/create"
        },
        documents: {
            open: "orders/documents/open"
        }
    },
    products: {
        products: {
            all: "articles",
            update: "articles/update",
            search: "articles/search",
            import: "articles/import"
        },
        categories: {
            all: "categories",
            update: "categories/update"
        },
        attributes: {
            all: "attributes"
        }
    },
    pricing: {
        rules: {
            all: "pricing/rules",
            update: "pricing/rules/update",
            delete: "pricing/rules/delete"
        }
    },
    multichannel: {
        marketplaceinfos: "marketplaces/marketplaceinfos",
        publish: "multichannel/publish",
        listings: "multichannel/listings"
    },
    support: {
        tickets: {
            all: "support/tickets",
            single: "support/tickets/ticket"
        }
    },
    financial: {
        bills: {
            all: "financial/bills",
            single: "financial/bills/bill",
            status: "financial/bills/status"
        }
    },
    ordervalidation: {
        address: "ordervalidation/address"
    }
};