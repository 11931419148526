import { Component } from "react";
import { 
    Table,
    TableBody,
    TableHead,
    MDBInput,
    MDBCollapse,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBBadge,
    MDBTooltip,
    MDBCard,
    MDBCardBody,
    MDBCardText
 } from "mdbreact";
 import images, {imageUrls } from "../images";
 import {OrdersAPI} from "../../Api/connect";
 import {Auth} from 'aws-amplify';
 import {CopyToClipboard} from 'react-copy-to-clipboard';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import ReactTooltip from 'react-tooltip';

 import AddressValidationModal from "./ValidateAddress";
import { connect } from "react-redux";


 const getDaysDiff = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1; 
 }

// Flatten polyfill 
if (!Array.prototype.flatten) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.flatten = function() {
        return this.reduce((acc, val) => acc.concat(val), []);
    };
}

 const OrderList = class OrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
         openOrders: [],
         userGroup: null,
         subModules: props.userData?.modules?.orderSubModules || {},
         modals: {
            addressValidation: {
                isOpen: false,
                order: {}
            }
        }
     }
    }
     
    componentWillReceiveProps = props => {
        const userGroup = props.userData.group
        const subModules = props.userData?.modules?.orderSubModules || {}
        this.setState({userGroup, subModules})
    }
    selectOrder = id => {
        this.props.selectOrder(id);
    };
    handleOpen = id => {
         // Remove Order from selected
         if (this.state.openOrders.indexOf(id) >= 0) {
            this.setState(state => ({
                openOrders: state.openOrders.filter(selectedId => selectedId !== id)
            }))
        // Add Order to selected
        } else {
            this.setState(state => ({
                openOrders: [...state.openOrders, id]
            }))
        }
    }

    toggleModal = (id) => {
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen
                }
            }
        }));
    }

    openModal = (id, order) => {
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    isOpen: true,
                    order
                }
            }
        }))
    }

    openDocument = async id => {
        try {
            const b = {
                documentIds: [id]
            };
            const RES = await OrdersAPI.openDocument(b);
            window.open(RES.url, "_blank")
        } catch (error) {
            console.log(error)
        }
    }
    data_checkboxes = {
        columns: [
          {
            'label': <div className="form-check">
                        <input type="checkbox" className="form-control form-check-input" value=""
                        checked={this.props.isAllSelected ? "checked" : false}
                        onChange={(e) => e}
                        />
                        <label
                        onClick={this.props.selectAll}
                        className="form-check-label mr-5"
                        style={{height: "15px"}}>
                        </label>
                    </div>,
            'field': 'check'
          },
          {
            'label': 'Order number',
            'field': 'orderNumber',
            'sort': 'asc'
          },
          {
            'label': 'Store',
            'field': 'store',
            'sort': 'asc'
          },
          {
            'label': 'Order Time',
            'field': 'time',
            'sort': 'asc'
          },
          {
            'label': 'Customer',
            'field': 'customer',
            'sort': 'asc'
          },
          {
            'label': 'Payment',
            'field': 'payment',
            'sort': 'asc'
          },
          {
            'label': 'Delivery',
            'field': 'delivery',
            'sort': 'asc'
          }
        ]
      };
      renderOrder(order, selectedOrderIds, selectOrder) {
        const isSameAddress = order.addresses && order.addresses.billing && order.addresses.shipping ? Object.values(order.addresses.billing).join() === Object.values(order.addresses.shipping).join() : true;
        const orderTime = new Date(order.time)
        let progressValue = 0;
        if(order.paymentStatus) progressValue = 34;
        if(order.status === "instructed") progressValue = 66;
        if(order.status === "shipped") progressValue = 100;
        return ([{
            "check": <MDBInput label=" " type="checkbox" checked={selectedOrderIds.indexOf(order.id) >= 0 ? "checked" : false} id={order.id.toString()} onChange={() => selectOrder(order.id)} />,
            "orderNumber": <>{this.state.userGroup === "reseller" && <span className="font-weight-bold">{capitalize(order.account)}<br /></span>}<span onClick={() => this.handleOpen(order.id)}><span>{order.id}<br /><span className="grey-text">{order.storeOrderId}</span></span></span></>,
            "time": <span onClick={() => this.handleOpen(order.id)}>{orderTime.toLocaleDateString("de-DE", {dateStyle: "medium"}) + " " + orderTime.toLocaleTimeString('de-DE', {timeStyle: "short"})}</span>,
            "address": <>
                <span onClick={() => this.handleOpen(order.id)}>{order.addresses.shipping ? (order.addresses.shipping.fullName ? order.addresses.shipping.fullName + " " + order.addresses.shipping.zip + " " + order.addresses.shipping.country 
                : order.addresses.shipping.firstName + " " + order.addresses.shipping.lastName + " " + order.addresses.shipping.zip + " " + order.addresses.shipping.country) : order.customerData.fullName}
                {order.store === "ebay" && " - " + order.storeUsername}</span>
            </>,
            "products": <div style={{maxWidth: "150px"}} onClick={() => this.handleOpen(order.id)}><span>{order.articles?.skus?.join(", ")}</span>     
            </div>,
            "progress":  <div style={{minWidth: "200px"}} className="px-2" onClick={() => this.handleOpen(order.id)}><ProgressBar
            percent={progressValue}
            filledBackground="#009688"
            height={4}
          >
            <Step transition="scale" className="white">
            {({ accomplished }) => (
                <>
                <MDBBadge color="white" className="p-1">
                <img
                  style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                  height="15"
                  src={this.state.subModules.hidecms && order.originalStore ?
                    imageUrls[order.originalStore]
                    :
                    imageUrls[order.store]
                    }
                />
                </MDBBadge>
                </>
                
                
              )}
               
            </Step>
            <Step transition="scale" className="white">
              {({ accomplished }) => (
                  
                  <MDBBadge color="white" className="p-1">
                  {order.paymentProvider === "other" ? 
                  <MDBIcon className={accomplished ? "teal-text" : "grey-text"} icon="dollar-sign" />
                  :
                  <img
                    style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                    height="15"
                    src={imageUrls[order.paymentProvider]}
                    />
                  }
                </MDBBadge>
              )}
            </Step>
            <Step transition="scale" className="white">
              {({ accomplished }) => (
                  <>
                <MDBBadge color="white" className={!accomplished && order.errors?.find(e => e.type === "fulfillment") ? "p-1 border border-danger" :"p-1"} data-tip data-for={"tooltip-fulfillment-" + order.id} data-event="click" data-effect="solid">
                {order.fulfiller ?
                <img
                style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                height="15"
                src={imageUrls[order.fulfiller]}
                />
                :
                <MDBIcon className={accomplished ? "teal-text" : (order.errors?.find(e => e.type === "fulfillment") ? "danger-text" :"grey-text")} icon="warehouse" />
                }
                
                </MDBBadge>
                <ReactTooltip id={"tooltip-fulfillment-" + order.id} type='light' place="bottom" clickable={true}>
                {order.errors?.filter(e => e.type === "fulfillment").length > 0 ? <span className="danger-text">{order.errors?.filter(e => e.type === "fulfillment").map(e => e.message).join(", ")}</span> : " "}
                </ReactTooltip>
                </>
              )}
            </Step>
            <Step transition="scale" className="white">
              {({ accomplished }) => (
                <>
                <MDBBadge data-tip data-for={"tooltip-shipping-" + order.id} data-event="click" data-effect="solid" color="white" className="p-1">
                {order.shippingProvider ?
                <img
                style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                width="30"
                src={imageUrls[order.shippingProvider]}
                />
                :
                <MDBIcon className={accomplished ? "teal-text" : "grey-text"} icon="truck" />
                }
                </MDBBadge>
                <ReactTooltip id={"tooltip-shipping-" + order.id} type='light' place="left" clickable={true}>
                <span>{order.tracking?.id}<br></br><a target="_blank" href={order.shippingProvider === "dhl" ? "https://www.dhl.de/en/privatkunden/pakete-empfangen/verfolgen.html?lang=de&ref=channelhive&idc="+order.tracking?.id : order.shippingProvider === "dpag" ? "https://www.deutschepost.de/sendung/simpleQueryResult.html?form.sendungsnummer="+order.tracking?.id : order.shippingProvider === "gls" ? "https://www.gls-pakete.de/sendungsverfolgung?match="+order.tracking?.id: ""}>Open Tracking</a></span>
                </ReactTooltip>
                </>  
              )}
            </Step>
            {/* <Step transition="scale">
              {({ accomplished }) => (
                <MDBIcon
                    style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, color: `${accomplished ? "green" : "grey"}` }}
                    icon="check-circle" />
              )}
            </Step> */}
          </ProgressBar>
          </div> 
            
        },
        {
            "check": 
                    <MDBCollapse className="span-all text-left" id="basicCollapse" isOpen={this.state.openOrders.indexOf(order.id) >= 0}>
                    <MDBRow className="mx-3 my-md-3">
                        <MDBCol md="4">
                            <MDBCard style={{height: "100%"}}>
                                <MDBCardBody>
                                    <MDBCardText><span className="font-weight-bold">Billing & Shipping Address</span> <span className="text-right float-right" onClick={() => this.openModal("addressValidation", order)}><MDBIcon icon="edit" /></span></MDBCardText>
                                    <MDBRow>
                                        <MDBCol md={isSameAddress ? "12" : "6"}>
                                        {isSameAddress ? "" : <span className="font-weight-bold dark-grey-text">Billing<br></br></span>}
                                        
                                        {order.addresses && order.addresses.billing &&
                                            <span>
                                                {order.addresses.billing.fullName}<br></br>
                                                {order.addresses.billing.street}<span className="font-weight-bold"> {order.addresses.billing.streetNumber}</span><br></br>
                                                {order.addresses.billing.street2 && <>{order.addresses.billing.street2}<br></br></> }
                                                {order.addresses.billing.street3 && <>{order.addresses.billing.street3}<br></br></> }
                                                {order.addresses.billing.zip} {order.addresses.billing.city}<br></br>
                                                {order.addresses.billing.region && <>{order.addresses.billing.region}<br></br></> }
                                                {order.addresses.billing.country}
                                            </span>
                                        }
                                        </MDBCol>
                                        {(!isSameAddress || !order.addresses.billing) && order.addresses && order.addresses.shipping &&
                                            <MDBCol md={isSameAddress ? "12" : "6"}>
                                            {isSameAddress ? "" : <span className="font-weight-bold dark-grey-text">Shipping<br></br></span>}
                                            <span>
                                                {order.addresses.shipping.fullName}<br></br>
                                                {order.addresses.shipping.street}<span className="font-weight-bold"> {order.addresses.shipping.streetNumber}</span><br></br>
                                                {order.addresses.shipping.street2 && <>{order.addresses.shipping.street2}<br></br></> }
                                                {order.addresses.shipping.street3 && <>{order.addresses.shipping.street3}<br></br></> }
                                                {order.addresses.shipping.zip} {order.addresses.shipping.city}<br></br>
                                                {order.addresses.shipping.region && <>{order.addresses.shipping.region}<br></br></> }
                                                {order.addresses.shipping.country}
                                            </span>
                                            </MDBCol>
                                        }
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBCard style={{height: "100%"}}>
                                <MDBCardBody>
                                    <MDBCardText className="font-weight-bold">Contact</MDBCardText>
                                    <div className="text-left">
                                        <table className="w-100">
                                            <tr>
                                                <td className="p-1 border-0"><MDBIcon icon="user" /></td>
                                                <td className="p-1 border-0">{order.customerData.fullName}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-1 border-0"><MDBIcon icon="envelope" /></td>
                                                <td className="p-1 border-0">{order.email}</td>
                                            </tr>
                                            {order.addresses?.shipping?.phone &&
                                                <tr>
                                                <td className="p-1 border-0"><MDBIcon icon="phone" /></td>
                                                <td className="p-1 border-0">{order.addresses?.shipping?.phone}</td>
                                            </tr>}
                                            {order.store === "ebay" &&
                                            <tr>
                                                <td className="p-0 border-0"><MDBIcon fab icon="ebay" /></td>
                                                <td><a className="blue-text font-weight-bold" target="_blank" rel="noopener noreferrer" href={"https://www.ebay.de/contact/sendmsg?recipient="+ order.storeUsername + (order.articles.storeProductIds.length < 2 ? "&item_id=" + order.articles.storeProductIds[0] : "")}>{order.storeUsername}</a></td>
                                            </tr>
                                            }
                                            
                                        </table>
                                         
                                    </div>
                                   
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                        <MDBCard style={{height: "100%"}}>
                            <MDBCardBody>
                                <MDBCardText className="font-weight-bold mb-0">Payment</MDBCardText>
                                <table className="w-100">
                                    <tr>
                                        <td className="p-2 border-0"> <img
                                        style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                                        width="30"
                                        src={imageUrls[order.paymentProvider]}
                                        /></td>
                                        <td className="p-2 border-0">{order.financial?.payment?.transactionId || ""}</td>
                                        
                                    </tr>
                                </table>
                                <hr className="py-0" />
                                <MDBCardText className="font-weight-bold mb-0">Shipment</MDBCardText>
                                <table className="w-100">
                                    <tr>
                                        <td className="p-2 border-0"> <img
                                        style={{ filter: `grayscale(${order.paymentStatus ? 0 : 90}%)` }}
                                        width="30"
                                        src={imageUrls[order.shippingProvider]}
                                        /></td>
                                        <td className="p-2 border-0">{order.tracking && (order.tracking.link || order.tracking.id)}<span className="text-right float-right"><a target="_blank" href={order.shippingProvider === "dhl" ? "https://www.dhl.de/en/privatkunden/pakete-empfangen/verfolgen.html?lang=de&ref=channelhive&idc="+order.tracking?.id : order.shippingProvider === "dpag" ? "https://www.deutschepost.de/sendung/simpleQueryResult.html?form.sendungsnummer="+order.tracking?.id : order.shippingProvider === "gls" ? "https://www.gls-pakete.de/sendungsverfolgung?match="+order.tracking?.id: ""}><MDBIcon icon="external-link-alt" /></a></span></td>
                                    </tr>
                                </table>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        {/* <MDBCol md="3">
                            <h6 className="font-weight-bold">Documents</h6>
                            {order.documents && order.documents.length > 0 && 
                            order.documents.map(document => <p key={document.number}>
                                {document.number + " " + document.totalAmount + document.currency}
                                &nbsp;<span className="grey-text" onClick={() => this.openDocument(document.id)}><MDBIcon icon="file-download" /></span>&nbsp;&nbsp;
                                <CopyToClipboard text={document.number}
                                    onCopy={() => this.setState({copied: true})}>
                                    <span><MDBIcon far icon="clone" /></span>
                                </CopyToClipboard>
                            </p>)
                            }
                        </MDBCol> */}
                    </MDBRow>
                    <MDBRow className="my-md-4">
                    <MDBCard className="w-100 mx-5">
                        <MDBCardBody>
                            <Table responsive className="text-left w-100 mx-auto">
                                <TableHead columns={[
                                    {
                                            'label': 'QTY',
                                            'field': 'qty'
                                        },
                                        {
                                            'label': 'Name',
                                            'field': 'name'
                                        },
                                        {
                                            'label': 'SKU',
                                            'field': 'sku'
                                        },
                                        {
                                            'label': 'Store ID',
                                            'field': 'storeProductId',
                                            minimal: "sm"
                                        }
                                    ]} />
                                    {order.articles && order.articles.names &&
                                        <TableBody rows={order.articles.names.map((name, index) => ({
                                            qty:  order.articles.qtys && order.articles.qtys[index], // Not needed but ES data had missing fields which resultet in an error (cant find [index] of null)
                                            name,
                                            sku: order.articles.skus[index],
                                            storeProductId: order.articles.storeProductIds[index]
                                        }))} />
                                    }
                            </Table>
                        </MDBCardBody>
                    </MDBCard>
                        
                    </MDBRow>
                </MDBCollapse>
                
        }
    ])


        // let address, payment, delivery, business;
        // const isSameAddress = order.addresses && order.addresses.billing ? Object.values(order.addresses.billing).join() === Object.values(order.addresses.shipping).join() : true;
        //         switch (order.status) {
        //             case "pending":
        //                 address = "Order pending";
        //                 payment = <span className="font-weight-bold" style={{color: "orange"}}>pending</span>;
        //                 delivery = <span className="font-weight-bold" style={{color: "orange"}}>open</span>;
        //                 break;
        //             case "canceled":
        //                 address = "Order canceled";
        //                 payment = <span className="font-weight-bold" style={{color: "red"}}>canceled</span>;
        //                 delivery = <span className="font-weight-bold" style={{color: "red"}}>open</span>;
        //                 break;
        //             case "shipped":
        //                 business = order.financial.isBusiness === "true" && <span style={{color: "green"}}><br />Business</span>;
        //                 address = <span>{order.addresses.billing ? (order.addresses.billing.fullName ? order.addresses.billing.fullName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country 
        //                                 : order.addresses.billing.firstName + " " + order.addresses.billing.lastName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country) : order.customerData.fullName}
        //                                  {order.store === "ebay" && " - " + order.storeUsername}
        //                                 {business}</span>;
        //                 payment = <span>
        //                 {images[order.paymentProvider]}
        //                 <br />
        //                 <span style={{color: order.paymentStatus ? "#00c300" : "red"}} className="font-weight-bold">{order.financial.amount}€</span> 
        //                 <span style={{color: order.invoice && order.invoice.sentEmail ? "#00c300" : "black"}} className="font-weight-bold">{order.invoice && " | " +  order.invoice.number}</span>
        //               </span>;
        //                 delivery = <span>{images[order.shippingProvider]}<br /><span className="font-weight-bold" style={{color: "#00c300"}}>fulfilled</span>{order.fulfiller && <span><br />{images[order.fulfiller] || order.fulfiller}</span>}</span>;
        //                 break;
        //             case "instructed":
        //                 business = order.financial.isBusiness === "true" && <span style={{color: "green"}}><br />Business</span>;
        //                 address = <span>{order.addresses.billing ? (order.addresses.billing.fullName ? order.addresses.billing.fullName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country 
        //                             : order.addresses.billing.firstName + " " + order.addresses.billing.lastName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country) : order.customerData.fullName}
        //                             {order.store === "ebay" && " - " + order.storeUsername}
        //                             {business}</span>;
        //                 payment = <span>
        //                             {images[order.paymentProvider]}
        //                             <br />
        //                             <span style={{color: order.paymentStatus ? "#00c300" : "red"}} className="font-weight-bold">{order.financial.amount}€</span> 
        //                             <span style={{color: order.invoice && order.invoice.sentEmail ? "#00c300" : "black"}} className="font-weight-bold">{order.invoice && " | " + order.invoice.number}</span>
        //                           </span>;
        //                 delivery = <span>{images[order.shippingProvider]}<br /><span className="font-weight-bold" style={{color: "orange"}}>In fulfillment</span>{order.fulfiller && <span><br />{images[order.fulfiller] || order.fulfiller}{order.instruct_fullfillment_time && (" - " + getDaysDiff(order.instruct_fullfillment_time) + (getDaysDiff(order.instruct_fullfillment_time) == 1 ?" Day" :" Days"))}</span>}</span>
        //                 break;
        //             default:
        //                 business = order.financial.isBusiness === "true" && <span style={{color: "green"}}><br />Business</span>;
        //                 address = <span>{order.addresses.billing ? (order.addresses.billing.fullName ? order.addresses.billing.fullName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country 
        //                             : order.addresses.billing.firstName + " " + order.addresses.billing.lastName + " " + order.addresses.billing.zip + " " + order.addresses.billing.country) : order.customerData.fullName}
        //                             {order.store === "ebay" && " - " + order.storeUsername}
        //                             {business}</span>;
        //                 payment = <span>
        //                             {images[order.paymentProvider]}
        //                             <br />
        //                             <span style={{color: order.paymentStatus ? "#00c300" : "red"}} className="font-weight-bold">{order.financial.amount}€</span> 
        //                             <span style={{color: order.invoice && order.invoice.sentEmail ? "#00c300" : "black"}} className="font-weight-bold">{order.invoice && " | " + order.invoice.number}</span>
        //                           </span>;
        //                 delivery = <span>{images[order.shippingProvider]}<br /><span className="font-weight-bold" style={{color: "red"}}>open</span>{order.fulfiller && <span><br />{images[order.fulfiller] || order.fulfiller}{order.instruct_fullfillment_time && (" - " + getDaysDiff(order.instruct_fullfillment_time) + (getDaysDiff(order.instruct_fullfillment_time) == 1 ?" Day" :" Days"))}</span>}</span>
        //                 break;
        //         }
        
        //         return ([{
        //             "check": <MDBInput label=" " type="checkbox" checked={selectedOrderIds.indexOf(order.id) >= 0 ? "checked" : false} id={order.id.toString()} onChange={() => selectOrder(order.id)} />,
        //             "orderNumber": <span onClick={() => this.handleOpen(order.id)}><span>{order.id}<br /><span className="grey-text">{order.storeOrderId}</span></span></span>,
        //                 "store": <span onClick={() => this.handleOpen(order.id)}>
        //                     {this.state.userGroup === "reseller" && <span className="font-weight-bold">{capitalize(order.account)}<br /></span>}
        //                     {this.state.subModules.hidecms && order.originalStore ?
        //                     images[order.originalStore]
        //                     :
        //                     images[order.store]
        //                     }
        //                     </span>,
        //                 "time": <span onClick={() => this.handleOpen(order.id)}>{order.time.substring(0, 16)}</span>,
        //                 "customer": <span onClick={() => this.handleOpen(order.id)}>{address}</span>,
        //                 "payment": <span onClick={() => this.handleOpen(order.id)}>{payment}</span>,
        //                 "delivery": <span onClick={() => this.handleOpen(order.id)}>{delivery}</span>
        //         },
        //         {
        //             "check": 
        //                     <MDBCollapse className="span-all text-left" id="basicCollapse" isOpen={this.state.openOrders.indexOf(order.id) >= 0}>
        //                     <MDBRow className="ml-md-5 my-md-3">
        //                         <MDBCol md="3">
        //                         <MDBRow>
        //                             <MDBCol md={isSameAddress ? "12" : "6"}>
        //                             <h6 className="font-weight-bold">Billing {isSameAddress && " & Shipping"} Address</h6>
        //                             {order.addresses && order.addresses.billing &&
        //                                 <span>
        //                                     {order.addresses.billing.fullName}<br></br>
        //                                     {order.addresses.billing.street}<span className="font-weight-bold"> {order.addresses.billing.streetNumber}</span><br></br>
        //                                     {order.addresses.billing.street2 && <>{order.addresses.billing.street2}<br></br></> }
        //                                     {order.addresses.billing.street3 && <>{order.addresses.billing.street3}<br></br></> }
        //                                     {order.addresses.billing.zip} {order.addresses.billing.city}<br></br>
        //                                     {order.addresses.billing.region && <>{order.addresses.billing.region}<br></br></> }
        //                                     {order.addresses.billing.country}
        //                                 </span>
        //                             }
        //                             </MDBCol>
        //                             {!isSameAddress && order.addresses && order.addresses.shipping &&
        //                                 <MDBCol md="6">
        //                                 <h6 className="font-weight-bold">Shipping Address</h6>
        //                                 <span>
        //                                     {order.addresses.shipping.fullName}<br></br>
        //                                     {order.addresses.shipping.street}<span className="font-weight-bold"> {order.addresses.shipping.streetNumber}</span><br></br>
        //                                     {order.addresses.shipping.street2 && <>{order.addresses.shipping.street2}<br></br></> }
        //                                     {order.addresses.shipping.street3 && <>{order.addresses.shipping.street3}<br></br></> }
        //                                     {order.addresses.shipping.zip} {order.addresses.shipping.city}<br></br>
        //                                     {order.addresses.shipping.region && <>{order.addresses.shipping.region}<br></br></> }
        //                                     {order.addresses.shipping.country}
        //                                 </span>
        //                                 </MDBCol>
        //                             }
        //                         </MDBRow>
        //                         <MDBRow>
        //                             <MDBCol>
        //                                 <span onClick={() => this.openModal("addressValidation", order)}>Validate / Correct</span>
        //                             </MDBCol>
        //                         </MDBRow>
        //                         </MDBCol>
        //                         <MDBCol md="3">
        //                             <h6 className="font-weight-bold">Contact</h6>
        //                             <span>
        //                                 Email: {order.email}<br></br>
        //                                 {order.addresses && order.addresses.shipping && order.addresses.shipping.phone && <>Phone: {order.addresses.shipping.phone}<br></br></>}
        //                                 {order.store === "ebay" ? <>Marketplace: <a className="blue-text font-weight-bold" target="_blank" rel="noopener noreferrer" href={"https://www.ebay.de/contact/sendmsg?recipient="+ order.storeUsername + (order.articles.storeProductIds.length < 2 ? "&item_id=" + order.articles.storeProductIds[0] : "")}>{order.storeUsername}</a><br></br></> : <br></br>}
        //                             </span>
        //                         </MDBCol>
        //                         <MDBCol md="3">
        //                             <h6 className="font-weight-bold">Payment & Shippment</h6>
        //                             <MDBRow>
        //                                 <MDBCol>{payment}</MDBCol>
        //                                 <MDBCol>{delivery}</MDBCol>
        //                             </MDBRow><br></br>
        //                             {order.tracking && (order.tracking.link || order.tracking.id)}
        //                         </MDBCol>
        //                         <MDBCol md="3">
        //                             <h6 className="font-weight-bold">Documents</h6>
        //                             {order.documents && order.documents.length > 0 && 
        //                             order.documents.map(document => <p key={document.number}>
        //                                 {document.number + " " + document.totalAmount + document.currency}
        //                                 &nbsp;<span className="grey-text" onClick={() => this.openDocument(document.id)}><MDBIcon icon="file-download" /></span>&nbsp;&nbsp;
        //                                 <CopyToClipboard text={document.number}
        //                                     onCopy={() => this.setState({copied: true})}>
        //                                     <span><MDBIcon far icon="clone" /></span>
        //                                 </CopyToClipboard>
        //                             </p>)
        //                             }
        //                         </MDBCol>
        //                     </MDBRow>
        //                     <MDBRow className="my-md-4">
        //                         <Table responsive className="text-center w-70 mx-auto">
        //                             <TableHead columns={[
        //                                 {
        //                                         'label': 'QTY',
        //                                         'field': 'qty'
        //                                     },
        //                                     {
        //                                         'label': 'Name',
        //                                         'field': 'name'
        //                                     },
        //                                     {
        //                                         'label': 'SKU',
        //                                         'field': 'sku'
        //                                     },
        //                                     {
        //                                         'label': 'Store ID',
        //                                         'field': 'storeProductId'
        //                                     }
        //                                 ]} />
        //                                 {order.articles && order.articles.names &&
        //                                     <TableBody rows={order.articles.names.map((name, index) => ({
        //                                         qty:  order.articles.qtys && order.articles.qtys[index], // Not needed but ES data had missing fields which resultet in an error (cant find [index] of null)
        //                                         name,
        //                                         sku: order.articles.skus[index],
        //                                         storeProductId: order.articles.storeProductIds[index]
        //                                     }))} />
        //                                 }
        //                         </Table>
        //                     </MDBRow>
        //                 </MDBCollapse>
                        
        //         }
        //     ])
    }
    render() {
        if(this.props.orders?.length > 0) {
            setTimeout(() => {
                const elems = document.getElementsByClassName("span-all");
                for (let elem of elems) {
                    elem.parentElement.setAttribute("colspan", 7);
                    elem.parentElement.setAttribute("class", "row-0")
                }
                }, 0);
            }
        return(
            <>
            <TableBody rows={this.props.orders?.map(order => this.renderOrder(order, this.props.selectedOrderIds, this.selectOrder)).flatten()} />
            {/* <Table responsive className="text-center">
                <TableHead  color="teal" textWhite columns={this.data_checkboxes.columns} />
                <TableBody rows={this.props.orders?.map(order => this.renderOrder(order, this.props.selectedOrderIds, this.selectOrder)).flatten()} />
            </Table> */}
            <AddressValidationModal isOpen={this.state.modals.addressValidation.isOpen} order={this.state.modals.addressValidation.order} toggle={this.toggleModal} />
            </>
        )
    }
      
    
 }

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(OrderList)

 function capitalize(s)
{
    return s[0].toUpperCase() + s.slice(1);
}