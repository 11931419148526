import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    MDBInput,
    ButtonGroup,
    MDBPagination,
    MDBPageItem,
    MDBPageNav,
    MDBToastContainer,
    toast,
    Table,
    TableBody,
    TableHead,
    MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
 } from "mdbreact";
import {OrdersAPI, SettingsAPI} from "../Api/connect";
import Loader from "../components/Loader";
import InstrShippingModal from "../components/orders/instrShipping";
import MarkShippedModal from "../components/orders/MarkShipped";
import ExportModal from "../components/orders/Export";
import ImportTrackingModal from "../components/orders/ImportTracking";
import CancelModal from "../components/orders/Cancel";
import CapturePayment from "../components/orders/CapturePayment";
import InvoiceModal from "../components/orders/Invoice";
import LabelModal from "../components/orders/Label";
import StatusModal from "../components/orders/ChangeStatus";
import OrdersList from "../components/orders/OrdersList";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

 import "./Orders.css";


class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            orders: [],
            selectedOrders: [],
            selectedOrderIds: [],
            selectAll: false,
            limit: 50,
            nextLimit: 50,
            from: 0,
            totalItems: 0,
            searchQuery: "",
            searchFilters: {},
            searchTimeout: 0,
            modals: {
                instrShipping: {
                    isOpen: false,
                    items: []
                },
                markShipped: {
                    isOpen: false,
                    items: []
                },
                export: {
                    isOpen: false,
                    items: []
                },
                importTracking: {
                    isOpen: false,
                    items: []
                },
                cancel: {
                    isOpen: false,
                    items: []
                },
                capturePayment: {
                    isOpen: false,
                    items: []
                },
                invoice: {
                    isOpen: false,
                    items: []
                },
                label: {
                    isOpen: false,
                    items: []
                },
                changeStatus: {
                    isOpen: false,
                    items: []
                }
            },
            settings: {},
            hideFilters: true
        }

    }

    async componentDidMount() {
        try {
            this.getOrders();
            
        } catch (error) {
            console.log(error)
            toast.error("Error loading orders page", {closeButton: true})
        }
        
       
    }
    getOrders = async (from, filter) => {
        try {
            const esRes = await OrdersAPI.search({
                limit: this.state.nextLimit !== this.state.limit ? this.state.nextLimit : this.state.limit,
                from: from !== undefined ? from : this.state.from,
                q: this.state.searchQuery,
                filter: filter || this.state.searchFilters
            })
            //console.log(esRes);
            this.setState(state => ({
                limit: this.state.nextLimit !== this.state.limit ? this.state.nextLimit : this.state.limit,
                orders: esRes.orders,
                totalItems: esRes.count,
                lastItem: state.limit + (from >= 0 ? from : state.from),
                from: from !== undefined ? from : state.from
            }))
            this.setState({isLoading: false})
        } catch (error) {
            console.log(error)
            toast.error("Error fetching orders: " + error.message)
            this.setState({isLoading: false})
        } 
    }

    printSlip = async() => {
        if(this.state.selectedOrderIds.length > 0) {
            try {
                const b = {
                    orderIds: this.state.selectedOrderIds,
                    documentType: "deliveryNote",
                    account: this.state.selectedOrders[0].account
                };
                const URL = (await OrdersAPI.printSlip(null, b)).url;
                console.log(URL)
                window.open(URL, "_blank")
            } catch (error) {
                console.log(error)
                toast.error("Error printing slip: " + error.message)
            }
        }
    }

    // Search

    changeSearchInput = e => this.setState({searchQuery: e.target.value})

    searchInputKeyPress = e => {if(e.key === 'Enter') this.getOrders()}

    handleFilter = async (e) => {
        const {id, value} = e.target;
        if (value.length > 0) {
            this.setState(state => ({
                searchFilters: {
                    ...state.searchFilters,
                    [id]: value
                }
            }));
            this.getOrders(0, {
                ...this.state.searchFilters,
                [id]: value
            });
        } else {
            let newFilter = this.state.searchFilters;
            delete newFilter[id];
            this.setState(state => ({
                searchFilters: newFilter
            }));
            this.getOrders(0, newFilter);
        }
    }

    selectOrder = (id) => {
        // Remove Order from selected
        if (this.state.selectedOrderIds.indexOf(parseInt(id)) >= 0) {
            this.setState(state => ({
                selectedOrders: state.selectedOrders.filter(o => o.id !== id),
                selectedOrderIds: state.selectedOrderIds.filter(oid => parseInt(oid) !== parseInt(id))
            }))
        // Add Order to selected
        } else {
            this.setState(state => ({
                selectedOrders: [...state.selectedOrders, this.state.orders.filter(o => o.id.toString() === id.toString())[0]],
                selectedOrderIds: [...state.selectedOrderIds, parseInt(id)]
            }))
        }
    }

    selectAll = () => {
        if(this.state.selectAll) {
            this.setState({
                selectAll: false,
                selectedOrders: [],
                selectedOrderIds: []
            })
        } else {
            this.setState({
                selectAll: true,
                selectedOrders: this.state.orders,
                selectedOrderIds: this.state.orders.map(o => o.id)
            })
        }
    }

    instructShipping = () => {
        this.setState(state => ({
            modals: {
                ...state.modals,
                instrShipping: {
                    isOpen: true,
                    items: state.selectedOrders
                }
            }
        }))
    }

  

    toggleModal = async (id, reset) => {
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen,
                    items: !state.modals[id].isOpen ? state.selectedOrders : []
                }
            },
            selectedOrders: [],
            selectedOrderIds: []
        }));
        if (reset)  {
            await new Promise(resolve => setTimeout(resolve, 2000));
            this.setState({
                selectedOrders: [],
                selectedOrderIds: []
            });
        }
        this.getOrders();
    }

    // Pagination

    prevPage = () => {
        this.getOrders(this.state.lastItem >= this.state.limit ? this.state.lastItem - this.state.limit : this.state.from);
        window.scrollTo(0, 200);
    }

    nextPage = () => {
        this.getOrders(this.state.lastItem);
        window.scrollTo(0, 200);
    }

    toPage = (startKey) => {
        this.getOrders(startKey);
        window.scrollTo(0, 200);
    }

    renderPages = () => {
        const {totalItems, limit, lastItem} = this.state;
        let pageItems = [];
            for (let index = 0; index < (totalItems / limit); index++) {
                if(index < 25) {
                    pageItems.push(<MDBPageItem
                            key={index}
                            active={lastItem / limit === index + 1 ? true : false}
                            onClick={() => {this.toPage(index * limit)}}
                            >
                    <MDBPageNav>
                        {index + 1}
                    </MDBPageNav>
                    </MDBPageItem>)
                }
            }
        return pageItems;
    }
    
render() {
    const styleButtonsBig = {
        width: "100%",
        margin: "0px",
        paddingLeft: "0px",
        paddingRight: "0px"
    
    };
    
    const styleButtonsDuoGroup = {
        width: "50%",
        paddingLeft: "0px",
        paddingRight: "0px"
    };
    
    const styleButtonsTrioGroup = {
        width: "33%",
        paddingLeft: "0px",
        paddingRight: "0px"
    };
    
    const { t } = this.props;

    return (
        <section>
            {/* <Row>
                <Col md="6">
                <Card className="h-100">
                   <CardHeader color="stylish-color">{t("orders.actions", "Actions")}</CardHeader>
                   <CardBody>
                   <Row>
                       <Col>
                           <ListGroup style={{cursor: "pointer"}}>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Create order manually <MDBIcon icon="plus" />
                               </ListGroupItem>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Provision order <MDBIcon icon="history" />
                               </ListGroupItem>
                               <ListGroupItem
                               className="d-flex justify-content-between align-items-center"
                               
                               >
                                   Cancel order <MDBIcon icon="times" />
                               </ListGroupItem>
                           </ListGroup>
                       </Col>
                       <Col>
                           <ListGroup style={{cursor: "pointer"}}>
                               <ListGroupItem className="d-flex justify-content-between align-items-center">
                                   Edit <MDBIcon icon="edit" />
                               </ListGroupItem>
                               <ListGroupItem className="d-flex justify-content-between align-items-center" >
                                   Change Status<MDBIcon icon="edit" />
                               </ListGroupItem>
                               <ListGroupItem
                                    className="d-flex justify-content-between align-items-center"
                                    onClick={() => {this.toggleModal("label")}}
                                >Export<MDBIcon icon="file-export" />
                               </ListGroupItem>
                           </ListGroup>
                       </Col>
                   </Row>
                   </CardBody>
               </Card>
                </Col>
                <Col md="3">
                   <Card className="h-100">
                       <CardHeader color="stylish-color">Payment</CardHeader>
                       <CardBody className="text-center">
                       <Button outline style={styleButtonsBig} >Capture Payment</Button>
                       <hr></hr>
                       <ButtonGroup style={styleButtonsBig}>
                       <Button outline style={styleButtonsDuoGroup} >Invoice</Button>
                       <Button outline style={styleButtonsDuoGroup}>Refund</Button>
                       </ButtonGroup>
                       </CardBody>
                   </Card>
                </Col>
                <Col md="3">
                <Card className="h-100">
                       <CardHeader color="stylish-color">Shippment</CardHeader>
                       <CardBody className="text-center">
                       <ButtonGroup style={styleButtonsBig}>
                       <Button outline style={styleButtonsDuoGroup} >Confirm shipment</Button>
                       <Button outline style={styleButtonsDuoGroup} >instruct shipping</Button>
                       </ButtonGroup>
                       <hr></hr>
                       <ButtonGroup style={styleButtonsBig}>
                       <Button outline style={styleButtonsTrioGroup} >Print Slip</Button>
                       <Button outline style={styleButtonsTrioGroup} >Label</Button>
                       <Button outline style={styleButtonsTrioGroup} >Import</Button>
                       </ButtonGroup>
                       </CardBody>
                   </Card>
                </Col>
            </Row> */}
            {this.props.userData?.group === "reseller" && 
            <select
            className="browser-default custom-select md-form col-4 py-0"
            // value=""
            style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
            id="account"
            onChange={this.handleFilter}>
            <option value="">All Sub Account</option>
            {this.props.userData.managedAccounts?.map(account => <option value={account}>{account}</option>)
            }
            </select>
            }
            <Row className="py-3">
           <Col md="12">
             <Card>
               <CardBody>
                   <Row>
                   <Col size="1">
                        <Button outline size="sm" className="mt-4" onClick={() => this.getOrders()}>
                            <MDBIcon icon="sync" className="mr-0" />
                        </Button>
                    </Col>
                       <Col size="5">
                           <Row>
                               <Col className="px-md-0">
                               <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="status"
                                onChange={this.handleFilter}>
                                <option value="">{t("gen.status", "Status")}</option>
                                <option value="">{t("gen.all", "All")}</option>
                                <option value="verify">{t("states.verify", "With problems")}</option>
                                <option value="unpaid">{t("states.unpaid", "Unpaid")}</option>
                                <option value="unshipped">{t("states.unshipped", "Unshipped")}</option>
                                {/* <option value="prepared">{t("states.prepared", "Prepared")}</option> */}
                                <option value="instructed">{t("states.instructed", "In fulfillment")}</option>
                                <option value="shipped">{t("states.shipped", "Shipped")}</option>
                                <option value="hold">{t("states.holded", "Holded")}</option>
                                <option value="canceled">{t("states.canceled", "Canceled")}</option>
                                </select>
                               
                               </Col>
                               <Col className="px-md-1">
                               <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="store"
                                onChange={this.handleFilter}>
                                <option value="">{t("gen.store", "Store")}</option>
                                <option value="">{t("gen.all", "All")}</option>
                                <option value="amazon">Amazon</option>
                                <option value="shopware">Shopware</option>
                                <option value="ebay">eBay</option>
                                <option value="shopify">Shopify</option>
                                <option value="wooCommerce">WooCommerce</option>
                                </select>
                               
                               </Col>
                               <Col className="px-md-0">
                                <select
                                    className="browser-default custom-select md-form"
                                    // value=""
                                    style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                    id="paymentProvider"
                                    onChange={this.handleFilter}>
                                    <option value="">{t("gen.paymentProvider", "Payment Provider")}</option>
                                    <option value="paypal">PayPal</option>
                                    <option value="amazon_pay">Amazon Pay</option>
                                    <option value="amazon">Amazon Marketplace</option>
                                    <option value="credit_card">Stripe</option>
                                    <option value="sofort">Sofort</option>
                                    <option value="prepayment">Prepayment</option>
                                    </select>
                               </Col>
                               <Col className="px-md-0 text-center align-middle green-text" style={{lineHeight: "85px"}}>
                                <span onClick={() => this.setState({hideFilters: !this.state.hideFilters})}>{this.state.hideFilters ? "More" : "Hide"} filters</span>
                               </Col>
                           </Row>
                            {/* Hidden filters */}
                           <Row style={{display: this.state.hideFilters ? "none" : "flex"}}>
                               <Col className="px-md-0">
                                <select
                                    className="browser-default custom-select md-form"
                                    // value=""
                                    style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                    id="invoiceState"
                                    onChange={this.handleFilter}>
                                    <option value="">Invoice State</option>
                                    <option value="open">Open</option>
                                    <option value="created">Created</option>
                                    <option value="canceled">Canceled</option>
                                    </select>
                               </Col>
                               <Col className="px-md-0">
                               <select
                                    className="browser-default custom-select md-form"
                                    // value=""
                                    style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                    id="shippingProvider"
                                    onChange={this.handleFilter}>
                                    <option value="">Shipping Provider</option>
                                    <option value="dhl">DHL</option>
                                    <option value="dpag">Deutsche Post</option>
                                    <option value="gls">GLS</option>
                                    </select>
                               </Col>
                           </Row>


                       </Col>
                       <Col size="6">
                            <Row>
                                <Col size="10">
                                <MDBInput label="Search"
                                    id="search"
                                    onChange={this.changeSearchInput}
                                    onKeyPress={this.searchInputKeyPress}
                                />
                                </Col>
                                <Col size="2">
                                <Button outline size="sm" className="mt-4" onClick={() => this.getOrders()}>
                                    <MDBIcon icon="search" className="mr-0" />
                                </Button>
                                </Col>
                            </Row>
                       </Col>
                   </Row>
                    <Table responsive className="text-center">
                        <thead data-test="table-head" className="text-white teal">
                            <tr>
                                <th className="" style={{width: "20px"}}>
                                
                                <MDBInput checked={this.state.selectAll} label=" " type="checkbox" id="checkbox1" containerClass="form-check checkbox-white" labelClass="form-check-label" onChange={() => this.selectAll()} />
                                </th>
                                {this.state.selectedOrders.length > 0 ? 
                                <>
                                <th colspan="5" className="text-left py-0" style={{paddingRight: "3em", minWidth: "300px"}}>
                                    <div style={{
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        height: "40px",
                                        marginBottom: "7.5px",
                                        marginLeft: "25px",
                                        borderRadius: "3px",
                                        maxWidth: "900px"
                                    }} className="p-2 d-flex justify-content-around">
                                    <span className="my-auto" onClick={() => {this.toggleModal("changeStatus")}} style={{cursor: "pointer"}}><MDBIcon icon="retweet" /> Change Status</span>
                                    |
                                    <span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="history" /> Provision</span>
                                    |
                                    <span className="my-auto" onClick={() => {this.toggleModal("cancel")}} style={{cursor: "pointer"}}><MDBIcon icon="times" /> Cancel</span>
                                    |
                                    <MDBDropdown>
                                    <MDBDropdownToggle nav className="p-0" color="white">
                                    <span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="dollar-sign" /> Payment</span>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic>
                                        <MDBDropdownItem onClick={() => {this.toggleModal("capturePayment")}}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="money-check-alt" /> Capture Payment</span></MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="hand-holding-usd" /> Refund</span></MDBDropdownItem>
                                    </MDBDropdownMenu>
                                    </MDBDropdown>
                                    |
                                    <MDBDropdown>
                                    <MDBDropdownToggle nav className="p-0" color="white">
                                    <span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="truck" /> Shipping</span>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic>
                                        <MDBDropdownItem onClick={() => {this.toggleModal("label")}}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon far icon="file" /> Create Label</span></MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem onClick={() => {this.toggleModal("markShipped")}}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="shipping-fast" /> Confirm</span></MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem onClick={this.instructShipping}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="warehouse" /> Instruct</span></MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem onClick={() => {this.toggleModal("importTracking")}}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="file-import" /> Import</span></MDBDropdownItem>
                                    </MDBDropdownMenu>
                                    </MDBDropdown>
                                    |
                                    <MDBDropdown>
                                    <MDBDropdownToggle nav className="p-0" color="white">
                                    <span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="file-invoice" /> Documents</span>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu basic>
                                        <MDBDropdownItem onClick={() => {this.toggleModal("invoice")}}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="file-invoice-dollar" /> Invoice</span></MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem onClick={this.printSlip}><span className="my-auto" style={{cursor: "pointer"}}><MDBIcon icon="file-invoice" /> Delivery Note</span></MDBDropdownItem>
                                    </MDBDropdownMenu>
                                    </MDBDropdown>
                                    
                                    
                                    </div>
                                </th>
                                </>
                                :
                                <>
                                <th className="" style={{width: "125px"}}>{t("orders.orderNumber", "Order Number")}</th>
                                <th className="" style={{width: "135px"}}>{t("gen.orderTime", "Order Time")}</th>
                                <th className="" style={{width: "200px"}}>{t("gen.customer", "Customer")}</th>
                                <th className="" style={{width: "150px"}}>{t("gen.products", "Products")}</th>
                                <th className="" style={{width: "200px"}}>{t("gen.progress", "Progress")}</th>
                                
                                </>
                                }
                            </tr>
                        </thead>
                        <OrdersList orders={this.state.orders} selectedOrderIds={this.state.selectedOrderIds} selectOrder={this.selectOrder} selectAll={this.selectAll} isSelectAll={this.state.selectAll} />
                        
                    </Table>
                    
                    {this.state.isLoading && <Loader /> }
                    <Row className="w-100">
                            <Col size="11">
                                <MDBPagination color="teal" style={{maxWidth: "100%", justifyContent: "center", display: "flex"}}>
                                    <MDBPageItem>
                                    <MDBPageNav aria-label="Previous" onClick={this.prevPage}>
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Previous</span>
                                    </MDBPageNav>
                                    </MDBPageItem>
                                    {this.renderPages()}
                                    <MDBPageItem>
                                    <MDBPageNav onClick={this.nextPage}>
                                        &raquo;
                                    </MDBPageNav>
                                    </MDBPageItem>
                                </MDBPagination>
                                </Col>
                                <Col size="1">
                                <MDBInput
                                    containerClass="my-md-0 w-70 float-left"
                                    label="Items"
                                    value={this.state.nextLimit}
                                    onChange={e => this.setState({nextLimit: e.target.value})}
                                    onKeyPress={this.searchInputKeyPress}
                                />
                                <MDBIcon
                                    icon="sync"
                                    className="mr-0 float-right mt-3 teal-text"
                                    onClick={() => this.getOrders()}
                                    style={{width: "30%"}}    
                                />
                            </Col>
                        </Row>
               </CardBody>
             </Card>
           </Col>
         </Row>
         <InstrShippingModal settings={this.state.settings} isOpen={this.state.modals.instrShipping.isOpen} orders={this.state.modals.instrShipping.items} toggle={this.toggleModal} />
         <MarkShippedModal settings={this.state.settings} isOpen={this.state.modals.markShipped.isOpen} orders={this.state.modals.markShipped.items} toggle={this.toggleModal} />
         <ExportModal settings={this.state.settings} isOpen={this.state.modals.export.isOpen} orders={this.state.modals.export.items} toggle={this.toggleModal} />
         <ImportTrackingModal settings={this.state.settings} isOpen={this.state.modals.importTracking.isOpen} orders={this.state.modals.importTracking.items} toggle={this.toggleModal} />
         <CancelModal settings={this.state.settings} isOpen={this.state.modals.cancel.isOpen} orders={this.state.modals.cancel.items} toggle={this.toggleModal} />
         <InvoiceModal settings={this.state.settings} isOpen={this.state.modals.invoice.isOpen} orders={this.state.modals.invoice.items} toggle={this.toggleModal} />
         <CapturePayment isOpen={this.state.modals.capturePayment.isOpen} orders={this.state.modals.capturePayment.items} toggle={this.toggleModal} />
         <LabelModal isOpen={this.state.modals.label.isOpen} orders={this.state.modals.label.items} toggle={this.toggleModal} />
         <StatusModal isOpen={this.state.modals.changeStatus.isOpen} orders={this.state.modals.changeStatus.items} toggle={this.toggleModal} />
         <MDBToastContainer
            hideProgressBar={true}
            newestOnTop={true}
            autoClose={5000}
        />
        </section>
     );
}
  
};


const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(withTranslation()(Orders))