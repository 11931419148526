import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function Marketplace_Shopify(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({refetchLocations: false, connectManualStore: false})


    // Existing connections
    const [stores, setStores] = useState({})
    // For creating new connection
    const [storeName, setStoreName] = useState("")

    // Conenct new store App or Manual select
    const [connectTypeOpen, setConnectTypeOpen] = useState("")

    // Manual setup form
    const [manualForm, updateManualForm] = useState({})
    const [manualStoreRes, setManualStoreRes] = useState({finished: false, success: null, message:""})

    // Configure a connected store
    const [selectedStore, setSelectedStore] = useState({})
    const [isModalOpen, setModalOpen] = useState(false)
    const [isModalRemoveStoreOpen, setModalRemoveStoreOpen] = useState(false)
    

    useEffect(() => {
        getStores()
    }, [props.isActive])

    const getStores = async () => {
        try {
            const resShopifySettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "shopify"}})
            setStores(resShopifySettings.stores)
        } catch (error) {
            console.log(error)
        }
    }

    const updateDefaultLocation = async (storeName, locationId) => {
        if(isModalOpen && (!selectedStore.defaultLocationId || locationId.toString() !== selectedStore.defaultLocationId.toString())) {
            console.log("updating location " + locationId + " for store " + storeName)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {"defaultLocation": parseInt(locationId)}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        defaultLocationId: parseInt(locationId)
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    defaultLocationId: parseInt(locationId)
                })
            } catch (error) {
                console.log(error)
            }
        }
       
    }

    const refetchLocations = async (storeName) => {
        console.log("Refetching locations")
        setIsLoading({...isLoading, refetchLocations: true})
        try {
            const locationsRes = await API.get("ConnectApi", "settings/shopify/refetchlocations", {queryStringParameters: {storeName}})
            setStores({
                ...stores,
                [storeName]: {
                    ...stores[storeName],
                    locations: locationsRes
                }
            })
            setSelectedStore({
                ...selectedStore,
                locations: locationsRes
            })
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, refetchLocations: false})
    }

    const connectManualStore = async () => {
        console.log("Connecting manual store")
        setIsLoading({...isLoading, connectManualStore: true})
        try {
            const connectRes = await API.post("ConnectApi", "settings/shopify/connectmanualstore", {body: {
                credentials: {
                    storeName: manualForm.storeName,
                    access_token: manualForm.token,
                    key: manualForm.key,
                    secret: manualForm.keySecret
                }
            }})
            console.log(connectRes)
            setManualStoreRes({
                finished: true,
                success: true
            })
            updateManualForm({storeName: "", token: "", key: "", keySecret: ""})
            getStores()
        } catch (error) {
            // console.log(error.response)
            setManualStoreRes({
                finished: true,
                success: false,
                message: "Cannot connect store"
            })
        }
        setIsLoading({...isLoading, connectManualStore: false})
    }

    const updateSyncInterval = async (type, value, storeName) => {
        console.log(value)
        if(isModalOpen && value.toString() !== selectedStore.syncintervals[type].toString()) {
            console.log("updating sync interval for " + type + " to: " + value)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {interval: {type, value}}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        syncintervals: {
                            ...stores[storeName].syncintervals,
                            [type]: parseInt(value)
                        }
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    syncintervals: {
                        ...selectedStore.syncintervals,
                        [type]: parseInt(value)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const deleteStore = async (storeName) => {
        console.log("Deleting Store")
        setIsLoading({...isLoading, deleteStore: true})
        try {
            await API.post("ConnectApi", "settings/shopify/removestore", {queryStringParameters: {storeName}})
            setStores(prevState => {
                let state = {...prevState};
                delete state[storeName];
                return state;
            })
            setSelectedStore({})
            setModalRemoveStoreOpen(false);
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, refetchLocations: false})
    }
    

  return (
    <Fragment>
        <MDBContainer className="my-2 my-md-4">
            {Object.keys(stores).length > 0 && <>
                <h4>Connected stores</h4>
                <MDBRow className="mt-4">
                {Object.keys(stores).map(storeHandle => <MDBCol key={storeHandle} size="12" md="6">
                    <MDBCard
                    
                    >
                        <MDBCardBody>
                        
                        <MDBIcon icon="times-circle" className="float-right red-text" onClick={() => {
                            setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                            setModalRemoveStoreOpen(true)
                        }} />
                        <MDBIcon icon="edit" className="float-right mr-2" onClick={() => {
                            setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                            setModalOpen(true)
                        }} />
                            <MDBCardTitle>{storeHandle}<span style={{fontSize: "50%"}}>.myshopify.com</span></MDBCardTitle>
                            <MDBCardText>
                                Connected: {new Date(stores[storeHandle].connectedTime).toLocaleDateString()}<br></br>
                                Connection-Type: {stores[storeHandle].type}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
                </MDBRow>
            </>}
            <h4 className="mt-5">Connect new store</h4>
                <MDBRow className="">
                    <MDBCol className="text-right"><MDBBtn onClick={() => setConnectTypeOpen("app")} outline>Install our app</MDBBtn></MDBCol>
                    <MDBCol><MDBBtn onClick={() => setConnectTypeOpen("manual")} outline>Create custom app</MDBBtn></MDBCol>
                </MDBRow>
            <MDBCollapse id="app" isOpen={connectTypeOpen==="app"}>
                <MDBRow className="mt-5">
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="6">
                        <MDBInputGroup
                            material
                            hint="Store Name"
                            containerClassName="mb-0 mt-1"
                            append=".myshopify.com"
                            value={storeName}
                            onChange={(e) => setStoreName(e.target.value)}
                        />
                    </MDBCol>
                    <MDBCol size="6" md="2">
                        <OauthSender
                            authorizeUrl={"https://" + storeName +".myshopify.com/admin/oauth/authorize"}
                            clientId="4def2e636055c0fee236ee7414bce5b3"
                            redirectUri="https://app.channelhive.com/settings/oauth/shopify"
                            state={{ from: '/settings', storeName: storeName }}
                            args={{scope: "write_orders,write_customers,write_assigned_fulfillment_orders,write_draft_orders,write_fulfillments,write_inventory,read_locales,read_locations,write_merchant_managed_fulfillment_orders,write_products,read_product_listings,read_reports,write_shipping,read_shopify_payments_disputes,write_third_party_fulfillment_orders,write_order_edits"}}
                            render={({ url }) => <MDBBtn color="default" size="sm" disabled={storeName.length < 1} onClick={() => storeName.length > 0 && window.open(url, "_blank")}>Connect</MDBBtn>}
                        />
                    </MDBCol>
                    <MDBCol size="0" md="2"></MDBCol>
                </MDBRow>
            </MDBCollapse>
            <MDBCollapse id="manual" isOpen={connectTypeOpen==="manual"}>
                <ol className="mt-md-4">
                    <li>Log in to your store as the store owner</li>
                    <li>From your Shopify admin, go to Apps</li>
                    <li><MDBIcon icon="mouse-pointer" /> Develop apps</li>
                    <li><MDBIcon icon="mouse-pointer" /> Create an app</li>
                    <li>Enter the app name "ChannelHive"</li>
                    <li><MDBIcon icon="mouse-pointer" /> Create app</li>
                    <li><MDBIcon icon="mouse-pointer" /> Configure Admin API scopes</li>
                    <li>Select the following scopes:<br></br>
                        <ul>
                            <li>Analytics: read_analytics</li>
                            <li>Assigned fulfillment orders - write_assigned_fulfillment_orders</li>
                            <li>Customers - write_customers</li>
                            <li>Draft orders - write_draft_orders</li>
                            <li>Fulfillment services - write_fulfillments</li>
                            <li>Inventory - write_inventory</li>
                            <li>Locations - read_locations</li>
                            <li>Merchant-managed fulfillment orders - write_merchant_managed_fulfillment_orders</li>
                            <li>Order editing - write_order_edits</li>
                            <li>Orders - write_orders</li>
                            <li>Price rules - write_price_rules</li>
                            <li>Product listings - write_product_listings</li>
                            <li>Products - write_products</li>
                            <li>Shipping - write_shipping</li>
                            <li>Third-party fulfillment orders - write_third_party_fulfillment_orders</li>
                        </ul>
                    </li>
                    <li>In the section "Webhook subscriptions", select "2022-04" as the event version</li>
                    <li><MDBIcon icon="mouse-pointer" /> Save</li>
                    <li><MDBIcon icon="mouse-pointer" /> API credentials</li>
                    <li><MDBIcon icon="mouse-pointer" /> Install app, then <MDBIcon icon="mouse-pointer" /> Install</li>
                    <li><MDBIcon icon="mouse-pointer" /> Reveal token once, then copy it</li>
                    <li>Paste the access data here:</li>
                </ol>
                <MDBRow>
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="12" md="8">
                        <MDBInput label="Access token" value={manualForm.token} onChange={(e) => updateManualForm({...manualForm, token: e.target.value})} />
                        <MDBInput label="API key" value={manualForm.key} onChange={(e) => updateManualForm({...manualForm, key: e.target.value})} />
                        <MDBInput label="API secret key" value={manualForm.keySecret} onChange={(e) => updateManualForm({...manualForm, keySecret: e.target.value})} />
                        <MDBInputGroup
                            material
                            hint="Store Name"
                            containerClassName="mb-0 mt-1"
                            append=".myshopify.com"
                            value={manualForm.storeName}
                            onChange={(e) => updateManualForm({...manualForm, storeName: e.target.value})}
                        />
                    </MDBCol>
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="12" className="text-center mt-3 mt-md-4">
                    {isLoading.connectManualStore ? 
                        <div className="spinner-grow text-default" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    :
                    <MDBBtn size="sm" disabled={!(manualForm.token?.length > 20 && manualForm.key?.length > 20 && manualForm.keySecret?.length > 20 && manualForm.storeName?.length > 3)} onClick={() => connectManualStore()}>Connect</MDBBtn>
                    }
                    {manualStoreRes.finished && !manualStoreRes.success && <span className="text-danger">{manualStoreRes.message}</span> }
                    </MDBCol>
                </MDBRow>
                
            </MDBCollapse>  
        </MDBContainer>
                  
        <MDBModal
    isOpen={isModalOpen}
    toggle={() => {
      setModalOpen(false);
    }}
    centered
    size="lg">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}}>
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
        tag='div'
      >Configure store {selectedStore.handle}.myshopify.com
      </MDBCardImage>
    {selectedStore?.handle && 
        <>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Default location for stock and fulfillment</h6>
        <MDBRow>
            <MDBCol size="12" md="9"  className="px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0 && (!selectedStore.defaultLocationId || value !== selectedStore.defaultLocationId.toString())) updateDefaultLocation(selectedStore.handle, value)
                }}
                options={[
                {
                    value: "0",
                    text: "Set default location",
                    disabled: true
                },
                ...selectedStore.locations.map(location => ({
                    value: location.id.toString(),
                    text: location.name,
                    checked: selectedStore.defaultLocationId && selectedStore.defaultLocationId === location.id
                }))
                ]}
                selected={selectedStore.defaultLocationId ? selectedStore.locations.find(location => selectedStore.defaultLocationId === location.id ).name : "Set default location"}
            />
            </MDBCol>
            <MDBCol size="12" md="3">
                <MDBBtn outline size="sm" className="mt-md-4" onClick={() => refetchLocations(selectedStore.handle)}><MDBIcon icon="sync"/>
                    {isLoading.refetchLocations ?
                        " Please wait..."
                    :
                        " Sync from store"
                    }
                </MDBBtn>
            </MDBCol>
        </MDBRow>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Sync intervals</h6>
        <MDBRow>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Orders</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("orders", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set order sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.orders +" Minutes" : "Set order sync interval"}
            />
            </MDBCol>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Stock</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("stock", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set stock sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.stock +" Minutes" : "Set stock sync interval"}
            />
            </MDBCol>
        </MDBRow>
        </>
        
    }
    
      
    </MDBCard>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        outline color="danger"
        onClick={() => { 
            setModalOpen(false);
      }}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>

  <MDBModal
    isOpen={isModalRemoveStoreOpen}
    toggle={() => {
      setModalRemoveStoreOpen(false);
    }}
    centered
    size="lg">
  <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}} className="mb-4">
    <MDBCardImage
        className='red white-text d-flex justify-content-center align-items-center flex-column p-2'
        tag='div'
      >{"Delete Store"}
      </MDBCardImage>
    </MDBCard>
    <p className="mb-0">{"Delete selected Store"}</p>
    <span style={{fontSize: "small"}}>{"This will only affect future orders."}</span>
    {selectedStore.handle}
    </MDBModalBody>
    <MDBModalFooter>
    <MDBBtn outline color="danger" onClick={() => setModalRemoveStoreOpen(false)}>Cancel</MDBBtn>
      <MDBBtn
      onClick={() => deleteStore(selectedStore.handle)}
      disabled={isLoading.deleteStore}
      color="danger"
      >
      {isLoading.deleteStore ?
          <div className="spinner-grow spinner-small white-text" role="status"></div>
      :
          "Delete"
      }</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
    </Fragment>
  )
}
