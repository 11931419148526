import { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardImage,
} from 'mdbreact';
import { useTranslation } from "react-i18next";
import {API} from "aws-amplify";


export default function DeleteProducts(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState({update: false});

  const [products, setProducts] = useState([])
  
  useEffect(() => {
    setProducts(props.products)
  }, [props.products])



  const deleteProduct = async() => {
    if(isLoading.update) return;
    setIsLoading({update: true});
    try {
      const res = await API.del("ConnectApi", "articles", {queryStringParameters: {ids: products.map(p => p.id)}})
    } catch (error) {
      console.log(error)
    }
    setIsLoading({update: false});
  }


  return <MDBModal
    isOpen={props.isOpen}
    toggle={() => {
      props.toggle("deleteProducts");
    }}
    centered
    size="l">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}} className="mb-4">
    <MDBCardImage
        className='red white-text d-flex justify-content-center align-items-center flex-column p-2'
        tag='div'
      >{t("modals.deleteProducts", "Delete Products")}
      </MDBCardImage>
    </MDBCard>
    <p className="mb-0">{t("modals.delteProducts.intro", "Confirm the deletion of the following products:")}</p>
    <span style={{fontSize: "small"}}>{t("modals.delteProducts.subText", "If the products are parent products and contain variants, all variants are also deleted.")}</span>
    {products.length > 0 && <ul>
      {products.map(p => <li key={p.id}>{p.name}</li>)}
    </ul>}
    </MDBModalBody>
    <MDBModalFooter>
    <MDBBtn outline color="danger" onClick={() => {props.toggle("deleteProducts")}}>Cancel</MDBBtn>
      <MDBBtn
      onClick={() => deleteProduct()}
      disabled={isLoading.update}
      color="danger"
      >
      {isLoading.update ?
          <div className="spinner-grow spinner-small white-text" role="status"></div>
      :
          "Delete"
      }</MDBBtn>
    </MDBModalFooter>
  </MDBModal>;
}