import React, { useEffect, useState } from 'react'
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBChip
} from "mdbreact";


// Editor
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { t } from 'i18next';
import { WithContext as ReactTags } from 'react-tag-input';

export default function ProductEdit_General(props) {
    const { product, handleChange } = props;
    useEffect(() => {
        convertHTMLtoDraftInit(product.description?.html || "")
    }, [product.description?.html])

    // Editor
    const [editorState, setEditorState] = useState()
    const [editorModes, setEditorModes] = useState({showDraft: true,showHTML: false})
    const onEditorStateChange = (editorState) => setEditorState(editorState); 
    const convertHTMLtoDraft = e => {
        const contentBlock = htmlToDraft(e.target.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }
    const convertHTMLtoDraftInit = html => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }
    const ChangeHTMLDraft = () => {
    if (editorModes.showDraft && !editorModes.showHTML) {
        setEditorModes({showDraft: false,showHTML: true})
    } else if (!editorModes.showDraft && editorModes.showHTML) {
        setEditorModes({showDraft: true,showHTML: false})
    }
    }
    const ChangeDraftHTMLBtn = () => <div className="rdw-option-wrapper" onClick={ChangeHTMLDraft}><MDBIcon icon="code" /></div>

    // Tags
    const KeyCodes = {
        comma: 188,
        enter: 13
      };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const tags = product.tags?.map(text => ({id: text, text})) || []
    const setTags = tags => handleChange("tags", tags.map(t => t.text))
    
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };
    const handleAddition = tag => {
        setTags([...tags, tag]);
    };
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setTags(newTags);
    };
    const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
    };
  return (
    <>
         <MDBRow className="border-bottom">
            <MDBCol>
            <MDBInput
                label="Product Name"
                id="name"
                value={product.name}
                onChange={e => handleChange("name", e.target.value)}/>
            <MDBRow>
                <MDBCol className="border-right">
                <h5 className="font-weight-normal">Identification</h5>
                <MDBInput label="SKU" id="sku" value={product.sku} onChange={e => handleChange("sku", e.target.value)}/>
                <MDBInput label="EAN" id="ean" value={product.ean} type="text" onChange={e => handleChange("ean", e.target.value)}/>
                <MDBInput
                    label="Category"
                    onClick={() => this.toggleModal("selectCategory")}
                    value={product.category?.name}
                    placeholder={product.category ? product.category.name : "Select category"}
                    disabled
                    />
                <h5 className="font-weight-normal">Properties</h5>
                <MDBRow>
                    <MDBCol size="12">
                    <MDBInput
                        label="Weight"
                        id="weight"
                        icon="weight"
                        type="number"
                        step="0.01"
                        value={product.weight}
                        onChange={e => handleChange("weight", parseFloat(e.target.value))}/>
                    </MDBCol>
                        <MDBCol>
                        <MDBInput
                            label={t("gen.length", "Length")}
                            id="length"
                            icon="expand"
                            type="number"
                            step="0.01"
                            value={product.dimensions?.len}
                            onChange={e => handleChange("dimensions.length", parseFloat(e.target.value))}/>
                        </MDBCol>
                        <MDBCol>
                        <MDBInput
                            label="Width"
                            id="width"
                            icon="expand"
                            type="number"
                            step="0.01"
                            value={product.dimensions?.width}
                            onChange={e => handleChange("dimensions.width", parseFloat(e.target.value))}/>
                        </MDBCol>
                        <MDBCol>
                        <MDBInput
                            label="Height"
                            id="height"
                            icon="arrows-alt-v"
                            type="number"
                            step="0.01"
                            value={product.dimensions?.height}
                            onChange={e => handleChange("dimensions.height", parseFloat(e.target.value))}/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="4" style={{lineHeight: "80px"}}><span>Condition</span></MDBCol>
                        <MDBCol size="8">
                            <select
                                className="browser-default custom-select md-form"
                                // value=""
                                style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                                id="condition"
                                value={product.condition}
                                onChange={e => handleChange("condition", e.target.value)}>
                                <option value="new">New</option>
                                <option value="used-likeNew">Used Like New</option>
                                <option value="used-veryGood">Used Very Good</option>
                                <option value="used-good">Used Good</option>
                                <option value="used-acceptable">Used Acceptable</option>
                            </select>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol>
                <h5 className="font-weight-normal">Origin</h5>
                <MDBRow style={{marginTop: "-0.5rem", marginBottom: "-1.5rem"}}>
                    <MDBCol>
                        <MDBInput
                        label="Manufacturer"
                        id="manufacturer"
                        value={product.manufacturer}
                        onChange={e => handleChange("manufacturer", e.target.value)}/>
                    </MDBCol>
                    <MDBCol>
                        <MDBInput
                        label="Manufacturer SKU"
                        id="manufacturerSKU"
                        value={product.manufacturerSKU}
                        onChange={e => handleChange("manufacturerSKU", e.target.value)}/>
                    </MDBCol>
                </MDBRow>
                <MDBInput label="Manufacturer Delivery Time (Days)" id="manufacturer_deliverytime" value={product.manufacturer_deliverytime} onChange={e => handleChange("manufacturer_deliverytime", e.target.value)}/>
                <MDBInput label="EU Customs Tariff (TARIC)" id="harmonized_system_code" value={product.harmonized_system_code} onChange={e => handleChange("harmonized_system_code", e.target.value)}/>
                <MDBInput label="Origin Country" id="originCountry" value={product.originCountry} onChange={e => handleChange("originCountry", e.target.value)}/>
                
                <h5 className="font-weight-normal">Meta Data</h5>
                <MDBInput
                label="SEO Keywords"
                id="keywords"
                value={product.keywords}
                onChange={e => handleChange("keywords", e.target.value)}/>
                <ReactTags
                tags={tags}
                inputFieldPosition="top"
                placeholder="Tags"
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                classNames={{
                    tagInput: "md-form",
                    tagInputField: "form-control",
                    tag: "chip",
                    remove: "close"
                }}
                />
                
                {/* <MDBInput
                label="Tags"
                id="tags"
                value={product.tags}
                onChange={e => handleChange("tags", e.target.value)}/> */}
                <h5 className="font-weight-normal">Extras</h5>
                <MDBInput
                label="Overwrite name on invoice"
                id="name_invoice"
                value={product.name_invoice}
                onChange={e => handleChange("name_invoice", e.target.value)}/>
                <MDBInput
                label="Overwrite name on customs"
                id="name_customs"
                value={product.name_customs}
                onChange={e => handleChange("name_customs", e.target.value)}/>
                </MDBCol>
            </MDBRow>
            </MDBCol>
        </MDBRow>
        <h5 className="font-weight-normal mt-3 mt-md-4">Descriptions</h5>
        <MDBRow>
            <MDBCol md="6">
            <h6 className="font-weight-normal mt-3 mt-md-4">Short description</h6>
            <MDBInput
                label="Short Description"
                type="textarea"
                id="desc_short"
                value={product.description?.short}
                onChange={e => handleChange("description.short", e.target.value)}/>
            </MDBCol>
            <MDBCol md="6">
            <h6 className="font-weight-normal mt-3 mt-md-4">Package Contents</h6>
            <MDBInput
                id="includes"
                type="textarea"
                value={product.includes}
                onChange={e => handleChange("includes", e.target.value)}/>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
            <h6 className="font-weight-normal mt-3 mt-md-4">Full Description</h6>
            <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName={editorModes.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
            toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
            onEditorStateChange={onEditorStateChange}/>
            <textarea
                onChange={convertHTMLtoDraft}
                className={editorModes.showHTML ? "md-textarea form-control w-100" : "md-textarea form-control w-100 d-none"}
                value={editorState && draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
            <h6 className="font-weight-normal mt-3 mt-md-4">Bulletpoints</h6>
            <MDBInput
                label="1"
                id="bulletpoint1"
                value={product.bulletpoints?.["1"] || ""}
                onChange={e => handleChange("bulletpoints.1", e.target.value)}/>
                <MDBInput
                label="2"
                id="bulletpoint2"
                value={product.bulletpoints?.["2"] || ""}
                onChange={e => handleChange("bulletpoints.2", e.target.value)}/>
                <MDBInput
                label="3"
                id="bulletpoint3"
                value={product.bulletpoints?.["3"] || ""}
                onChange={e => handleChange("bulletpoints.3", e.target.value)}/>
                <MDBInput
                label="4"
                id="bulletpoint4"
                value={product.bulletpoints?.["4"] || ""}
                onChange={e => handleChange("bulletpoints.4", e.target.value)}/>
                <MDBInput
                label="5"
                id="bulletpoint5"
                value={product.bulletpoints?.["5"] || ""}
                onChange={e => handleChange("bulletpoints.5", e.target.value)}/>

            </MDBCol>
        </MDBRow>
    </>
  )
}
