import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBSelectV5, MDBBtn, MDBIcon } from 'mdbreact'
import FilterBuilder, { CustomOperation } from 'devextreme-react/filter-builder';
import TagBox from 'devextreme-react/tag-box';
import 'devextreme/dist/css/dx.light.css';
import {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { filterPre, fields, groupOperations } from './data.js';

import { EditorComponent } from './EditorComponent.js';
import Flow from './components/FlowBuilder';

function calculateFilterExpression(filterValue, field) {
    return filterValue && filterValue.length
      && Array.prototype.concat.apply([], filterValue.map((value) => [[field.dataField, '=', value], 'or'])).slice(0, -1);
  }

export default function Rules_Orders() {

    const [isLoading, setIsLoading] = useState(false)

    const [filter, setFilter] = useState({value: [
        ['Category', 'anyof', ['Automation', 'Monitors']],
        'or',
        [
          ['Category', '=', 'Televisions'],
          'and',
          ['Price', 'between', [2000, 4000]],
        ],
      ]})
    const handleUpdateFilter = useCallback((e) => {
        setFilter({value: e.value, expression: e.component.getFilterExpression()})
    }, [])

    const [variables, setVariables] = useState([])
    const getVariables = async () => {
        try {
            const variablesS3 = (await axios.get("https://s3.eu-central-1.amazonaws.com/cdn.eu.com/ch/helpers/automation-variables.json")).data
            const parsedFields = variablesS3.map(v => ({
                dataField: v.path || v.type,
                caption: v.type,
                dataType: v.dataType || "string",
                filterOperations: v.operations,
                lookup: v.fields ?
                {
                    dataSource: v.fields
                }
                : undefined,
                editorOptions: v.editorOptions
            }))
            setVariables(parsedFields)
        } catch (e) {
            console.log(e)
        }
    }

    const [trigger, setTrigger] = useState({})
    const availableTriggers = [
        {
            handle: "orderChange",
            name: "On any order change or initial import"
        }
    ]

    const availableActions = [
        {
            handle: "instructShipping",
            name: "Instruct Shipping",
            icon: "warehouse",
            maxOccurences: 1,
            options: [
                {
                    type: "select",
                    handle: "fulfiller",
                    name: "Fulfiller",
                    options: useSelector((state) => state.fulfillers?.fulfillers && Object.keys(state.fulfillers.fulfillers).map(key => ({handle: key, name: state.fulfillers.fulfillers[key].name})))
                }
            ]
        },
        {
            handle: "validateAddress",
            name: "Validate Address",
            icon: "map-marked",
            maxOccurences: 1,
            options: [
                {
                    type: "select",
                    handle: "fulfiller",
                    name: "Fulfiller",
                    options: useSelector((state) => state.fulfillers?.fulfillers && Object.keys(state.fulfillers.fulfillers).map(key => ({handle: key, name: state.fulfillers.fulfillers[key].name})))
                }
            ]
        },
        {
            handle: "changeStatus",
            name: "Change Status",
            icon: "retweet",
            maxOccurences: 1,
            options: [
                {
                    type: "select",
                    handle: "status",
                    name: "Status",
                    options: [{handle: "shipped", name: "Shipped"}, {handle: "verify", name: "Need verification"}]
                }
            ]
        }
    ]

    const [actions, setActions] = useState([])
    const addAction = () => {
        setActions([...actions, {type: undefined}])
    }


    useEffect(() => {
        getVariables()
    }, [])


    const saveRule = async () => {
        const rule = {
            trigger: trigger.handle,
            condition: filter.expression,
            actions
        }
        console.log(JSON.stringify(rule))
    }
    
  return (
    <>
        <MDBRow>
            <MDBCol>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>Trigger</MDBCardTitle>
                        <MDBSelectV5
                            style={{width: "100%"}}
                            getValue={(value) => {
                                if(value.length > 0) setTrigger(availableTriggers.find(t => t.handle === value[0]))
                            }}
                            options={[
                            {
                                value: "0",
                                text: "Select rule trigger",
                                disabled: true
                            },
                            ...availableTriggers.map(t => ({value: t.handle, text: t.name}))
                    
                            ]}
                            selected={trigger.handle ? trigger.name : "Select rule trigger"}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
            <MDBCol md="6">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>Condition</MDBCardTitle>
                        <FilterBuilder
                        fields={variables}
                        //value={filter}
                        // onInitialized={this.updateTexts}
                        groupOperations={groupOperations}
                        onValueChanged={handleUpdateFilter}
                        >
                        <CustomOperation
                            name="anyof"
                            caption="Is any of"
                            icon="check"
                            editorComponent={EditorComponent}
                            calculateFilterExpression={calculateFilterExpression}
                        />
                        <CustomOperation
                            name="noneof"
                            caption="Is none of"
                            icon="close"
                            editorComponent={EditorComponent}
                            calculateFilterExpression={calculateFilterExpression}
                        />
                        </FilterBuilder>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol md="6" className="w-100">
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle>Action</MDBCardTitle>
                        {actions.map((action, index) => <MDBCard key={index} className="mb-3">
                            <MDBCardBody>
                                <MDBIcon icon="times-circle" className="float-right red-text" onClick={() => setActions(oldActions => {
                                    let newActions = [...oldActions]
                                    newActions.splice(index, 1)
                                    return newActions
                                })} />
                            
                            <MDBSelectV5
                                        style={{width: "100%"}}
                                        getValue={(value) => {
                                            if(value.length > 0) {
                                                let newActions = [...actions]
                                                newActions[index] = {
                                                    type: value[0],
                                                    typeName: availableActions.find(a => a.handle === value[0]).name
                                                }
                                                setActions(newActions)
                                                console.log("updateda actions")
                                            }
                                            
                                        }}
                                        options={[
                                        {
                                            value: "0",
                                            text: "Select action type",
                                            disabled: true
                                        },
                                        ...availableActions.filter(a => a.maxOccurences ? a.maxOccurences > actions.filter(ac => a.handle === ac.type).length : true).map(a => ({value: a.handle, text: a.name}))
                                
                                        ]}
                                        selected={actions[index].typeName || "Select action type"}
                                    />
                            
                            {availableActions.find(a => a.handle === actions[index].type)?.options?.length > 0 &&
                            availableActions.find(a => a.handle === actions[index].type).options.map(o => {
                                switch (o.type) {
                                    case "select":
                                    return <>
                                    <MDBSelectV5
                                        style={{width: "100%"}}
                                        getValue={(value) => {
                                            if(value.length > 0) {
                                                setActions(oldActions => oldActions.map((a,indexNewItem) => indexNewItem === index ? {...a, options: {...a.options, [o.handle]: {name: o.options.find(op => value[0] === op.handle).name, value: value[0]}}} : a))
                                            }    
                                        }}
                                        options={[
                                        {
                                            value: "0",
                                            text: "Select action type",
                                            disabled: true
                                        },
                                        ...o.options.map(op => ({value: op.handle, text: op.name}))
                                
                                        ]}
                                        selected={actions[index]?.options?.[o.handle]?.name ? actions[index].options[o.handle].name : "Select " + o.name}
                                    />
                                    </>
                                    break;
                                    default: 
                                    console.log("cant find option")
                                    break;
                                }
                                
                            })
                            }
                            </MDBCardBody>
                        </MDBCard>)}
                        <div className="text-center">
                            <MDBBtn size="sm" outline rounded onClick={() => addAction()}>Add</MDBBtn>
                        </div>
                        
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
        <MDBRow className='my-5'>
            <MDBCol size='12'>
                <Flow availableActions={availableActions} />
            </MDBCol>
        </MDBRow>
        <MDBBtn
        onClick={() => saveRule()}
        disabled={isLoading.save}
        className='float-right mt-3 mr-0'
        >
        {isLoading.save ?
            <div className="spinner-grow spinner-small white-text" role="status"></div>
        :
          "Save"
      }</MDBBtn>
    </>
  )
}
