import { Component } from "react";
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,MDBBtn, MDBInput, MDBInputGroup, MDBIcon,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBSelectV5
 } from "mdbreact";
import { OauthSender } from 'react-oauth-flow';
import { connect } from "react-redux";
import {SettingsAPI} from "../../Api/connect";
import DHL from "./ShippingProviders/dhl";
import New_ShippingProvider from "./ShippingProviders/newShippingProvider";



class ShippingProviders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "newShippingProvider",
            shippingProviders: props.shippingProviders,
            profile: ""
        }

    }

    componentWillReceiveProps(props) {
        this.setState({shippingProviders: props.shippingProviders})
    }

    

toggleTabs = tab => () => {
    if (this.state.activeTab !== tab) {
    this.setState({
        activeTab: tab
    });
    }
    }

    
render() {
    return (
        <section>
           <MDBRow>
               <MDBCol sm="4">
                <MDBListGroup className="my-4 mx-4" style={{ width: "22rem" }}>
                        {this.state.shippingProviders.active.length > 0 && this.state.shippingProviders.active.map(shippingProvider => <MDBListGroupItem color="light" onClick={this.toggleTabs(shippingProvider.handle)}>{shippingProvider.name}</MDBListGroupItem>)}
                        <MDBListGroupItem className="default-color white-text text-center" onClick={this.toggleTabs("newShippingProvider")}><MDBIcon icon="plus" /></MDBListGroupItem>
                    </MDBListGroup>
               </MDBCol>
                <MDBCol sm="8">
                <MDBTabContent className="card p-0" activeItem={this.state.activeTab}>
                <CardHeader color="stylish-color">{this.state.activeTab.charAt(0).toUpperCase() + this.state.activeTab.slice(1)}</CardHeader>
                <MDBTabPane tabId="dhl">
                   <DHL isActive={this.state.activeTab === "dhl"} />
                    
                </MDBTabPane>
                <MDBTabPane tabId="newShippingProvider">
                   <New_ShippingProvider activeShippingProviders={this.state.shippingProviders.active} toggleShippingProvider={this.toggleTabs} />
                </MDBTabPane>
                </MDBTabContent>
                </MDBCol>
           </MDBRow>
        </section>
     );
}
  
};


const mapStateToProps = (state, ownProps) => ({
    shippingProviders: state.shippingProviders,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(ShippingProviders)