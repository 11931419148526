const conditions = {
    ean: p => !!p.ean,
    price: p => !!p.price.amazon,
    stock: p => !!p.stock,
    sku: p => !!p.sku,
}

export const product = p => {
    try {

        let errors = [];
        Object.keys(conditions).forEach(con => {
            if (!conditions[con](p)) errors.push(con)
        });

        if (errors.length > 0) {
            throw Error("Missing or wrong value for: " + errors.map(e => e.toUpperCase()).join())
        }

        return true;
    } catch (e) {
        console.log("ErrorTrown: " + e);
        return false;
    }
 
}