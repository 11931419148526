import { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBtnGroup,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  Table,
  TableHead,
  TableBody,
  MDBInput,
  MDBToastContainer,
  toast,
  MDBCard,
  MDBCardImage 
} from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import images from "../images";

export default class PrintLabelModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        notifyMarketplace: true,
        isLoading: false,
        orders: props.orders,
      trackingInfos: props
        .orders
        .map(order => ({orderId: order.id, provider: "dhl"})), // {orderId, trackingId, trackingProvider, shippTime}
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
        orders: props.orders,
        trackingInfos: props
            .orders
            .map(order => ({orderId: order.id, provider: "dhl"}))
        }) // {orderId, trackingId, trackingProvider, shippTime}})
  }

  createLabel = async orders => {
    const body = {
        labelRequests: orders.map(order => ({orderId: order.id, provider: "dhl"})), // order.shippingProvider
        notifyMarketplace: this.state.notifyMarketplace
      };
      try {
        console.log(body)
        const res = await OrdersAPI.createLabels(body);
        const { trackingInfos, printAllUrls } = res;
        this.setState(state => ({
            orders: state.orders.map(order => ({
                ...order,
                tracking: {
                    ...order.tracking,
                    id: trackingInfos.filter(item => item.orderId === order.id)[0].trackingId,
                    provider: trackingInfos.filter(item => item.orderId === order.id)[0].trackingProvider,
                    labelUrl: trackingInfos.filter(item => item.orderId === order.id)[0].labelUrl,
                    exportLabelUrl: trackingInfos.filter(item => item.orderId === order.id)[0].exportLabelUrl
                }
            })),
            trackingInfos: this.props.orders.map(order => ({orderId: order.id, provider: "dhl", trackingId: trackingInfos.filter(item => item.orderId === order.id)[0].trackingId})),
            printAllUrls
        }))
        console.log(res);
       
      } catch (error) {
        console.log(error)
      }
  }
  renderSelect = (fulfillers) => {
    const options = Object
      .entries(fulfillers)
      .map(f => (
        <option value={f[0]}>{f[1].name}</option>
      ))

    return (
      <select className="browser-default custom-select" // value=""
  style={{
        backgroundColor: "transparent",
        border: "none",
        borderBottom: "1px solid #ced4da",
        borderRadius: "0"
      }} onChange={this.changeFulfiller}>
        <option>Choose Shipping Provider</option>
        {options}
      </select>
    )
  }
  renderOrders = (orders) => {
    if (!orders) {
      return
    }
    return orders.map((order) => ({
      "orderNumber": <span>{order.id}<br/>
        <span className="grey-text">{order.storeOrderId}</span>
      </span>,
      "store": images[order.store],
      "address": <span>{order.addresses.shipping.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.addresses.shipping.street}<br />{order.addresses.shipping.zip + " " + order.addresses.shipping.city}<br />{order.addresses.shipping.country}</span>,
      "articles": <span>{order.articles.names.map((name, index) => <span>{name.substring(0, 30) + (name.length > 30 && "...")}<br /><span className="grey-text">{order.articles.skus[index]}</span></span>)}</span>,
      "provider": <select className="browser-default custom-select" // value=""
  style={{
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "1px solid #ced4da",
          borderRadius: "0"
        }} onChange={this.changeTemplate}>
          <option>Provider</option>
          <option value="dhl" selected>DHL</option>
          <option value="ups">UPS</option>
          <option value="fba">FBA</option>
        </select>,
        "trackingId": <MDBInput
        value={this.state.trackingInfos && this.state.trackingInfos.filter(ti => ti.orderId === order.id)[0].trackingId}
        onChange={this.changeTrackingId}
        id={order
        .id
        .toString()}/>,
        label: <>
            <MDBBtnGroup>
                <MDBBtn rounded size="sm" color=" rgba-stylish-light" onClick={() => this.createLabel(this.state.orders.filter(o => o.id = order.id))}>Create</MDBBtn>
                {order.tracking && order.tracking.labelUrl && 
                <MDBBtn rounded size="sm" color=" rgba-green-strong" onClick={() => window.open(order.tracking.labelUrl)}>Print</MDBBtn>
                }
                {order.tracking && order.tracking.exportLabelUrl && 
                <MDBBtn rounded size="sm" color=" rgba-green-strong" onClick={() => window.open(order.tracking.exportLabelUrl)}>Export Docs</MDBBtn>
                }
                {order.tracking && order.tracking.labelUrl && 
                <MDBBtn outline rounded size="sm" color=" rgba-red-light" onClick={() => this.execute(false)}>Cancel</MDBBtn>
                }
            </MDBBtnGroup>
        </>
    }))
  };

  execute = async(notifyMarketplace) => { // Set Shipped and Notify
    const body = {
      trackingInfos: this
        .state
        .trackingInfos
        .map(trackingInfo => ({
          ...trackingInfo,
          shippTime: this.state.shipTime
        })),
      notifyMarketplace: this.state.notifyMarketplace
    };
    try {
      console.log(body)
      await OrdersAPI.updateOrderStatus(null, body);
      console.log("Send");
      this
        .props
        .toggle("label", true);
    } catch (error) {
      console.log(error)
    }
  }

  changeTrackingId = e => {
    const orderId = parseInt(e.target.id);
    const trackingID = e.target.value;

    this.setState(state => ({
      trackingInfos: state
        .trackingInfos
        .map(trackingInfo => {
          if (trackingInfo.orderId !== orderId) {
            return trackingInfo;
          } else {
            return ({
              ...trackingInfo,
              trackingId: trackingID
            });
          }
        })
    }))
  }

  changeTrackingProvider = e => {
    const orderId = parseInt(e.target.id);
    const trackingProvider = e.target.value;

    this.setState(state => ({
      trackingInfos: state
        .trackingInfos
        .map(trackingInfo => {
          if (trackingInfo.orderId !== orderId) {
            return trackingInfo;
          } else {
            return ({
              ...trackingInfo,
              provider: trackingProvider
            });
          }
        })
    }))
  }

  getShipDate = date => {
    this.setState({
      shipTime: date.toISOString()
    });
    console.log(date);
  }

  render = () => {
    const data_checkboxes = {
      columns: [
        {
          'label': 'Order number',
          'field': 'orderNumber',
          'sort': 'asc'
        }, {
          'label': 'Store',
          'field': 'store',
          'sort': 'asc'
        }, {
          'label': 'Address',
          'field': 'address',
          'sort': 'asc'
        }, {
          'label': 'Articles',
          'field': 'articles',
          'sort': 'asc'
        },
        {
            'label': 'Provider',
            'field': 'provider',
            'sort': 'asc'
        }, {
          'label': 'Tracking ID',
          'field': 'trackingId',
          'sort': 'asc'
        }, {
            'label': 'Label',
            'field': 'label',
            'sort': 'asc'
          }

      ]
    };
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        contentClassName="modalTeal"
        toggle={() => {
        this
          .props
          .toggle("label")
      }}
        centered
        size="xl">
        {/* <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("label")
        }}>Print Label</MDBModalHeader> */}
        <MDBModalBody>
        <MDBCard style={{margin: "-1rem"}}>
        <MDBCardImage
            className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
            tag='div'
          >
            <h2>Print Label</h2>
          </MDBCardImage>

        </MDBCard>
          <Table responsive className="text-center">
            <TableHead columns={data_checkboxes.columns}/>
            <TableBody rows={this.renderOrders(this.state.orders)}/>
          </Table>
          <MDBRow className="mt-5">
            <MDBCol size="12" md="12" className="text-right">
            <MDBBtnGroup>
                <MDBBtn rounded size="sm" color=" rgba-stylish-light" onClick={() => this.createLabel(this.state.orders)}>Create all</MDBBtn>
                {this.state.printAllUrls && 
                    <MDBBtn size="sm" rounded color=" rgba-green-strong" onClick={() => window.open(this.state.printAllUrls)}>Print all</MDBBtn>
                }
                {this.state.printAllUrls && 
                    <MDBBtn size="sm" rounded color=" rgba-red-light" onClick={() => this.execute(false)}>Cancel all</MDBBtn>
                }
            </MDBBtnGroup>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn  rounded
            outline color="danger"
            onClick={() => {
            this
              .props
              .toggle("label")
          }}>Cancel</MDBBtn>
          <MDBInput containerClass="w-200" label="Notify Marketplace" type="checkbox" id="notifyMarketplaceCheckbox"
          checked={this.state.notifyMarketplace}
          onChange={e => this.setState({notifyMarketplace: e.target.checked})}
           />
          <MDBBtn  rounded
          onClick={() => this.execute()}
          >
          {this.state.isLoading ?
            <div className="spinner-grow spinner-small white-text" role="status"></div>
          :
            "Update"
          }</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}