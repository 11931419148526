import { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardImage,
  MDBSelectV5
} from 'mdbreact';
import {API} from 'aws-amplify';
const availableStates = [
    {
        value: "verify",
        text: "Need verification"
    },
    {
        value: "unpaid",
        text: "Unpaid"
    },
    {
        value: "canceled",
        text: "Canceled"
    },
    {
        value: "shipped",
        text: "Shipped"
    },
    {
        value: "instructed",
        text: "Instructed"
    },
    {
        value: "unshipped",
        text: "unshipped"
    }
]

export default function ChangeStatus(props) {
  const [isLoading, setIsLoading] = useState({update: false});
  const [selectedStatus, setSelectedStatus] = useState("")
  
  useEffect(() => {
    
  }, [props.orders])



  const update = async() => {
    if(isLoading.update) return;
    setIsLoading({update: true});
    try {
      const res = await API.post("ConnectApi", "orders/update", {body: {orders: props.orders.map(o => ({id: o.id, updateAttributes: {
        status: selectedStatus
    }}))}})
    } catch (error) {
      console.log(error)
    }
    setIsLoading({update: false});
  }


  return <MDBModal
    isOpen={props.isOpen}
    toggle={() => {
      props.toggle("changeStatus");
    }}
    centered
    size="xl">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}} className="mb-4">
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2'
        tag='div'
      >Change Status
      </MDBCardImage>
    </MDBCard>
    {props.orders.length > 0 && 
    <ul>
        {props.orders.map(o => <li key={o.id}>{o.id + " " + o.customerName}</li>)}
    </ul>}
    </MDBModalBody>
    <MDBModalFooter>
    <MDBSelectV5
        style={{width: "100%"}}
        getValue={(value) => {
            if(value.length > 0) setSelectedStatus(value[0])
        }}
        options={[
        {
            value: "0",
            text: "Set new status",
            disabled: true
        },
        ...availableStates
        ]}
        selected={selectedStatus ? availableStates.find(s => s.value === selectedStatus).text : "Set new status"}
    />
    <MDBBtn outline color="danger" onClick={() => {props.toggle("changeStatus")}}>Cancel</MDBBtn>
      <MDBBtn
      onClick={() => update()}
      disabled={isLoading.update}
      >
      {isLoading.update ?
          <div className="spinner-grow spinner-small white-text" role="status"></div>
      :
          "Update"
      }</MDBBtn>
    </MDBModalFooter>
  </MDBModal>;
}