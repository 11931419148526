import React, { Component, Suspense, useEffect, useState } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Fa
} from "mdbreact";

export default function LangSelect () {
    const { t, i18n } = useTranslation();

    const [lngs, setLngs] = useState({ en: { nativeName: 'English' }});

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages((err, ret) => {
        if (err) return // TODO: handle err...
        //console.log("RES", ret)
        setLngs(ret);
        });
    }, []);
    //console.log("LNGS", lngs)

    const handleChangeLang = lng => {
      i18n.changeLanguage(lng)
    }

    return <Dropdown>
      <DropdownToggle nav caret>
        
        <div className="d-none d-md-inline">
        <ReactCountryFlag
                countryCode={i18n.language == "en" ? "gb" : i18n.language}
                svg
                style={{
                    width: '20px',
                    height: '20px',
                }}
                title={i18n.language}
            /></div>
      </DropdownToggle>
      <DropdownMenu right>
      { 
        Object.keys(lngs).map((lng) => (
          <DropdownItem
          key={lng}
          style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}
          onClick={() => handleChangeLang(lng)}>
            <ReactCountryFlag
                countryCode={lng == "en" ? "gb" : lng}
                svg
                style={{
                    width: '20px',
                    height: '20px',
                }}
                title={i18n.language}
            /> {lngs[lng].nativeName}
          </DropdownItem>
        ))
      }
      </DropdownMenu>
    </Dropdown>
}