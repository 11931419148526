import React, { Fragment, useState, useEffect } from 'react'
import { 
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBIcon, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBSelectV5,
    MDBContainer
 } from "mdbreact";
 import { OauthSender } from 'react-oauth-flow';
 import {API} from 'aws-amplify';

export default function Marketplace_Amazon(props) {
    // Active requests in process
    const [isLoading, setIsLoading] = useState({refetchLocations: false, connectManualStore: false})

    const [availableMarketplaces, setAvailableMarketplaces] = useState([])
    const [selectedMarketplace, setSelectedMarketplace] = useState("")



    // Existing connections
    const [stores, setStores] = useState({})
    // For creating new connection
    const [storeName, setStoreName] = useState("")

    // Conenct new store App or Manual select
    const [connectTypeOpen, setConnectTypeOpen] = useState("")

    // Manual setup form
    const [manualForm, updateManualForm] = useState({})
    const [manualStoreRes, setManualStoreRes] = useState({finished: false, success: null, message:""})

    // Configure a connected store
    const [selectedStore, setSelectedStore] = useState({})
    const [isModalOpen, setModalOpen] = useState(false)
    

    useEffect(() => {
        getStores();
        getAvailableMarketplaces()
    }, [props.isActive])
    
    const getStores = async () => {
        try {
            const resAmazonSettings = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "amazon"}})
            setStores(resAmazonSettings.stores)
        } catch (error) {
            console.log(error)
        }
    }

    const getAvailableMarketplaces = async () => {
        try {
            const resGetAvailableMarketplaces = await API.get("ConnectApi", "/settings/marketplaces/amazon/availablemarketplaces")
            setAvailableMarketplaces(resGetAvailableMarketplaces)
        } catch (error) {
            console.log(error)
        }
    }



    const updateDefaultLocation = async (storeName, locationId) => {
        if(isModalOpen && (!selectedStore.defaultLocationId || locationId.toString() !== selectedStore.defaultLocationId.toString())) {
            console.log("updating location " + locationId + " for store " + storeName)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {"defaultLocation": parseInt(locationId)}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        defaultLocationId: parseInt(locationId)
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    defaultLocationId: parseInt(locationId)
                })
            } catch (error) {
                console.log(error)
            }
        }
       
    }

    const refetchLocations = async (storeName) => {
        console.log("Refetching locations")
        setIsLoading({...isLoading, refetchLocations: true})
        try {
            const locationsRes = await API.get("ConnectApi", "settings/shopify/refetchlocations", {queryStringParameters: {storeName}})
            setStores({
                ...stores,
                [storeName]: {
                    ...stores[storeName],
                    locations: locationsRes
                }
            })
            setSelectedStore({
                ...selectedStore,
                locations: locationsRes
            })
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, refetchLocations: false})
    }

    const connectManualStore = async () => {
        console.log("Connecting manual store")
        setIsLoading({...isLoading, connectManualStore: true})
        try {
            const connectRes = await API.post("ConnectApi", "settings/shopify/connectmanualstore", {body: {
                credentials: {
                    storeName: manualForm.storeName,
                    access_token: manualForm.token,
                    key: manualForm.key,
                    secret: manualForm.keySecret
                }
            }})
            console.log(connectRes)
            setManualStoreRes({
                finished: true,
                success: true
            })
            updateManualForm({storeName: "", token: "", key: "", keySecret: ""})
            getStores()
        } catch (error) {
            // console.log(error.response)
            setManualStoreRes({
                finished: true,
                success: false,
                message: "Cannot connect store"
            })
        }
        setIsLoading({...isLoading, connectManualStore: false})
    }

    const updateSyncInterval = async (type, value, storeName) => {
        console.log(value)
        if(isModalOpen && value.toString() !== selectedStore.syncintervals[type].toString()) {
            console.log("updating sync interval for " + type + " to: " + value)
            try {
                await API.post("ConnectApi", "settings/shopify/update", {queryStringParameters: {storeName: storeName}, body: {interval: {type, value}}})
                setStores({
                    ...stores,
                    [storeName]: {
                        ...stores[storeName],
                        syncintervals: {
                            ...stores[storeName].syncintervals,
                            [type]: parseInt(value)
                        }
                    }
                })
                setSelectedStore({
                    ...selectedStore,
                    syncintervals: {
                        ...selectedStore.syncintervals,
                        [type]: parseInt(value)
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }
    

  return (
    <Fragment>
        <MDBContainer className="my-2 my-md-4">
            {Object.keys(stores).length > 0 && <>
                <h4>Connected stores</h4>
                <MDBRow className="mt-4">
                {Object.keys(stores).map(storeHandle => <MDBCol key={storeHandle} size="12" md="6">
                    <MDBCard
                    onClick={() => {
                        setSelectedStore({handle: storeHandle, ...stores[storeHandle]});
                        setModalOpen(true)
                    }}
                    >
                        <MDBCardBody>
                            <MDBCardTitle>{storeHandle}<span style={{fontSize: "50%"}}> Selling Partner ID</span></MDBCardTitle>
                            <MDBCardText>
                                Connected: {new Date(stores[storeHandle].connectedTime).toLocaleDateString()}<br></br>
                                Connection-Type: {stores[storeHandle].type}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
                </MDBRow>
            </>}
            <h4 className="mt-5">Connect new store</h4>
                <MDBRow className="mt-5">
                    <MDBCol size="0" md="2"></MDBCol>
                    <MDBCol size="6">
                    <MDBSelectV5
                        style={{width: "100%"}}
                        getValue={(value) => {
                            if(value.length > 0 && value !== selectedMarketplace) setSelectedMarketplace(value[0])
                        }}
                        options={[
                        {
                            value: "0",
                            text: "Select Amazon Marketplace",
                            disabled: true
                        },
                        ...availableMarketplaces.map(marketplace => ({
                            value: marketplace.url,
                            text: marketplace.countryName,
                            checked: selectedMarketplace === marketplace.url
                        }))
                        ]}
                        selected={availableMarketplaces.length > 0 && availableMarketplaces.find(marketplace => selectedMarketplace === marketplace.url)?.countryName || "Select Amazon Marketplace"}
                    />
                    </MDBCol>
                    <MDBCol size="6" md="2">
                    <MDBBtn className="mt-4" color="default" size="sm" disabled={!selectedMarketplace}
                    onClick={() => selectedMarketplace && window.open(selectedMarketplace +"/apps/authorize/consent?application_id=amzn1.sp.solution.b190a02c-c332-4c9d-baed-050e89d0dace&state=a&redirect_uri=https%3A%2F%2Fapp.channelhive.com%2Foauth%2Famazon", "_blank")}>
                    Connect</MDBBtn>
                    </MDBCol>
                    <MDBCol size="0" md="2"></MDBCol>
                </MDBRow>
        </MDBContainer>
                  
        <MDBModal
    isOpen={isModalOpen}
    toggle={() => {
      setModalOpen(false);
    }}
    centered
    size="lg">

    <MDBModalBody>
    <MDBCard style={{margin: "-1rem"}}>
    <MDBCardImage
        className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
        tag='div'
      >Configure store {selectedStore.handle}.myshopify.com
      </MDBCardImage>
    {selectedStore?.handle && 
        <>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Default location for stock and fulfillment</h6>
        <MDBRow>
            <MDBCol size="12" md="9"  className="px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0 && (!selectedStore.defaultLocationId || value !== selectedStore.defaultLocationId.toString())) updateDefaultLocation(selectedStore.handle, value)
                }}
                options={[
                {
                    value: "0",
                    text: "Set default location",
                    disabled: true
                },
                ...selectedStore.locations.map(location => ({
                    value: location.id.toString(),
                    text: location.name,
                    checked: selectedStore.defaultLocationId && selectedStore.defaultLocationId === location.id
                }))
                ]}
                selected={selectedStore.defaultLocationId ? selectedStore.locations.find(location => selectedStore.defaultLocationId === location.id ).name : "Set default location"}
            />
            </MDBCol>
            <MDBCol size="12" md="3">
                <MDBBtn outline size="sm" className="mt-md-4" onClick={() => refetchLocations(selectedStore.handle)}><MDBIcon icon="sync"/>
                    {isLoading.refetchLocations ?
                        " Please wait..."
                    :
                        " Sync from store"
                    }
                </MDBBtn>
            </MDBCol>
        </MDBRow>
        <h6 className="ml-3 ml-md-4 font-weight-bold">Sync intervals</h6>
        <MDBRow>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Orders</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("orders", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set order sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.orders +" Minutes" : "Set order sync interval"}
            />
            </MDBCol>
            <MDBCol size="6" className="text-center">
                <p className="mt-md-4">Stock</p>
            </MDBCol>
            <MDBCol size="6" className="px-1 px-md-5">
            <MDBSelectV5
                style={{width: "100%"}}
                getValue={(value) => {
                    if(value.length > 0) updateSyncInterval("stock", value[0], selectedStore.handle)
                }}
                options={[
                {
                    value: "0",
                    text: "Set stock sync interval",
                    disabled: true
                },
                {
                    value: "10",
                    text: "Every 10 Minutes"
                },
                {
                    value: "20",
                    text: "Every 20 Minutes"
                },
                {
                    value: "30",
                    text: "Every 30 Minutes"
                },
                {
                    value: "60",
                    text: "Every 60 Minutes"
                }
                
                ]}
                selected={selectedStore.syncintervals?.orders ? "Every "+ selectedStore.syncintervals.stock +" Minutes" : "Set stock sync interval"}
            />
            </MDBCol>
        </MDBRow>
        </>
        
    }
    
      
    </MDBCard>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn
        outline color="danger"
        onClick={() => { 
            setModalOpen(false);
      }}>Close</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
    </Fragment>
  )
}
