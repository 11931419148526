import React, { useState, useRef, useCallback } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
} from 'react-flow-renderer';

import Sidebar from './Sidebar';

import ValidateAddressNode from './Actions/ValidateAddress.js';
import InstructShippingNode from './Actions/InstructShipping.js';

const nodeTypes = {
    validateAddress: ValidateAddressNode,
    instructShipping: InstructShippingNode
  };


import './index.css';

const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Start' },
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    type: 'output',
    data: { label: 'End' },
    position: { x: 0, y: 400 },
  },
];

let id = 0;
const getId = () => `dndnode_${id++}`;

const DnDFlow = (props) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge({...params, animated: true, type: "step"}, eds)), []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      const optionData = event.dataTransfer.getData('application/reactflow/optionData');


      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node`, optionData: optionData ? JSON.parse(optionData): "" },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  return (
    <div className="dndflow">
      <ReactFlowProvider>
      <Sidebar availableActions={props.availableActions} />
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitView
          >
            <Controls />
            <Background variant="dots" style={{backgroundColor: "#fff"}} />
          </ReactFlow>
        </div>
        
      </ReactFlowProvider>
    </div>
  );
};

export default DnDFlow;
