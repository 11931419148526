import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCol, MDBIcon, MDBInput, MDBRow, MDBSelectV5 } from 'mdbreact';
import React, { useState, useEffect } from 'react'
import {API} from "aws-amplify";
import { Icon } from '@aws-amplify/ui-react';

export default function EditProduct_Marketplacelistings(props) {
    const { product, handleChange } = props;

    // Available Marketplaces
    const [availableMarketplaces, setAvailableMarketplaces] = useState([])

    const getAvailableMarketplaces = async () => {
        try {
            const res = await API.get("ConnectApi", "system/availableproviders", {queryStringParameters: {type: "marketplaces"}})
            setAvailableMarketplaces(res.available)
        } catch (error) {
            console.log(error)
        }
    }

    const toSingleMatches = storeMatch => {
        let matches = []
        Object.keys(storeMatch).forEach(storeHandle => {
            storeMatch[storeHandle].forEach(storeSKU => {
                matches.push({store: storeHandle, sku: storeSKU})
            })
        })
        return matches
    }

    const removeMatch = match => {
        handleChange("storeMatch", {
            ...product.storeMatch,
            [match.store]: product.storeMatch[match.store].filter(i => i !== match.sku)
        })
    }

    const [createMatch, setCreateMatch] = useState({})

    const addMatch = () => {

    }


    useEffect(() => {
        if(availableMarketplaces.length === 0) {
            getAvailableMarketplaces()
        }
    }, [])

    return (
        <>
            <h5 className="font-weight-normal mt-3 mt-md-4">Marketplace matches</h5>
            {product.storeMatch && Object.keys(product.storeMatch).length > 0 && availableMarketplaces.length > 0 && 
            <MDBRow>
                {toSingleMatches(product.storeMatch).map(match => <MDBCol size="6" md="3">
                    <MDBCard>
                        <MDBCardBody>
                        <MDBIcon icon="times-circle" className="float-right red-text" onClick={e => removeMatch(match)} />
                            <MDBCardText>{availableMarketplaces.find(mp => mp.handle === match.store).name} - {match.sku}</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)}
            </MDBRow>
                
            }
            <h5 className="font-weight-normal mt-3 mt-md-4">Add marketplace matching</h5>
            <MDBRow className="mx-auto" style={{maxWidth: "500px"}}>
                <MDBCol size="6">
                    <MDBSelectV5
                    className="float-left"
                    options={[
                    {
                        value: "0",
                        text: "Marketplace",
                        disabled: true
                    },
                    ...availableMarketplaces.map(marketplace => ({
                        icon: marketplace.logoUrl,
                        value: marketplace.handle,
                        text: marketplace.name
                    }))
                    ]}
                    selected="Select Marketplace"
                    />
                </MDBCol>
                <MDBCol size="6">
                    <MDBInput type="text" label="Marketplace SKU" value={createMatch.sku} onChange={e => setCreateMatch(match => ({...match, sku: e.target.value}))} />
                </MDBCol>
                <MDBCol size="12" className="text-center">
                    <MDBBtn outline>Add</MDBBtn>
                </MDBCol>
            </MDBRow>
                
                
        </>
        
    )
}
