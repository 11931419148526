import { Component, useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  Input,
  MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent
} from 'mdbreact';
import {ProductsAPI} from "../../Api/connect";
import {useDropzone} from 'react-dropzone';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import SelectCategoryModal from "./ProductChooseCategory.js";

class EditCreateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      type: "create",
      productId: "",
      activeSection: "general",
      images: {
        additional: []
      },
      category: {},
      editor: {
        showDraft: true,
        showHTML: false
      },
      modals: {
        selectCategory: {
          isOpen: false
        }
      }
    }
  }

//   componentDidMount() {
//     if (this.props.productId && (!this.state.product || this.props.productId !== this.state.product.id)) {
//       this.getProduct(this.props.productId);
//     }
//   }

  componentWillReceiveProps(props) {
    if (props.type === "update" && this.props.productId && (!this.state.product || this.props.productId !== this.state.product.id)) {
        this.setState({type: "update"});
      this.getProduct(this.props.productId);
    } else if (props.type === "create") {
        this.setState({type: "create", product: {}});
    }
  }

  getProduct = async id => {
    try {
      const products = await ProductsAPI.getProducts({id: id});
      const p = products[0];
      this.setState({
        product: {
          ...p,
          condition: p.condition || "new",
          amazon: p.price && p.price.amazon,
          ebay: p.price && p.price.ebay,
          shopware: p.price && p.price.shopware,
          idealo_direkt: p.price && p.price.idealo_direkt,
          ek: p.price && p.price.ek,
          uvp: p.price && p.price.uvp,
          desc_short: p.description && p.description.short,
          keywords: p.seo && p.seo.keywords,
          stock: p.stock && p.stock.own,
          minStock: p.stock && p.stock.min,
          stockFBA: p.stock && p.stock.fba,
          stockSolectric: p.stock && p.stock.solectric,
          stockEbayRefill: p.marketplaces && p.marketplaces.ebayRefill,
          height: p.dimensions && p.dimensions.height,
          width: p.dimensions && p.dimensions.width,
          length: p.dimensions && p.dimensions.length
        },
        images: p.images,
        category: p.category,
        productId: p.id
      });
      this.convertHTMLtoDraftInit(p.description && p.description.html)
  } catch (error) {
      console.log(error)
  } 
  }

  execute = () => {
    switch (this.state.type) {
      case "create": 
        this.createProduct();
        break;
      case "update": 
        this.updateProduct();
        break;
    
      default:
        break;
    }
  }

  createProduct = async() => {
    const body = {
      product: {
        category: this.state.category,
        condition: this.state.product.condition || "new",
        description: {
          html: this.state.editorState && draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
          short: this.state.product.desc_short
        },
        dimensions: {
          height: this.state.product.height,
          length: this.state.product.length,
          width: this.state.product.width
        },
        ean: this.state.product.ean.toString(),
        images: {
          additional: this.state.images.additional,
          main: this.state.images.main
        },
        includes: this.state.product.includes,
        manufacturer: this.state.product.manufacturer,
        manufacturerSKU: this.state.product.manufacturerSKU,
        name: this.state.product.name,
        price: {
          amazon: this.state.product.amazon,
          ebay: this.state.product.ebay,
          ek: this.state.product.ek,
          shopware: this.state.product.shopwar,
          uvp: this.state.product.uvp
        },
        seo: {
          keywords: this.state.product.keywords,
          title: this.state.product.name
        },
        sku: this.state.product.sku,
        stock: {
          own: this.state.product.stock,
          min: this.state.product.minStock,
          fba: this.state.product.stockFBA,
          solectric: this.state.product.stockSolectric
        },
        tax: this.state.product.tax,
        originCountry: this.state.product.originCountry,
        weight: this.state.product.weight
      }
    };
    try {
      await ProductsAPI.createProduct(null, body);
      this.setState({
        product: {},
        activeSection: "general",
        images: {
          additional: []
        },
        editor: {
          showDraft: true,
          showHTML: false
        },
        category: {},
        editorState: null
      });
      this
        .props
        .toggle("editCreateProduct");
    } catch (error) {
      console.log(error)
    }
  }
  updateProduct = async() => {
    const body = {
      product: {
        id: this.state.productId,
        category: this.state.category,
        condition: this.state.product.condition,
        description: {
          html: this.state.editorState && draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
          short: this.state.product.desc_short
        },
        dimensions: (this.state.product.height || this.state.product.length || this.state.product.width) && {
          height: this.state.product.height,
          length: this.state.product.length,
          width: this.state.product.width
        },
        ean: this.state.product.ean,
        images: {
          additional: this.state.images.additional,
          main: this.state.images.main
        },
        includes: this.state.product.includes,
        manufacturer: this.state.product.manufacturer,
        manufacturerSKU: this.state.product.manufacturerSKU,
        name: this.state.product.name,
        price: {
          amazon: this.state.product.amazon,
          ebay: this.state.product.ebay,
          ek: this.state.product.ek,
          shopware: this.state.product.shopwar,
          uvp: this.state.product.uvp
        },
        seo: {
          keywords: this.state.product.keywords,
          title: this.state.product.name
        },
        sku: this.state.product.sku,
        stock: {
          own: this.state.product.stock,
          min: this.state.product.minStock,
          fba: this.state.product.stockFBA,
          solectric: this.state.product.stockSolectric
        },
        marketplaces: {
          ebayRefill: this.state.product.stockEbayRefill
        },
        tax: this.state.product.tax,
        originCountry: this.state.product.originCountry,
        weight: this.state.product.weight
      }
    };
    try {
      await ProductsAPI.updateProduct(null, body);
      this.setState({
        product: {},
        activeSection: "general",
        images: {
          additional: []
        },
        editor: {
          showDraft: true,
          showHTML: false
        },
        category: {},
        editorState: null
      });
      this
        .props
        .toggle("editCreateProduct");
    } catch (error) {
      console.log(error)
    }
  }

  toggleSection = tab => () => {
    if (this.state.activeSection !== tab) {
      this.setState({activeSection: tab});
    }
  }

  toggleModal = (id) => {
    this.setState(state => ({
        modals: {
            ...state.modals,
            [id]: {
                ...!state.modals[id],
                isOpen: !state.modals[id].isOpen
            }
        }
    }));
}

  uploadMainImage = async (img) => {
    try {
      const URL = await ProductsAPI.uploadImage(img, "mainImages");
      this.setState(state => ({
        images: {
          main: URL,
          additional: state.images.additional
        }
      }))
    } catch (e) {
      console.log(e)
    }
  }

  uploadAdditionalImages = async (images) => {
    try {
      const URLs = await Promise.all(images.map(async image => await ProductsAPI.uploadImage(image, "additionalImages")));
      this.setState(state => ({
        images: {
          main: state.images.main,
          additional: state.images.additional.concat(URLs)
        }
      }))
    } catch (e) {
      console.log(e)
    }
  }

  changePositionAddImage =  (from, to) => {
    let images = this.state.images.additional;
    images.splice(to,0,images.splice(from,1)[0]);
    this.setState(state => ({
      images: {
        ...state.images,
        additional: images
      }
    }))
  }

  removeImage = index => {
    let images = this.state.images.additional;
    images.splice(index, 1);
    this.setState(state => ({
      images: {
        ...state.images,
        additional: images
      }
    }))
  }

  changeVal = e => {
    const {id, value, type} = e.target;
    this.setState(state => ({
      product: {
        ...state.product,
        [id]: type === "number" ? parseFloat(value) : value
      }
    }))
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  convertHTMLtoDraft = e => {
    const contentBlock = htmlToDraft(e.target.value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({editorState})
    }
  }

  convertHTMLtoDraftInit = html => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({editorState})
    }
  }

  ChangeHTMLDraft = () => {
    if (this.state.editor.showDraft && !this.state.editor.showHTML) {
      this.setState({
        editor: {
          showDraft: false,
          showHTML: true
        }
      });
    } else if (!this.state.editor.showDraft && this.state.editor.showHTML) {
      this.setState({
        editor: {
          showDraft: true,
          showHTML: false
        }
      });
    }
  }
 
  selectCategory = (id, name) => {
    this.setState(state =>({
      category: {id, name},
      product: {
        ...state.product,
        category: {id, name}
      }
    
    }))
  }
  render = () => {
      const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      };
      const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginLeft: ".5%",
        marginRight: ".5%",
        width: "32%",
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
      };
      const thumbInner = {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
      };
      const img = {
        display: 'block',
        width: 'auto',
        height: '100%',
        marginLeft: "auto",
        marginRight: "auto",
      };
      
      const MainImage = (props) => {
        //const [file, setFile] = useState("");
        const {getRootProps, getInputProps} = useDropzone({
          accept: 'image/*',
          onDrop: acceptedFiles => {
            //   setFile(Object.assign(acceptedFiles[0], {
            //   preview: URL.createObjectURL(acceptedFiles[0])
            // }));
            this.uploadMainImage(acceptedFiles[0]);
          }
        });
      
        return (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <img alt="Main"
              src={this.state.images.main || "//cdn.eu.com/connect/img/image-placeholder.jpg"}
              className="img-thumbnail mx-auto"
              />
              <MDBIcon icon="cloud-upload-alt" className="mt-3" />
               <p>Drag 'n' drop or click to select files</p>
            </div>
          </section>
        );
      }

      const AdditionImages = (props) => {
        const [files, setFiles] = useState([]);
        const {getRootProps, getInputProps} = useDropzone({
          accept: 'image/*',
          onDrop: async (acceptedFiles) => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            })));
            this.uploadAdditionalImages(acceptedFiles);
          }
        });
        
        const thumbs = this.state.images.additional && this.state.images.additional.map((URL, index) => (
          <div style={thumb} key={index}>
            <div style={thumbInner}>
              <img alt="Additional"
                src={URL}
                style={img}
              />
            </div>
            <div style={{position : "absolute", width: "inherit"}}>
            {index > 0 &&
              <MDBIcon className="float-left" far icon="arrow-alt-circle-up" onClick={() => this.changePositionAddImage(index, index - 1)} />
            }
            {index + 1 < this.state.images.additional.length &&
              <MDBIcon className="float-left" far icon="arrow-alt-circle-down" onClick={() => this.changePositionAddImage(index, index + 1)} />
            }
            <MDBIcon className="float-right red-text" style={{marginRight: "35px"}} icon="trash" onClick={() => this.removeImage(index)} />
            </div>
          </div>
        ));
      
        useEffect(() => () => {
          // Make sure to revoke the data uris to avoid memory leaks
          files.forEach(file => URL.revokeObjectURL(file.preview));
        }, [files]);
      
        return (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <MDBIcon icon="cloud-upload-alt" className="mt-3" />
              <p>Drag 'n' drop or click to select files</p>
            </div>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          </section>
        );
      }

      const ChangeDraftHTMLBtn = () => <div className="rdw-option-wrapper" onClick={this.ChangeHTMLDraft}><MDBIcon icon="code" /></div>
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("editCreateProduct")
        }}>Edit Product</MDBModalHeader>
        <MDBModalBody className="px-md-5">
          <MDBNav pills color="teal" className="nav-justified ">
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "general"
                ? "active"
                : ""}
                onClick={this.toggleSection("general")}>
                General
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "media"
                ? "active"
                : ""}
                onClick={this.toggleSection("media")}>
                Media
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "pricing"
                ? "active"
                : ""}
                onClick={this.toggleSection("pricing")}>
                Pricing
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "stockship"
                ? "active"
                : ""}
                onClick={this.toggleSection("stockship")}>
                Stock & Shipping
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>

          <MDBTabContent activeItem={this.state.activeSection}>
            <MDBTabPane tabId="general">
              
            <MDBRow>
            <MDBCol>
              <Input
                label="Product Name"
                id="name"
                value={this.state.product.name}
                onChange={this.changeVal}/>
              <MDBRow>
                <MDBCol>
                  <Input label="SKU" id="sku" value={this.state.product.sku} onChange={this.changeVal}/>
                  <Input label="EAN" id="ean" value={this.state.product.ean} type="number" onChange={this.changeVal}/>
                  <select
                    className="browser-default custom-select md-form"
                    // value=""
                    style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                    id="condition"
                    value={this.state.product.condition}
                    onChange={this.changeVal}>
                    <option value="new">New</option>
                    <option value="used-likeNew">Used Like New</option>
                    <option value="used-veryGood">Used Very Good</option>
                    <option value="used-good">Used Good</option>
                    <option value="used-acceptable">Used Acceptable</option>
                  </select>
                  
                </MDBCol>
                <MDBCol>
                  <Input
                    label="Manufacturer"
                    id="manufacturer"
                    value={this.state.product.manufacturer}
                    onChange={this.changeVal}/>
                  <Input
                    label="Manufacturer SKU"
                    id="manufacturerSKU"
                    value={this.state.product.manufacturerSKU}
                    onChange={this.changeVal}/>
                   <Input label="Origin Country" id="originCountry" value={this.state.product.originCountry} onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              
              <MDBRow>
                <MDBCol onClick={() => this.toggleModal("selectCategory")}>
                <h5 className="mb-md-5">Category</h5>
                <Input
                    label="Category"
                    onClick={() => this.toggleModal("selectCategory")}
                    value={this.state.product.category && this.state.product.category.name}
                    placeholder={this.state.product.category && this.state.product.category.name}
                    disabled
                    />
                </MDBCol>
                <MDBCol size="12" sm="6">
                <h5>Dimensions</h5>
                  <MDBRow>
                  <MDBCol size="12">
                  <Input
                    label="Weight"
                    id="weight"
                    icon="weight"
                    type="number"
                    step="0.01"
                    value={this.state.product.weight}
                    onChange={this.changeVal}/>
                  </MDBCol>
                    <MDBCol>
                      <Input
                        label="Length"
                        id="length"
                        icon="expand"
                        type="number"
                        step="0.01"
                        value={this.state.product.length}
                        onChange={this.changeVal}/>
                    </MDBCol>
                    <MDBCol>
                      <Input
                        label="Width"
                        id="width"
                        icon="expand"
                        type="number"
                        step="0.01"
                        value={this.state.product.width}
                        onChange={this.changeVal}/>
                    </MDBCol>
                    <MDBCol>
                      <Input
                        label="Height"
                        id="height"
                        icon="arrows-alt-v"
                        type="number"
                        step="0.01"
                        value={this.state.product.height}
                        onChange={this.changeVal}/>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <h5>Descriptions</h5>
          <MDBRow>
            <MDBCol md="7">
              <Input
                label="Short Description"
                type="textarea"
                id="desc_short"
                value={this.state.product.desc_short}
                onChange={this.changeVal}/>
            </MDBCol>
            <MDBCol md="5">
            <Input
                label="SEO Keywords"
                id="keywords"
                type="textarea"
                value={this.state.product.keywords}
                onChange={this.changeVal}/>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
             <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName={this.state.editor.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
              toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
              onEditorStateChange={this.onEditorStateChange}/>
               <textarea
                onChange={this.convertHTMLtoDraft}
                className={this.state.editor.showHTML ? "md-textarea form-control w-100" : "md-textarea form-control w-100 d-none"}
                value={this.state.editorState && draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}
              />
               <h5 className="mt-4">Package includes</h5>
              <Input
                id="includes"
                type="textarea"
                value={this.state.product.includes}
                onChange={this.changeVal}/>
            </MDBCol>
          </MDBRow>

            </MDBTabPane>
            <MDBTabPane tabId="media">
              <MDBRow>
                <MDBCol md="4">
                  <h5>Main Image</h5>
                  <MainImage />
                </MDBCol>
                <MDBCol md="8">
                <h5>Additional Images</h5>
                <AdditionImages />
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
            <MDBTabPane tabId="pricing">
            <MDBRow>
            <MDBCol>
             
              <MDBRow>
                <MDBCol>
                <Input
                    label="Purchasing price"
                    id="ek"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.ek}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                  <Input
                    label="UVP"
                    id="uvp"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.uvp}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                <Input
                    label="VAT"
                    id="tax"
                    icon="percentage"
                    type="number"
                    step="1"
                    value={this.state.product.tax || 19}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                 
                </MDBCol>
              </MDBRow>
              <h5>Marketplaces</h5>
              <MDBRow>
                <MDBCol>
                  <Input
                    label="Amazon"
                    id="amazon"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.amazon}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <Input
                    label="eBay"
                    id="ebay"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.ebay}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <Input
                    label="Shopware"
                    id="shopware"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.shopware}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <Input
                    label="Idealo"
                    id="idealo_direkt"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.idealo_direkt}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              {this.state.product && this.state.product.autoPricing &&
              <>
              <h5>Auto Pricing</h5>
              <MDBRow style={{backgroundColor: "#dbdbdb"}}>
              {
                this.state.product.autoPricing.amazon &&
                <MDBCol>
                  <Input
                    label="Amazon"
                    id="amazon"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.autoPricing.amazon.price}
                    disabled
                    />
                </MDBCol>
              }
              {
                this.state.product.autoPricing.ebay &&
                <MDBCol>
                  <Input
                    label="eBay"
                    id="ebay"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.autoPricing.ebay.price}
                    disabled
                    />
                </MDBCol>
              }
              {
                this.state.product.autoPricing.shopware &&
                <MDBCol>
                  <Input
                    label="Shopware"
                    id="shopware"
                    icon="euro-sign"
                    type="number"
                    step="0.01"
                    value={this.state.product.autoPricing.shopware.price}
                    disabled
                    />
                </MDBCol>
              }
              </MDBRow>
              </>
              }
            </MDBCol>
          </MDBRow>
            </MDBTabPane>
            <MDBTabPane tabId="stockship">
            <MDBRow>
            <MDBCol>
             
              <MDBRow>
                <MDBCol>
                <Input
                    label="Stock"
                    id="stock"
                    type="number"
                    value={this.state.product.stock}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                  <Input
                    label="Min Stock"
                    id="minStock"
                    type="number"
                    value={this.state.product.minStock}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <h5>Dropshippers</h5>
              <MDBRow>
                <MDBCol>
                  <Input
                    label="FBA Stock"
                    id="stockFBA"
                    type="number"
                    value={this.state.product.stockFBA}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <Input
                    label="Min FBA Stock"
                    id="minStockFBA"
                    type="number"
                    value={this.state.product.minStockFBA}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <Input
                    label="Solectric Stock"
                    id="stockSolectric"
                    type="number"
                    value={this.state.product.stockSolectric}
                    onChange={this.changeVal}/>
                </MDBCol>
                <MDBCol>
                <Input
                    label="Min Solectric Stock"
                    id="minStockSolectric"
                    type="number"
                    value={this.state.product.minStockSolectric}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
              <h5>eBay</h5>
              <MDBRow>
                <MDBCol md="6">
                  <Input
                    label="Refill-Stock"
                    id="stockEbayRefill"
                    type="number"
                    value={this.state.product.stockEbayRefill}
                    onChange={this.changeVal}/>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

            </MDBTabPane>
          </MDBTabContent>

          

        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong white-text"
            onClick={() => {
            this
              .props
              .toggle("editProduct")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong white-text" onClick={this.execute}>{this.state.type === "create" ? "Create" : this.state.type === "update" ? "update" : ""}</MDBBtn>
        </MDBModalFooter>
        <SelectCategoryModal isOpen={this.state.modals.selectCategory.isOpen} toggle={this.toggleModal} selectCategory={this.selectCategory} />
      </MDBModal>   
    )
  }
}

export default EditCreateProduct;