import React from 'react';
import TagBox from 'devextreme-react/tag-box';
import { categories } from './data.js';

export class EditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onValueChanged = this.onValueChanged.bind(this);
    console.log(props)
  }

  render() {
    return (
      <TagBox
        defaultValue={this.props.data.value}
        items={this.props.data.field.lookup.dataSource}
        onValueChanged={this.onValueChanged}
        width="200px"
        showSelectionControls={true}
        applyValueMode="useButtons"
        searchEnabled={true}
        multiline={true}
      />
    );
  }

  onValueChanged(e) {
    this.props.data.setValue(e.value && e.value.length ? e.value : null);
  }
}
