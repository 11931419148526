import { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  Input,
  MDBIcon
} from 'mdbreact';
import {ProductsAPI} from "../../Api/connect";

class CreateAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      attributes: [
        {
          id: 0,
          value: ""
        }
      ]
    }
  }

  formAttributeFields = () => {
    return this
      .state
      .attributes
      .map(a => (
        <MDBRow>
          <MDBCol size="11">
            <Input
              label="Variant"
              id={a.id}
              value={a.value}
              onChange={this.changeValAttribute}/>
          </MDBCol>
          <MDBCol size="1">
            <MDBIcon
              icon="times"
              className="red-text mt-4"
              onClick={() => this.removeAttribute(a.id)}/>
          </MDBCol>
        </MDBRow>
      ));
  }

  execute = async() => {
    const body = {
      attribute: {
        name: this.state.name,
        variants: this
          .state
          .attributes
          .map(a => a.value)
      }
    };
    try {
      await ProductsAPI.createAttribute(null, body);
      this.setState({
        name: "",
        attributes: [
          {
            id: 0,
            value: ""
          }
        ]
      });
      this
        .props
        .toggle("CreateAttribute");
    } catch (error) {
      console.log(error)
    }
  }

  addAttributes = () => {
    this.setState(state => ({
      attributes: [
        ...state.attributes, {
          id: state.attributes.length,
          value: ""
        }
      ]
    }))
  }

  removeAttribute = id => {
    this.setState(state => ({
      attributes: state
        .attributes
        .filter(a => a.id.toString() !== id.toString())
    }))
  }

  changeVal = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  changeValAttribute = e => {
    const id = e.target.id;
    const val = e.target.value;
    this.setState(state => ({
      attributes: state
        .attributes
        .map(a => {
          if (a.id.toString() === id.toString()) {
            return ({id: a.id, value: val})
          } else {
            console.log("t")
            return a;
          }
        })
    }))
  }

  render = () => {
    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("CreateAttribute")
      }}
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("CreateAttribute")
        }}>Create Attribute</MDBModalHeader>
        <MDBModalBody className="text-center px-md-5">
          <Input
            label="Name"
            id="name"
            value={this.state.name}
            onChange={this.changeVal}/>
          <h5>Attributes</h5>
          {this.formAttributeFields()}
          <MDBBtn color=" teal white-text" onClick={this.addAttributes}>Add more</MDBBtn>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong white-text"
            onClick={() => {
            this
              .props
              .toggle("CreateAttribute")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong white-text" onClick={this.execute}>Create</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

export default CreateAttribute;