import { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  Card,
  CardHeader,
  CardBody,
  Input,
  MDBSelect,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
  MDBDatePicker,
  Table,
  TableHead,
  TableBody
} from 'mdbreact';
import {ProductsAPI, SettingsAPI, PricingAPI} from "../../../Api/connect";

export default class editRule extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState;
  }

defaultState = {
  rule: {
    marketplace: {
      id: ""
    },
    timing: {
      from: new Date().getTime()
    },
    calculation: {},
    limits: {}
  },
  marketplaces: {},
  activeSection: "general",
  products: [],
  searchedProducts: [],
  selectedProducts: [],
  searchQuery: "",
  perPage: 300

}

componentDidMount() {
    this.getMarketplaces();
    this.getProducts();
}

componentWillReceiveProps(props) {
  console.log(props)
  if (props.rule.id) {
    console.log(props.rule)
    this.setState({
      rule: {...props.rule},
      selectedProducts: props.rule.products || []
    })
  }
}

async getMarketplaces(qs) {
    try {
        const marketplaces = await SettingsAPI.getMarketplaces();
        console.log(marketplaces);
        this.setState({marketplaces})
    } catch (error) {
        console.log(error)
    } 
}

  execute = async() => {
    const body = {
      rule: {
        ...this.state.rule,
        products: this.state.selectedProducts.map(p => ({
          id: p.id,
          name: p.name,
          sku: p.sku,
          image: p.images && p.images.main
        }))
      }
    };
    console.log(body.rule);
    try {
      await PricingAPI.updatePricingRule(null, body);
      this.setState(this.defaultState);
      this.props.toggle("editRule");
    } catch (error) {
      console.log(error)
    }
  }

  toggleSection = tab => () => {
    if (this.state.activeSection !== tab) {
      this.setState({activeSection: tab});
    }
  }

  

  changeVal = e => {
    if (e.target.type === "checkbox"){
      const {id, checked} = e.target;
      if(this.state.rule[id] !== checked) {
        this.setState(state => ({
          rule: {
            ...state.rule,
            [id]: checked
          }
        }))
      }
    } else {
      const {id, value} = e.target;
      if(this.state.rule[id] !== value) {
        this.setState(state => ({
          rule: {
            ...state.rule,
            [id]: value
          }
        }))
      }
    }
   
  }

  changeValMarketplaceSettings = e => {
      const {id, value} = e.target;
      if(this.state.rule.marketplace[id] !== value) {
        this.setState(state => ({
          rule: {
            ...state.rule,
            marketplace: {
              ...state.rule.marketplace,
              [id]: parseFloat(value)
            }
          }
        }))
      }
  }

  changeValLimits = e => {
    const {id, value} = e.target;
    if(this.state.rule.limits[id] !== value) {
      this.setState(state => ({
        rule: {
          ...state.rule,
          limits: {
            ...state.rule.limits,
            [id]: value
          }
        }
      }))
    }
}

changeValCalculation = e => {
  const {id, value} = e.target;
  if(this.state.rule.calculation[id] !== value) {
    this.setState(state => ({
      rule: {
        ...state.rule,
        calculation: {
          ...state.rule.calculation,
          [id]: parseFloat(value)
        }
      }
    }))
  }
}

changeValLimits = (e, section) => {
  const {id, value} = e.target;
  if(this.state.rule.limits[section][id] !== value) {
    this.setState(state => ({
      rule: {
        ...state.rule,
        limits: {
          ...state.rule.limits,
          [section]: {
            ...state.rule.limits[section],
            [id]: parseFloat(value)
          }
        }
      }
    }))
  }
}

  changeSelect = (section, id, value) => {
    if (!this.state.rule[section]
        || !this.state.rule[section][id] 
        || this.state.rule[section][id] !== value
        ) {
          this.setState(state => ({
            rule: {
                ...state.rule,
                [section]: {
                    ...state.rule[section],
                    [id]: value
                }
            }
        }))
    } 
}

changeDatePicker= (id, value) => {
      this.setState(state => ({
          rule: {
              ...state.rule,
              timing: {
                  ...state.rule.timing,
                  [id]: value.getTime()
              }
          }
      }))
}

getProducts = async () => {
  if (this.state.products.length < 1) {
      try {
        const products = await ProductsAPI.getProducts({limit: this.state.perPage});
      this.setState({products})
    } catch (error) {
        console.log(error)
    } 
  }
}

renderProducts(products) {
  return products.map(product => {
      const isSelected = this.state.selectedProducts.filter(p => p.id === product.id).length > 0;
      const stock = product.stock && Object.values(product.stock).reduce((a, b) => a + b);
      return ({
          img: <img src={product.images && product.images.main} alt={product.name} height="50" />,
          name: product.name,
          stock: <span style={{color: stock > 0 ? "black": "red"}}>{stock}</span>,
          button: <MDBBtn
          color={isSelected ? " teal white-text" : " grey white-text"}
          size="sm" id={product.id.toString()}
          onClick={() => this.selectProduct(product.id, isSelected)}>Select</MDBBtn>

      })
              });

}

searchProducts = async () => {
  if(this.state.searchQuery.length > 0) {
      try {
          const searchedProducts = await ProductsAPI.searchProducts({q: this.state.searchQuery});
          this.setState(state => ({
              searchedProducts: searchedProducts,
              products: searchedProducts
          }))
      } catch (error) {
          console.log(error)
      } 
  } else {
      this.setState({searchedProducts: ""})
  }
}


selectProduct = (id, selected) => {
  console.log(selected)
  if (selected) {
      this.setState(state => ({
          selectedProducts: [...state.selectedProducts.filter(p => p.id !== id)]
      }))
  } else if (this.state.selectedProducts.filter(p => p.id === id).length < 1){
      this.setState(state => ({
          selectedProducts: [
              ...state.selectedProducts,
              this.state.products.filter(p => p.id === id)[0]
          ]
      }))
  }
}

changeSearchQuery = e => {
  this.setState({
      searchQuery: e.target.value
  })
}


 
  render = () => {
    const data_checkboxes = {
      columns: [
        {
          'label': 'Image',
          'field': 'img',
          'sort': 'asc'
        },
        {
          'label': 'Name',
          'field': 'name',
          'sort': 'asc'
        },
        {
          'label': 'Stock',
          'field': 'stock',
          'sort': 'asc'
        },
        {
          'label': 'Select',
          'field': 'button',
          'sort': 'asc'
        }
      ]
    };
    const selectMarketplace = this.state.marketplaces && this.state.marketplaces.active && this.state.marketplaces.active.map(m => ({
      text: m[0].toUpperCase() + m.slice(1),
      value: m,
      checked: this.state.rule.marketplace.id === m ? true : false
    }));
    const selectDates = {
      from: new Date(this.state.rule.timing.from),
      to: this.state.rule.timing.to && new Date(this.state.rule.timing.to)
    };

    return (
      <MDBModal
        isOpen={this.props.isOpen}
        toggle={() => {
        this
          .props
          .toggle("editRule")
      }}
        centered
        size="xl">
        <MDBModalHeader
          toggle={() => {
          this
            .props
            .toggle("editRule")
        }}>Edit Pricing Rule</MDBModalHeader>
        <MDBModalBody className="px-md-5">
          <MDBNav pills color="teal" className="nav-justified ">
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "general"
                ? "active"
                : ""}
                onClick={this.toggleSection("general")}>
                Settings
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                to="#"
                className={this.state.activeSection === "products"
                ? "active"
                : ""}
                onClick={this.toggleSection("products")}>
                Products
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>

          <MDBTabContent activeItem={this.state.activeSection}>
            <MDBTabPane tabId="general">
              
            <MDBRow>
            <MDBCol>
              <Input
                label="Rule Name"
                id="name"
                value={this.state.rule.name}
                onChange={this.changeVal}/>
              <MDBRow>
                <MDBCol md="3">
                <MDBSelect
                  options={selectMarketplace}
                  label="Marketplace"
                  getValue={value => value[0] && this.changeSelect("marketplace", "id", value[0])}
                />
                </MDBCol>
                <MDBCol md="4">
                  <MDBRow>
                    <MDBCol className="pt-3 text-center">
                    From Date:
                    </MDBCol>
                    <MDBCol>
                    <MDBDatePicker
                    valueDefault={selectDates.from}
                    getValue={value => this.changeDatePicker("from", value)} />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="5">
                  <MDBRow>
                    <MDBCol className="pt-4 text-center toDateBox">
                    <Input label="To Date:" type="checkbox" id="hasToDate" checked={this.state.rule.hasToDate} onChange={this.changeVal} />
                    </MDBCol>
                    <MDBCol>
                    <MDBDatePicker
                    valueDefault={selectDates.to}
                    disabled={!this.state.rule.hasToDate}
                    getValue={value => this.changeDatePicker("to", value)} />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="9" xl="8" className="mx-auto my-4 text-center">
                  <h6 className="mt-3">Marketplace Settings</h6>
                  <MDBRow className="text-left">
                    <MDBCol>
                      <Input type="number" label="% Fees" icon="percentage" id="percentFee" value={this.state.rule.marketplace.percentFee} onChange={this.changeValMarketplaceSettings} />
                    </MDBCol>
                    <MDBCol>
                      <Input type="number" label="Max % Fees" icon="euro-sign" id="percentFeeMax" value={this.state.rule.marketplace.percentFeeMax} onChange={this.changeValMarketplaceSettings} />
                    </MDBCol>
                    <MDBCol>
                      <Input type="number" label="Fixed Fees" icon="euro-sign" id="fixedFee" value={this.state.rule.marketplace.fixedFee} onChange={this.changeValMarketplaceSettings} />
                    </MDBCol>
                    <MDBCol>
                      <Input type="number" label="Tax Rate" icon="percentage" id="tax" value={this.state.rule.marketplace.tax} onChange={this.changeValMarketplaceSettings} />
                    </MDBCol>
                    <MDBCol className="pt-3">
                    <Input label="PayPal" type="checkbox" id="isPaypal" checked={this.state.rule.isPaypal} onChange={this.changeVal} />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-4">
                <MDBCol className="text-center">
                <h5>Calculation</h5>
                <MDBSelect
                  options={[
                    {
                      text: "None",
                      value:"none",
                      checked: this.state.rule.calculation.method === "none" ? true : false
                    },
                    {
                      text: "EK Addition",
                      value:"ekAdded",
                      checked: this.state.rule.calculation.method === "ekAdded" ? true : false
                    },
                    {
                      text: "UVP Substraction",
                      value:"uvpSub",
                      checked: this.state.rule.calculation.method === "uvpSub" ? true : false
                    }
                  ]}
                  label="Method"
                  getValue={value => value[0] && this.changeSelect("calculation", "method", value[0])}
                />
                {this.state.rule.calculation.method === "ekAdded" &&
                <>
                <MDBRow className="text-left">
                  <MDBCol>
                    <Input type="number" label="% added" icon="percentage" id="ekAddedPercent" value={this.state.rule.calculation.ekAddedPercent} onChange={this.changeValCalculation} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Max % added" icon="euro-sign" id="ekAddedPercentMax" value={this.state.rule.calculation.ekAddedPercentMax} onChange={this.changeValCalculation} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Fixed added" icon="euro-sign" id="ekAddedFix" value={this.state.rule.calculation.ekAddedFix} onChange={this.changeValCalculation} />
                  </MDBCol>
                </MDBRow>
                </>
                }
                {this.state.rule.calculation.method === "uvpSub" &&
                <>
                <MDBRow className="text-left mb-4">
                  <MDBCol>
                    <Input type="number" label="% substracted" icon="percentage" id="uvpSubstractedPercent" value={this.state.rule.calculation.uvpSubstractedPercent} onChange={this.changeValCalculation} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Max % substracted" icon="euro-sign" id="uvpSubstractedPercentMax" value={this.state.rule.calculation.uvpSubstractedPercentMax} onChange={this.changeValCalculation} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Fixed substracted" icon="euro-sign" id="uvpSubstractedFix" value={this.state.rule.calculation.uvpSubstractedFix} onChange={this.changeValCalculation} />
                  </MDBCol>
                </MDBRow>
                </>
                }
                </MDBCol>
                <MDBCol className="text-center">
                <h5>Limits</h5>
                <h6 className="mt-4">Minimum Price</h6>
                <MDBSelect
                  options={[
                    {
                      text: "EK",
                      value:"ek",
                      checked: this.state.rule.limits.minPriceMethod === "ek" ? true : false
                    },
                    {
                      text: "EK added",
                      value:"ekAdded",
                      checked: this.state.rule.limits.minPriceMethod === "ekAdded" ? true : false
                    }
                  ]}
                  label="Method"
                  getValue={value => value[0] && this.changeSelect("limits", "minPriceMethod", value[0])}
                />
                {this.state.rule.limits.minPriceMethod === "ekAdded" &&
                <>
                <MDBRow className="text-left mb-4">
                  <MDBCol>
                    <Input type="number" label="% added" icon="percentage" id="addedPercent" value={this.state.rule.limits.minPrice && this.state.rule.limits.minPrice.addedPercent} onChange={e => this.changeValLimits(e, "minPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Max % added" icon="euro-sign" id="addedPercentMax" value={this.state.rule.limits.minPrice && this.state.rule.limits.minPrice.addedPercentMax} onChange={e => this.changeValLimits(e, "minPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Fixed added" icon="euro-sign" id="addedFix" value={this.state.rule.limits.minPrice && this.state.rule.limits.minPrice.addedFix} onChange={e => this.changeValLimits(e, "minPrice")} />
                  </MDBCol>
                </MDBRow>
                </>
                }
                <h6>Maximum Price</h6>
                <MDBSelect
                  options={[
                    {
                      text: "UVP",
                      value:"UVP",
                      checked: this.state.rule.limits.maxPriceMethod === "UVP" ? true : false
                    },
                    {
                      text: "EK added",
                      value:"ekAdded",
                      checked: this.state.rule.limits.maxPriceMethod === "ekAdded" ? true : false
                    },
                    {
                      text: "UVP substracted",
                      value:"uvpSub",
                      checked: this.state.rule.limits.maxPriceMethod === "uvpSub" ? true : false
                    }
                  ]}
                  label="Method"
                  getValue={value => value[0] && this.changeSelect("limits", "maxPriceMethod", value[0])}
                />
                {this.state.rule.limits.maxPriceMethod === "ekAdded" &&
                <>
                <MDBRow className="text-left mb-4">
                  <MDBCol>
                    <Input type="number" label="% added" icon="percentage" id="addedPercent" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.addedPercent} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Max % added" icon="euro-sign" id="addedPercentMax" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.addedPercentMax} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Fixed added" icon="euro-sign" id="addedFix" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.addedFix} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                </MDBRow>
                </>
                }
                {this.state.rule.limits.maxPriceMethod === "uvpSub" &&
                <>
                <MDBRow className="text-left mb-4">
                  <MDBCol>
                    <Input type="number" label="% substracted" icon="percentage" id="substractedPercent" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.substractedPercent} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Max % substracted" icon="euro-sign" id="substractedPercentMax" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.substractedPercentMax} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                  <MDBCol>
                    <Input type="number" label="Fixed substracted" icon="euro-sign" id="substractedFix" value={this.state.rule.limits.maxPrice && this.state.rule.limits.maxPrice.substractedFix} onChange={e => this.changeValLimits(e, "maxPrice")} />
                  </MDBCol>
                </MDBRow>
                </>
                }
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

            </MDBTabPane>
            <MDBTabPane tabId="products">
              <MDBRow>
              <MDBCol size="6">
                <Card className="w-100">
                        <CardHeader color="stylish-color">Selected Products</CardHeader>
                        <CardBody className="p-0">
                            <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.selectedProducts.length > 0 && this.renderProducts(this.state.selectedProducts)} />
                            </Table>
                        </CardBody>
                    </Card>
                </MDBCol>
              <MDBCol size="6">
                <Card className="w-100">
                        <CardHeader color="stylish-color">All Products</CardHeader>
                        <CardBody className="p-0">
                        <MDBRow>
                            <MDBCol size="9">
                            <div className="md-form my-1 ml-md-4">
                            <input type="text" className="form-control" value={this.state.searchQuery} onChange={this.changeSearchQuery} />
                            </div>
                            </MDBCol>
                            <MDBCol size="3">
                                <MDBBtn size="sm" color=" teal white-text mt-md-2" onClick={this.searchProducts}>Search</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                            <Table responsive className="text-center">
                                <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                                <TableBody rows={this.state.searchedProducts.length > 0 ? this.renderProducts(this.state.searchedProducts) : this.state.products && this.renderProducts(this.state.products)} />
                            </Table>
                        </CardBody>
                    </Card>
            </MDBCol>
              </MDBRow>
            </MDBTabPane>
        </MDBTabContent>

          

        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color=" rgba-red-strong white-text"
            onClick={() => {
            this
              .props
              .toggle("editRule")
          }}>Cancel</MDBBtn>
          <MDBBtn color=" rgba-stylish-strong white-text" onClick={this.execute}>Update</MDBBtn>
        </MDBModalFooter>
      </MDBModal>   
    )
  }
}