import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    MDBIcon,
    Table,
    TableBody,
    TableHead,
 } from "mdbreact";
import {MultichannelAPI} from "../../Api/connect";
import Images from "../../components/images";


class ActiveListings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listings: [],
            selectedListing: "",
            modals: {
            }
        }
    }

    async componentDidMount() {
        this.getListings();
       
    }

    async getListings() {
        try {
            const listings = await MultichannelAPI.getListings();
            this.setState({listings})
        } catch (error) {
            console.log(error)
        } 
    }

    selectListing = e => {
        const id = e.target.id;
        this.setState(state => ({
            selectedListings: [
                ...state.selectedListings,
                id
            ],
            selectedListing: id
        }))
    }
    
    renderListings(listings) {
        return listings.map(listing => ({
                check: <div className="form-check my-1">
                    <input type="checkbox" className="form-control form-check-input" id={listing.offerId} onChange={this.selectListing} />
                    <label className="form-check-label mr-5" for={listing.offerId} id={listing.offerId}> </label>
                </div>,
                image: <img src={listing.productImage} alt={listing.productName} height="50" />,
                name: listing.productName,
                marketplace: Images[listing.marketplace],
                published: new Date(listing.lastPublish).toISOString().substring(0, 16).replace("T", " "),
                link: <a target="_blank" rel="noopener noreferrer" href={listing.marketplaceOfferLink}><MDBIcon icon="external-link-alt" /></a>
        }));
    }

    toggleModal = (id) => {
        if (this.state.modals[id].isOpen) {
            setTimeout(() => {
                this.getProducts();
            }, 500)
        }
        this.setState(state => ({
            modals: {
                ...state.modals,
                [id]: {
                    ...!state.modals[id],
                    isOpen: !state.modals[id].isOpen
                }
            }
        }));
    }

    render() {

        const data_checkboxes = {
            columns: [
              {
                'label': '#',
                'field': 'check'
              },
              {
                'label': 'Image',
                'field': 'image',
                'sort': 'asc'
              },
              {
                'label': 'Name',
                'field': 'name',
                'sort': 'asc'
              },
              {
                'label': 'Marketplace',
                'field': 'marketplace',
                'sort': 'asc'
              },
              {
                'label': 'Published',
                'field': 'published',
                'sort': 'asc'
              },
              {
                'label': 'Link',
                'field': 'link',
                'sort': 'asc'
              }
            ]
          };

        return (
            <section>
         <Row>
             <Col md="8">
             <Card>
                <CardHeader color="stylish-color">Actions</CardHeader>
                <CardBody>
                <Row>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("createProduct")}>
                                Create <MDBIcon icon="plus" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Delete <MDBIcon icon="remove" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center" onClick={() => this.toggleModal("editProduct")}>
                                Edit <MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Copy<MDBIcon icon="copy" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col>
                        <ListGroup style={{cursor: "pointer"}}>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Edit with blueprint <MDBIcon icon="edit" />
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Edit history <MDBIcon icon="history" />
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
                </CardBody>
            </Card>
             </Col>
             <Col md="4">
                <Card>
                    <CardHeader color="stylish-color">Stock</CardHeader>
                    <CardBody>
                    <ListGroup>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Current stock: 
                            </ListGroupItem>
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                                Change stock: <MDBIcon icon="refresh" />
                            </ListGroupItem>
                        </ListGroup>
                    </CardBody>
                </Card>
             </Col>
         </Row>
                <Row className="py-3">
               <Col md="12">
                 <Card>
                   <CardBody>
                    <Table responsive className="text-center">
                        <TableHead  color="teal" textWhite columns={data_checkboxes.columns} />
                        <TableBody rows={this.state.listings && this.renderListings(this.state.listings)} />
                    </Table>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
              </section>
         );
    }
  
};

export default ActiveListings;