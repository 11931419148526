import React, { Fragment, useState, useEffect } from 'react'
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBIcon,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage
 } from "mdbreact";
 import { useSelector, useDispatch } from 'react-redux'
 import {API} from "aws-amplify";
import { addActiveShippingProvider } from "../../../features/shippingProviders";

export default function New_ShippingProvider(props) {
    const dispatch = useDispatch()
    // Get available ShippingProviders
    const [availableShippingProviders, setAvailableShippingProviders] = useState([])

    const getAvailableShippingProviders = async () => {
        try {
            const res = await API.get("ConnectApi", "system/availableproviders", {queryStringParameters: {type: "shippingProviders"}})
            setAvailableShippingProviders(res.available)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if(availableShippingProviders.length === 0) {
            getAvailableShippingProviders()
        }
    }, [])


    const activateShippingProvider = async (shippingProvider) => {
        
        try {
            //await API.post("ConnectApi", "settings/shippingproviders/activate", {body: {shippingProvider}})
            dispatch(addActiveShippingProvider(shippingProvider))
        } catch (error) {
            console.log(error)
        }
        
    }

  return (
    <Fragment>
        <h4 className="mt-5 mx-4">Shipping Providers</h4>
         <MDBRow className="p-2 p-md-4">
            {availableShippingProviders.length > 0 && availableShippingProviders.map(shippingProvider => {
                const isActive = props.activeShippingProviders?.findIndex(sp => sp.handle === shippingProvider.handle) >= 0

                return <MDBCol size="6" md="4" className="mb-3">
                <MDBCard
                className={isActive ? "rgba-green-slight border border-success" : ""}
                onClick={() => {
                    if(!isActive) {
                        activateShippingProvider(shippingProvider)
                    } else {
                        console.log("toggle");
                        const toggle = props.toggleShippingProvider(shippingProvider.handle)
                        toggle()
                    }
                }}
                >
                    <MDBCardImage  className="mx-auto pt-3" style={{height: "50px"}}src={shippingProvider.logoUrl} />
                    <MDBCardBody className="mt-2 p-2">
                        <MDBCardText className="text-center">
                        <MDBBtn outline size="sm">{isActive ? "Configure" : "Activate"}</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            })}
        </MDBRow>
    </Fragment>
  )
}
