import React, { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    Input,
    MDBIcon,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBTabPane,
    MDBTabContent,
    MDBInput
} from 'mdbreact';
import {useDropzone} from 'react-dropzone';
import {ProductsAPI} from "../../../Api/connect";

const Image = React.memo(function Image({ src, alt, className, style }) { // TODO: [CHA-86] Fix rerender image on every state change
    return <img src={src} alt={alt} className={className} style={style} />;
  });

export default function EditProduct_Media(props) {
    const { product, handleChange, isActive } = props;

    const uploadMainImage = async (img) => {
        try {
          const URL = await ProductsAPI.uploadImage(img, "mainImages");
          handleChange("images.main", URL)
        } catch (e) {
          console.log("Error uploading image", e)
        }
      }
    
      const uploadAdditionalImages = async (images) => {
        try {
          const URLs = await Promise.all(images.map(async image => await ProductsAPI.uploadImage(image, "additionalImages")));
          handleChange("images.additional", product.images.additional ? product.images.additional.concat(URLs) : URLs)
        } catch (e) {
            console.log("Error uploading additional image", e)
        }
      }
    
      const changePositionAddImage =  (from, to) => {
        let images = product.images.additional;
        images.splice(to,0,images.splice(from,1)[0]);
        handleChange("images.additional", images)
      }
    
      const removeImage = index => {  // TODO: [CHA-84] Remove image from S3 when deleting product in frontend
        let images = product.images.additional;
        images.splice(index, 1);
        handleChange("images.additional", images)
      }

      const MainImage = (props) => {
        const {getRootProps, getInputProps} = useDropzone({
          accept: 'image/*',
          onDrop: acceptedFiles => {
            uploadMainImage(acceptedFiles[0]);
          }
        });
      
        return (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Image alt={product.images?.mainAlt}
              src={product.images?.main || "//cdn.eu.com/connect/img/image-placeholder.jpg"}
              className="img-thumbnail mx-auto" />
              <MDBIcon icon="cloud-upload-alt" className="mt-3" />
               <p>Drag 'n' drop or click to select files</p>
            </div>
            <MDBInput label="Alternative Text" value={product.images?.mainAlt} onChange={e => handleChange("images.mainAlt", e.target.value)} />
          </section>
        );
      }

      const AdditionImages = (props) => {
        const [files, setFiles] = useState([]);
        const {getRootProps, getInputProps} = useDropzone({
          accept: 'image/*',
          onDrop: async (acceptedFiles) => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            })));
            uploadAdditionalImages(acceptedFiles);
          }
        });
        
        const thumbs = product.images?.additional && product.images.additional.map((AddImage, index) => (
             <div style={thumb} key={index}>
            <div style={thumbInner}>
            <Image src={AddImage.src} alt={AddImage.alt} style={img} />

              <MDBInput label="Alternative Text" containerClass="my-0 mx-auto" size="sm" />
            </div>
            <div style={{position : "absolute", width: "inherit"}}>
            {index > 0 &&
              <MDBIcon className="float-left" far icon="arrow-alt-circle-up" onClick={() => changePositionAddImage(index, index - 1)} />
            }
            {index + 1 < product.images.additional.length &&
              <MDBIcon className="float-left" far icon="arrow-alt-circle-down" onClick={() => changePositionAddImage(index, index + 1)} />
            }
            <MDBIcon className="float-right red-text" style={{marginRight: "35px"}} icon="trash" onClick={() => removeImage(index)} />
            </div>
            
          </div>
         
        ));
      
        useEffect(() => () => {
          // Make sure to revoke the data uris to avoid memory leaks
          files.forEach(file => URL.revokeObjectURL(file.preview));
        }, [files]);
      
        return (
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <MDBIcon icon="cloud-upload-alt" className="mt-3" />
              <p>Drag 'n' drop or click to select files</p>
            </div>
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          </section>
        );
      }

      const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      };
      const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginLeft: ".5%",
        marginRight: ".5%",
        width: "32%",
        height: 150,
        padding: 4,
        boxSizing: 'border-box'
      };
      const thumbInner = {
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center"
      };
      const img = {
        display: 'block',
        width: 'auto',
        height: '100px',
        marginLeft: "auto",
        marginRight: "auto",
      };

    return isActive ?
            <MDBRow>
                <MDBCol md="4">
                    <h5>Main Image</h5>
                    <MainImage/>
                </MDBCol>
                <MDBCol md="8">
                    <h5>Additional Images</h5>
                    <AdditionImages/>
                </MDBCol>
            </MDBRow>
            : "Inactive"
}
