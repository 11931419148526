import { Component } from "react";
import { 
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Table,
    TableBody,
    TableHead,
    MDBRow,
    MDBCol,
    MDBBadge
 } from "mdbreact";
 import {SupportAPI, SettingsAPI} from "../../Api/connect";

 import TicketModal from "../../components/support/Ticket";

 const data_checkboxes = {
    columns: [
      {
        'label': '#',
        'field': 'ID',
        'sort': 'asc'
      },
      {
        'label': 'Subject',
        'field': 'subject',
        'sort': 'asc'
      },
      {
        'label': 'Requestor',
        'field': 'requestor',
        'sort': 'asc'
      },
      {
        'label': 'Time',
        'field': 'time',
        'sort': 'asc'
      },
      {
        'label': 'Channel',
        'field': 'channel',
        'sort': 'asc'
      }
    ],
    rows: [
      {
        "ID": "#1",
        "subject": "Meine Bestellung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#2",
        "subject": "Rechnungsanforderung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#3",
        "subject": "Rücksendung Bestellung XXX",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#1",
        "subject": "Meine Bestellung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#2",
        "subject": "Rechnungsanforderung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#3",
        "subject": "Rücksendung Bestellung XXX",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#1",
        "subject": "Meine Bestellung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#2",
        "subject": "Rechnungsanforderung",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      },
      {
        "ID": "#3",
        "subject": "Rücksendung Bestellung XXX",
        "requestor": "Marvin Baum",
        "time": "2019-04-16 15:43",
        "channel": "Amazon"
      }
    ]
  };


class Tickets extends Component {
  constructor(props) {
      super(props);
      this.state = {
          tickets: [],
          ticket: {},
          settings: {},
          filters: {
            limit: 20
          },
          ticketModal: false
      }

  }

  async componentDidMount() {
      try {
          const tickets = await this.getTickets(this.state.filters);
          this.setState({tickets})
          const settings = await SettingsAPI.getSettings();
          this.setState({settings})
          console.log(tickets);
      } catch (error) {
          console.log(error)
      }
     
  }
  getTickets = async (qs) => {
      return await SupportAPI.getTickets(qs);
  }

  editTicket = (id) => {
    this.setState(state => ({
        ticketModal: true,
        ticket: state.tickets.filter(t => t.id === id)[0]
    }))
  }

  toggleModal = () => {
    this.setState({ticketModal: false})
  }


  renderTickets = (tickets) => {
    if (!tickets) {
        return
    }
    
    return tickets.map((ticket) => {
      const t = new Date(ticket.timestamp*1000);
      return ({
        id: "#"+ticket.id,
        subject: <span onClick={() => {this.editTicket(ticket.id)}}>{ticket.subject.substring(0, 50)}</span>,
        requestor: ticket.requestor && ticket.requestor.substring(0, 20),
        time: t.toISOString().substring(0, 16).replace("T", " "),
        channel: ticket.channel
      })
           
    })
};
  render() {
    return (
      <section>
         <MDBRow>
             <MDBCol md="3" className="h-100">
                 <Card className="h-100">
                     <CardHeader color="stylish-color">Overview</CardHeader>
                     <CardBody>
                     <Row>
                         <Col>
                             <ListGroup>
                                 <ListGroupItem className="d-flex justify-content-between align-items-center">
                                     Open Tickets <MDBBadge pill color="primary">7</MDBBadge>
                                 </ListGroupItem>
                                 <ListGroupItem className="d-flex justify-content-between align-items-center">
                                     Waiting Tickets <MDBBadge pill color="warning">7</MDBBadge>
                                 </ListGroupItem>
                                 <ListGroupItem className="d-flex justify-content-between align-items-center">
                                     Solved Tickets <MDBBadge pill color="success">7</MDBBadge>
                                 </ListGroupItem>
                                 <ListGroupItem className="d-flex justify-content-between align-items-center">
                                     Spam <MDBBadge pill color="danger">7</MDBBadge>
                                 </ListGroupItem>
                                 <ListGroupItem className="d-flex justify-content-between align-items-center">
                                     Deleted Tickets <MDBBadge pill color="dark">7</MDBBadge>
                                 </ListGroupItem>
                                
                             </ListGroup>
                         </Col>
                        
                     </Row>
                     </CardBody>
                 </Card>
             </MDBCol>
             <MDBCol md="9">
             <Card>
                     <CardHeader color="stylish-color">Tickets</CardHeader>
                     <CardBody>
                     <Table responsive className="text-center">
                     <TableHead  color="white" columns={data_checkboxes.columns} />
                     <TableBody rows={this.state.tickets && this.renderTickets(this.state.tickets)} />
                         </Table>
                     </CardBody>
                 </Card>
             </MDBCol>
         </MDBRow>
         <TicketModal settings={this.state.settings} isOpen={this.state.ticketModal} ticket={this.state.ticket} toggle={this.toggleModal} />
      </section>
   );
  }
 
};

export default Tickets;