import {Auth, API, Storage} from "aws-amplify";
import {v4 as uuid} from "uuid";

import oauthAPI from "./oauth";
import {apiName, mediaPath, paths} from "./config";
import ProductMethods from "./products";


// Authentification

const auth = async () => {
    const s = await Auth.currentSession();
    console.log("Authentification: ", s)
    return {
        username: s.idToken.payload["cognito:username"],
        token: s.idToken.jwtToken
    };
    
}

// Products API

    // Products

    // const getProducts = async (qs) => {
    //     
    //     return API.get(apiName, paths.products.products.all, {queryStringParameters: qs})
    // }

    const importProducts = async (qs, body) => {
        
        return API.post(apiName, paths.products.products.import, {queryStringParameters: qs, body})
    }

    const searchProducts = async (body) => {
        return API.post(apiName, paths.products.products.search, {body})
    }

    const createProduct = async (qs, body) => {
        
        return API.post(apiName, paths.products.products.all, {queryStringParameters: qs, body})
    }

    const updateProduct = async (qs, body) => {
        
        return API.post(apiName, paths.products.products.update, {queryStringParameters: qs, body})
    }

    const uploadImage = async (img, level, name) => {
            const username = (await Auth.currentSession()).idToken.payload["cognito:username"];
            const filename = name ? name : img.type === "image/png" ? uuid() + ".png" : img.type === "image/jpeg" && uuid() + ".jpg"
            return await Storage.put(username + "/" + filename, img, {contentType: img.type})
            .then(r => {
                const imageURL = mediaPath + username + "/" + filename;
                return imageURL;
            })
    }


    // Categories

    const getCategories = async (qs) => {
        
        return API.get(apiName, paths.products.categories.all, {queryStringParameters: qs})
    }

    const createCategory = async (qs, body) => {
        
        return API.post(apiName, paths.products.categories.all, {queryStringParameters: qs, body})
    }

    const updateCategory = async (qs, body) => {
        
        return API.post(apiName, paths.products.categories.update, {queryStringParameters: qs, body})
    }

    // Attributes

    const getAttributes = async (qs) => {
        
        return API.get(apiName, paths.products.attributes.all, {queryStringParameters: qs})
    }

    const createAttribute = async (qs, body) => {
        
        return API.post(apiName, paths.products.attributes.all, {queryStringParameters: qs, body})
    }

// Pricing API

    const getPricingRules = async (qs) => {
        
        return API.get(apiName, paths.pricing.rules.all, {queryStringParameters: qs})
    }

    const createPricingRule = async (qs, body) => {
        
        return API.post(apiName, paths.pricing.rules.all, {queryStringParameters: qs, body})
    }

    const updatePricingRule = async (qs, body) => {
        
        return API.post(apiName, paths.pricing.rules.update, {queryStringParameters: qs, body})
    }

    const deletePricingRule = async (qs, body) => {
        
        return API.post(apiName, paths.pricing.rules.delete, {queryStringParameters: qs, body})
    }


// Multichannel API
const getMarketplaceInfos = async (body) => {
    
    return API.post(apiName, paths.multichannel.marketplaceinfos, {body})
}

const publishProducts = async (body) => {
    
    return API.post(apiName, paths.multichannel.publish, {body})
}

const getListings = async (qs) => {
    
    return API.get(apiName, paths.multichannel.listings, {queryStringParameters: qs})
}


// Orders API

const getOrders = async (qs) => {
    
    return API.get(apiName, paths.orders.getAll, {queryStringParameters: qs})
}

const search = async (body) => {
    
    return API.post(apiName, paths.orders.search, {body})
}

const updateOrderStatus = async (qs, body) => {
    
    return API.post(apiName, paths.orders.update, {queryStringParameters: qs, body})
}

const capturePayment = async (qs, body) => {
    
    return API.post(apiName, paths.orders.payment.capture, {queryStringParameters: qs, body})
}

const orderInstructShipping = async (qs, body) => {
    
    return API.post(apiName, paths.orders.instructShipping, {queryStringParameters: qs, body})
}

const exportOrders = async (qs, body) => {
    
    return API.post(apiName, paths.orders.export, {queryStringParameters: qs, body})
}

const ImportTracking = async (qs, body) => {
    
    return API.post(apiName, paths.orders.importTracking, {queryStringParameters: qs, body})
}

const createInvoice = async (qs, body) => {
    
    return API.post(apiName, paths.orders.invoice.create, {queryStringParameters: qs, body})
}

const sendInvoiceEmail = async (qs, body) => {
    
    return API.post(apiName, paths.orders.invoice.sendEmail, {queryStringParameters: qs, body})
}

const cancelOrders = async (qs, body) => {
    
    return API.post(apiName, paths.orders.cancel, {queryStringParameters: qs, body})
}

const printSlip = async (qs, body) => {
    
    return API.post(apiName, paths.orders.print.slip, {queryStringParameters: qs, body})
}

const createLabels = async (body) => {
    
    return API.post(apiName, paths.orders.label.create, { body})
}

const openDocument = async (body) => {
    
    return API.post(apiName, paths.orders.documents.open, { body})
}

const validateAddress = async (body) => {
    
    return API.post(apiName, paths.ordervalidation.address, { body})
}

// Support API

const getTickets = async (qs) => {
    
    return API.get(apiName, paths.support.tickets.all, {queryStringParameters: qs})
}


// Financial API

const getBills = async (qs) => {
    
    return API.get(apiName, paths.financial.bills.all, {queryStringParameters: qs})
}


const createBill = async (qs, body) => {
    
    return API.post(apiName, paths.financial.bills.single, {queryStringParameters: qs, body})
}

const updateBillStatus = async (qs, body) => {
    
    return API.post(apiName, paths.financial.bills.status, {queryStringParameters: qs, body})
}

// Settings API

const getSettings = async (qs) => {
    
    return API.get(apiName, paths.settings.getMarketplaces, {queryStringParameters: qs})
}

const getMarketplaces = async (qs) => {
    
    return API.get(apiName, paths.settings.getAccountMarketplaces, {queryStringParameters: qs})
}

const updateSettings = async (qs, body) => {
    
    return API.post(apiName, paths.settings.update, {queryStringParameters: qs, body})
}

const getSettingsAccounting = async (qs) => {
    
    return API.get(apiName, paths.settings.accounting, {queryStringParameters: qs})
}

const updateSettingsAccounting = async (qs, body) => {
    
    return API.post(apiName, paths.settings.accounting, {queryStringParameters: qs, body})
}

    // Partners

    const getPartners = async (qs) => {
        
        return API.get(apiName, paths.settings.partners.all, {queryStringParameters: qs})
    }

    const getPartner = async (qs) => {
        
        return API.get(apiName, paths.settings.partners.single, {queryStringParameters: qs})
    }

    const updatePartner = async (qs, body) => {
        
        return API.post(apiName, paths.settings.partners.single, {queryStringParameters: qs, body})
    }



// Exports

export const OAuth = oauthAPI;

export const ProductsAPI = {
    getProducts: ProductMethods.getProducts,
    searchProducts,
    createProduct,
    updateProduct,
    getCategories,
    createCategory,
    updateCategory,
    getAttributes,
    createAttribute,
    uploadImage,
    importProducts
}

export const PricingAPI = {
    getPricingRules,
    createPricingRule,
    updatePricingRule,
    deletePricingRule
}

export const OrdersAPI = {
    getAll: getOrders,
    instructShipping: orderInstructShipping,
    updateOrderStatus,
    exportOrders,
    ImportTracking,
    cancelOrders,
    createInvoice,
    printSlip,
    search,
    sendInvoiceEmail,
    capturePayment,
    createLabels,
    openDocument,
    validateAddress
}

export const MultichannelAPI = {
    getMarketplaceInfos,
    publishProducts,
    getListings
}

export const SupportAPI = {
    getTickets
}

export const FinancialAPI = {
    createBill,
    updateBillStatus,
    getBills
}

export const SettingsAPI = {
    getSettings,
    updateSettings,
    getPartners,
    getPartner,
    getMarketplaces,
    updatePartner,
    getSettingsAccounting,
    updateSettingsAccounting
}