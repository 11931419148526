import { Component } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBCard, MDBCardImage, MDBModalFooter, MDBRow, MDBCol, Table, TableHead, TableBody } from 'mdbreact';
import {OrdersAPI} from "../../Api/connect";
import { API } from 'aws-amplify';
import { connect } from "react-redux";


class instrShipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fulfillers: {},
            selectedFulfiller: ""
        }
    }

    componentDidMount = () => {
        this.getFulfillers();
    }

    getFulfillers = async () => {
        this.setState({
            fulfillers: await API.get("ConnectApi", "settings/fulfillers")
        })
    }
   
    renderSelect = (fulfillers) => {
        const options = Object.entries(fulfillers).map(f => (
            <option key={f[0]} value={f[0]}>{f[1].name}</option>
        ))

        return (
            <select
            className="browser-default custom-select"
            // value=""
            style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
            onChange={this.changeFulfiller}>
            <option>Choose Fulfiller</option>
            {options}
             </select>
        )
    }
    renderOrders = (orders) => {
        if (!orders) {
            return
        }
        const images = {
            ebay: <img src="https://cdn.eu.com/connect/img/ebay.png" height="20" alt="eBay" />,
            shopware: <img src="https://cdn.eu.com/connect/partnerlogos/shopware/icon/logo.png" height="40" alt="Shopware" />,
            paypal: <img src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/paypal/icon/logo.png" height="20" alt="PayPal" />,
            amazon: <img src="https://img.winfuture.de/teaser/660/5734.jpg" height="22" alt="Amazon" />,
            amazon_pay: <img src="https://images-na.ssl-images-amazon.com/images/G/01/EPSMarketingJRubyWebsite/assets/3/images/logo-amazonpay.1fae0f1e.png" height="17" alt="AmazonPay" />,
            prepayment: <img src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/prepayment/icon/logo.png" height="20" alt="Prepayment" />,
            stripe_payment_sofort: <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw" height="20" alt="Stripe" />,
            stripe: <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/320px-Stripe_Logo%2C_revised_2016.svg.png" height="20" alt="Stripe" />,
            stripe_payment_card: <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VEnplEjfBTgYlRPkKBuyoDjLYrcROEm68tn28Q3s7gLAXqy-qw" height="20" alt="Stripe" />,
            dhl: <img className="rounded-0" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
            "Versand DHL Deutschland": <img className="rounded-0" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
            "Versand DHL Schweiz": <img className="rounded-0" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
            "Versand DHL EU": <img className="rounded-0" src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/connect/partnerlogos/dhl/logo/logo.jpg" height="20" alt="DHL" />,
            amazon_fba: <img src="https://erfolgimblut.com/wp-content/uploads/2019/02/Amazon-FBA-696x391.jpg" height="22" alt="Amazon FBA" />
        };
        return orders.map((order, index) => ({
                "orderNumber": <span>{order.id}<br /><span className="grey-text">{order.storeOrderId}</span></span>,
                "store": images[order.store],
                "address": <span>{order.addresses.shipping.fullName || order.customerData.firstName + " " + order.customerData.lastName}<br />{order.addresses.shipping.street}<br />{order.addresses.shipping.zip + " " + order.addresses.shipping.city}<br />{order.addresses.shipping.country}</span>,
                "articles": <span>{order.articles.names.map((name, index) => <span key={index}>{name}<br /><span className="grey-text">{order.articles.skus[index]}</span></span>)}</span>
        }))
    };

    execute = async (setInstructed) => {
        if(this.state.isLoading) return;
        this.setState({isLoading: true});

        try {
            if(this.props.userData?.group === "reseller"){
                let ordersByAccount = {};
                this.props.orders.forEach(order => {
                    if(ordersByAccount[order.account]){
                        ordersByAccount[order.account].push(order.id);
                    } else {
                        ordersByAccount[order.account] = [order.id];
                    }
                })
                await Promise.all(Object.keys(ordersByAccount).map(async key => {
                    await OrdersAPI.instructShipping(null, {
                        fulfillerId: this.state.selectedFulfiller,
                        orderIds: ordersByAccount[key],
                        setInstructed,
                        account: key
                    });
                }))
                this.props.toggle("instrShipping");
            } else { 
                await OrdersAPI.instructShipping(null, {
                    fulfillerId: this.state.selectedFulfiller,
                    orderIds: this.props.orders.map(o => o.id),
                    setInstructed
                });
                this.props.toggle("instrShipping", true);
            }
           
        } catch (error) {
            console.log(error)
        }
        this.setState({isLoading: false});
    }

    changeFulfiller = e => {
        this.setState({selectedFulfiller: e.target.value})
    }

    render= () => {
        const data_checkboxes = {
            columns: [
              {
                'label': 'Order number',
                'field': 'orderNumber',
                'sort': 'asc'
              },
              {
                'label': 'Store',
                'field': 'store',
                'sort': 'asc'
              },
              {
                'label': 'Address',
                'field': 'address',
                'sort': 'asc'
              },
              {
                'label': 'Articles',
                'field': 'articles',
                'sort': 'asc'
              },
             
            ]
          };
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => {this.props.toggle("instrShipping")}} centered size="xl">
          <MDBModalBody>
          <MDBCard style={{margin: "-1rem"}}>
        <MDBCardImage
            className='teal white-text d-flex justify-content-center align-items-center flex-column p-2 mb-4'
            tag='div'
          >
            <h2>Instruct shipment</h2>
          </MDBCardImage>
          </MDBCard>
          <Table responsive className="text-center">
                 <TableHead columns={data_checkboxes.columns} />
                 <TableBody rows={this.renderOrders(this.props.orders)} />
            </Table>
            <MDBRow className="mt-5">
                <MDBCol size="12" md="6" className="mx-auto">
                {this.state.fulfillers && this.renderSelect(this.state.fulfillers)}
                </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn outline color="danger" onClick={() => {this.props.toggle("instrShipping")}}>Cancel</MDBBtn>
            <MDBBtn
            onClick={() => this.execute(true)}
            >
            {this.state.isLoading ?
                <div className="spinner-grow spinner-small white-text" role="status"></div>
            :
                "Instruct"
            }</MDBBtn>
            
            {/* <MDBBtn color=" rgba-grey-strong" onClick={() => {this.execute(false)}}>Only instruct</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={() => {this.execute(true)}}>Instruct & change status</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
    )
    }
}

const mapStateToProps = (state, ownProps) => ({
    userData: state.userData,
    ...ownProps
})

 export default connect(
    mapStateToProps, null
 )(instrShipping)