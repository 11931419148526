import { Component, useState } from 'react';
import queryString from 'query-string';
import { MDBContainer, MDBIcon } from 'mdbreact';
import {API} from 'aws-amplify';
 
 export default function OAuth_Amazon() {
    const [qsParams, setQsParams] = useState({})
    const [setupState, setSetupState] = useState("open")

    const setupAmazon = async () => {
        //await new Promise(resolve => setTimeout(resolve, 5000));
        try {
            const authorizeRes = await API.post("ConnectApi", "/oauth/amazon/exchangetoken", {body: {
                authCode: qs.spapi_oauth_code,
                sellingPartnerId: qs.selling_partner_id
            }})
            console.log("authorizeRes", authorizeRes)
            setSetupState("success")
        } catch (error) {
            console.log("authorizeRes Failed", authorizeRes)
            setSetupState("failed")
        }
        
    }

    const qs = queryString.parse(window.location.search);
    console.log(qs);
    if (qs.spapi_oauth_code && qs.spapi_oauth_code !== qsParams.authCode) {
        console.log("Exchanging Token")
        setQsParams({authCode: qs.spapi_oauth_code, sellingPartnerId: qs.selling_partner_id})
        setupAmazon()
        
    } else {
        console.log("no code found")
    }

    const renderLoading = setupState => {
        switch (setupState) {
            case "open":
                return <MDBContainer className="text-center">
                     <div className="spinner-grow text-default" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <h5>Setting up Amazon...</h5>
                    <p className="mt-5" style={{ fontSize: 8}}>Code: {qsParams.authCode} | Selling Partner ID: {qsParams.sellingPartnerId}</p>
                </MDBContainer>
            break;
            case "success":
                return <MDBContainer className="text-center">
                        <MDBIcon fa icon="check" className="teal-text" style={{fontSize: 20}} />
                        <h5>Setup successful</h5>
                </MDBContainer>
            break;
            case "failed":
                return <MDBContainer className="text-center">
                        <MDBIcon fa icon="exclamation-triangle" className="teal-text" style={{fontSize: 20}} />
                        <h5>Setup failed</h5>
                </MDBContainer>
             break;
                    
            default:
                break;
        }
    }
   
   return renderLoading(setupState)
 }