import { Component } from "react";
import { 
    CardHeader,
    Button,
    Input,
    MDBRow,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBTabPane
 } from "mdbreact";
import {SettingsAPI} from "../../Api/connect";



class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "solectric",
            settings: {},
            profile: "",
            isLoading: true
        }

    }

    async componentDidMount() {
        try {

           const settings = await SettingsAPI.getSettings();
            this.setState({settings: settings.settings, isLoading: false});
            console.log(settings);
        } catch (error) {
            console.log(error)
            
        }
       
    }

toggleTabs = tab => () => {
    if (this.state.activeTab !== tab) {
    this.setState({
        activeTab: tab
    });
    }
    }

updateSettings = async () => {
    try {

        const updateRes = await SettingsAPI.updateSettings(null, {settings: this.state.settings});
         console.log(updateRes);
     } catch (error) {
         console.log(error)
         
     }
}

updateChanges = (fulfillerId, item, value) => {
    console.log(fulfillerId + item + value);
    this.setState(state => ({
        settings: {
            ...state.settings,
            fulfillers: {
                ...state.settings.fulfillers,
                [fulfillerId]: {
                    ...state.settings.fulfillers[fulfillerId],
                    [item]: value
                }
            }
        }
    })
    )
}
 
render() {
    return !this.state.isLoading &&
        <section>
           <MDBRow>
               <MDBCol sm="4">
                <MDBListGroup className="my-4 mx-4" style={{ width: "22rem" }}>
                        <MDBListGroupItem color="light" onClick={this.toggleTabs("solectric")}>Solectric</MDBListGroupItem>
                        <MDBListGroupItem color="light" onClick={this.toggleTabs("rayline")}>Rayline</MDBListGroupItem>
                    </MDBListGroup>
               </MDBCol>
                <MDBCol sm="8">
                <MDBTabContent className="card p-0" activeItem={this.state.activeTab}>
                <CardHeader color="stylish-color">{this.state.activeTab.charAt(0).toUpperCase() + this.state.activeTab.slice(1)}</CardHeader>
                <MDBTabPane tabId="solectric">
                <MDBRow>
                        <MDBCol className="p-5">
                            <MDBRow>
                                <MDBCol>
                                <Input type="text" label="Name" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                />
                                </MDBCol>
                                <MDBCol>
                                <Input type="text" label="ID" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                /> 
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                <h5>Address</h5>
                                <Input type="textarea" label="Address" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                />
                                </MDBCol>
                                <MDBCol>
                                <h5>Contact</h5>
                                <Input type="text" label="Email" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                /> 
                                <Input type="text" label="Phone" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                /> 
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                <h5>Financial</h5>
                                <Input type="text" label="IBAN" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                />
                                <Input type="text" label="BIC" 
                                onChange={(e) => this.updateChanges("solectric", "email", e.target.value)}
                                />
                                </MDBCol>
                                <MDBCol>
                                <h5>Shippment Providers</h5>
                                
                                </MDBCol>
                            </MDBRow>
                       
                         <div className="w-100 text-center">
                        <Button outline onClick={this.updateSettings}>Save Settings</Button>
                        </div>
                        </MDBCol>
                </MDBRow>
                </MDBTabPane>
                <MDBTabPane tabId="rayline">
                <MDBRow>
                        <MDBCol className="p-5">
                        <select
                        className="browser-default custom-select"
                        value={this.state.settings.fulfillers.sascha && this.state.settings.fulfillers.sascha.type}
                        style={{backgroundColor: "transparent", border: "none", borderBottom: "1px solid #ced4da", borderRadius: "0"}}
                        onChange={(e) => this.updateChanges("sascha", "type", e.target.value)}>
                        <option>Choose your option</option>
                        <option value="api">API</option>
                        <option value="email">E-Mail</option>
                        </select>
                        <Input type="text" label="Email Address" 
                        value={this.state.settings.fulfillers.sascha && this.state.settings.fulfillers.sascha.email}  
                        onChange={(e) => this.updateChanges("sascha", "email", e.target.value)}
                        />
                        <div className="w-100 text-center">
                        <Button outline onClick={this.updateSettings}>Save Settings</Button>
                        </div>
                        </MDBCol>
                </MDBRow>
                </MDBTabPane>
               
                </MDBTabContent>
                </MDBCol>
           </MDBRow>
        </section>
        
}
  
};

export default Settings;