import { Component, useState, useEffect } from "react";
import { 
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBListGroup,
    MDBListGroupItem,
    MDBIcon,
    MDBInput,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBBtn,
    MDBChip,
    MDBBtnGroup
 } from "mdbreact";
 import { useTranslation } from "react-i18next";
 import {API} from "aws-amplify";
 import {ProductsAPI, SettingsAPI} from "../../Api/connect";
 import CreateAttributeModal from "../../components/products/AttributeCreate";
 // import EditCategoryModal from "../../components/products/CategoryEdit";
 import { WithContext as ReactTags } from 'react-tag-input';
 import DeleteAttributesModal  from "../../components/products/DeleteAttributes";




export default function Attributes() {
    const { t } = useTranslation();

    const [modals, setModals] = useState({
        deleteAttributes: {
            isOpen: false
        }
    })

    const [attributes, setAttributes] = useState([])

    const [attributesToDelete, setAttributesToDelete] = useState([])

    const getAttributes = async () => {
        try {
            const attributes = await ProductsAPI.getAttributes();
            setAttributes(attributes)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAttributes()
    }, [])


    const [selectAllChecked, setSelectAllChecked] = useState(false)
    const [selectedAttributes, setSelectedAttributes] = useState([])

    const handleSelectAll = () => {
        setSelectedAttributes(attributes)
    }
    const handleSelect = (attribute, isSelected) => {
        if (isSelected) {
            setSelectedAttributes(old => ([
                ...old,
                attribute
            ]))
        } else {
            setSelectedAttributes(oldSelected => {
                let newSelected = [...oldSelected];
                const index = newSelected.findIndex(e => e.id === attribute.id);
                console.log("index", index)
                newSelected.splice(newSelected.findIndex(e => e.id === attribute.id), 1);
                return newSelected
            })
        }
       
    }


    // Add Attribute

    const [addAttributeActive, setAddAttributeActive] = useState(false)
    const [newAttribute, setNewAttribute] = useState({})

    // Tags
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const [variants, setVariants] = useState([])
    useEffect(() => {
      setNewAttribute(attribute => ({...attribute, variants: variants.map(v => v.text)}))
    }, [variants])
    
    
    const handleDelete = i => {
        setVariants(variants.filter((tag, index) => index !== i));
    };
    const handleAddition = tag => {
        setVariants([...variants, tag]);
    };
    const handleDrag = (tag, currPos, newPos) => {
        const newTags = variants.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        setVariants(newTags);
    };
    const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
    };

    const handleSave = async () => {
        const newAttributeRes = await ProductsAPI.createAttribute(null, {
            attribute: newAttribute
        });
        console.log("newAttr", newAttributeRes)
        setAttributes(attributes => ([...attributes, newAttributeRes]))
        setVariants([])
        setNewAttribute({})
        setAddAttributeActive(false)
        
    }

    const TagsComponent = props => {
        if(props.isEdit) {
            return <ReactTags
            tags={props.inititalVariants.map(v => ({id: v, text: v}))}
            inputFieldPosition="top"
            id={"edit" + props.attributeId}
            placeholder="Add Variant"
            autofocus={true}
            delimiters={delimiters}
            handleDelete={(index) => setAttributes(oldAttributes => oldAttributes.map(attribute => {
                if (attribute.id === props.attributeId) {
                    const newVariants = attribute.variants
                    newVariants.splice(index, 1)
                    return {
                        ...attribute,
                        variants: newVariants
                    }
                } else return attribute
            }))}
            handleAddition={variant => setAttributes(oldAttributes => oldAttributes.map(attribute => {
                if (attribute.id === props.attributeId) {
                    console.log("adding", variant)
                    return {
                        ...attribute,
                        variants: [
                            ...attribute.variants,
                            variant.text
                        ]
                    }
                } else return attribute
            }))}
            handleDrag={(variant, currPos, newPos) => setAttributes(oldAttributes => oldAttributes.map(attribute => {
                if (attribute.id === props.attributeId) {
                    const newVariants = attribute.variants.slice();
                    newVariants.splice(currPos, 1);
                    newVariants.splice(newPos, 0, variant.text);
                    return {
                        ...attribute,
                        variants: newVariants
                    }
                } else return attribute
            }))}
            handleTagClick={handleTagClick}
            classNames={{
                tagInput: "md-form md-outline",
                tagInputField: "form-control",
                tag: "chip",
                remove: "close"
            }}
            />
        } else {
            return <ReactTags
            tags={variants}
            inputFieldPosition="top"
            placeholder="Add Variant"
            autofocus={true}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            classNames={{
                tagInput: "md-form md-outline",
                tagInputField: "form-control",
                tag: "chip",
                remove: "close"
            }}
            />
        }
        
    }

    // Actions

    const toggleModal = (id, requestRefresh) => {
        setModals(oldModals => ({
            ...oldModals,
            [id]: {
                isOpen: !oldModals[id].isOpen
            }
        }))
        if(requestRefresh) {
            getAttributes()
        }
    }

    const discardAddAttribute = () => {
        setVariants([])
        setNewAttribute({})
        setAddAttributeActive(false)
    }

    const toggleEditAttribute = id => {
        setAttributes(oldAttributes => oldAttributes.map(attribute => {
            if (attribute.id === id) {
                return {
                    ...attribute,
                    isOpenEdit: true
                }
            } else return attribute
        }))
    }

    const handleDiscardEditAttribute = id => {
        setAttributes(oldAttributes => oldAttributes.map(attribute => {
            if (attribute.id === id) {
                return {
                    ...attribute,
                    isOpenEdit: false
                }
            } else return attribute
        }))
    }

    const handleChangeEditAttribute = (id, field, value) => {
        setAttributes(oldAttributes => oldAttributes.map(attribute => {
            if (attribute.id === id) {
                return {
                    ...attribute,
                    [field]: value
                }
            } else return attribute
        })) 
    }

    const handleEditSave = async id => {
        const attributeToSave = attributes.find(a => a.id === id)
        delete attributeToSave.isOpenEdit

        setAttributes(oldAttributes => oldAttributes.map(attribute => {
            if (attribute.id === id) {
                delete attribute.isOpenEdit
                return {
                    ...attribute
                }
            } else return attribute
        }))

        await API.put("ConnectApi", "/attributes", {queryStringParameters: { id }, body: {attribute: attributeToSave}})
    }

    const handleDeleteAttribute = attribute => {
        setAttributesToDelete([attribute]);
        toggleModal("deleteAttributes")
    }

    const handleDeleteSelectedAttributes = () => {
        setAttributesToDelete(selectedAttributes);
        toggleModal("deleteAttributes")
    }

    

    // Render helpers

    const renderAttributesList = attributes => {
        return attributes.map(attribute => {
            if (attribute.isOpenEdit) {
                return {
                    check: "",
                    name: <MDBInput outline label="Name" id={"editAttribute" + attribute.id} containerClass="ml-5 mt--3" value={attribute.name} onChange={(e) => handleChangeEditAttribute(attribute.id, "name", e.target.value)} />,
                    variants: <TagsComponent isEdit={true} attributeId={attribute.id} inititalVariants={attribute.variants} />,
                    action: <MDBBtnGroup>
                        <MDBBtn outline size="sm" onClick={() => handleDiscardEditAttribute(attribute.id)} >Discard</MDBBtn>
                        <MDBBtn size="sm" onClick={() => handleEditSave(attribute.id)} > Save </MDBBtn>
                    </MDBBtnGroup>
                }
            } else {
                return {
                    check: <MDBInput outline label=" " type="checkbox" id={attribute.id.toString()} onChange={(e) => handleSelect(attribute, e.target.checked)} />,
                    name: attribute.name,
                    variants: attribute.variants.map(variant => <MDBChip className="mt-3">{variant}</MDBChip>),
                    action: <span>
                                <MDBIcon icon="edit" onClick={() => toggleEditAttribute(attribute.id)} />
                                <MDBIcon className="ml-3 red-text" icon="trash" onClick={() => handleDeleteAttribute(attribute)} />
                            </span>
                }
            }
        });
    }

    const renderCreateAttribute = () => {
        return [{
            check: "",
            name: <MDBInput outline label="Name" containerClass="ml-5" id="newAttribute" value={newAttribute.name} onChange={(e) => setNewAttribute(attribute => ({...attribute, name: e.target.value}))} />,
            variants:  <TagsComponent isEdit={false} />,
            action: <><MDBBtn outline size="sm" onClick={() => handleSave()} >Save</MDBBtn><br></br><MDBIcon className="red-text" far icon="times-circle" onClick={() => discardAddAttribute()} /></>
        }]
    }


    return (
        <section>
            <MDBRow>
                    <MDBCol>
                        <span className="float-left teal-grey-text ml-2" style={{cursor: "pointer"}} onClick={() => setAddAttributeActive(true)}><MDBIcon icon="plus" style={{fontSize: "small"}} /> {t("gen.create", "Create")}</span>
                        {/* <span className="float-right teal-grey-text" style={{cursor: "pointer"}}><MDBIcon icon="file-export" style={{fontSize: "small"}} /> {t("gen.export", "Export")}</span>
                        <span className="float-right teal-grey-text" style={{cursor: "pointer"}} onClick={() => this.toggleModal("importProducts")}><MDBIcon icon="file-import" style={{fontSize: "small"}} /> {t("gen.import", "Import")} |&nbsp;</span>  */}
                    </MDBCol>
                </MDBRow>
                <MDBCard className="mt-3">
                    <MDBCardBody className="p-0">
                        <MDBTable responsive className="text-center">
                            {/* <TableHead  color="teal" textWhite columns={data_checkboxes.columns} /> */}
                            <thead data-test="table-head" className="text-white teal">
                                <tr>
                                    <th className="" style={{width: "20px"}}>
                                    
                                    <MDBInput checked={selectAllChecked} label=" " type="checkbox" id="checkbox1" containerClass="form-check checkbox-white" labelClass="form-check-label" onChange={() => handleSelectAll()} />
                                    </th>
                                    {selectedAttributes.length > 0 ? 
                                    <>
                                    <th className="text-left py-0" style={{paddingRight: "3em", minWidth: "300px"}}>
                                        <div style={{
                                            backgroundColor: "rgb(255 255 255 / 20%)",
                                            color: "#fff",
                                            height: "40px",
                                            marginBottom: "7.5px",
                                            marginLeft: "25px",
                                            borderRadius: "3px"
                                        }} className="p-2 d-flex justify-content-around">
                                        <span className="my-auto" onClick={() => handleDeleteSelectedAttributes()} style={{cursor: "pointer"}}><MDBIcon icon="trash" /> {t("gen.delete", "Delete")}</span>
                                        
                                        </div>
                                    </th>
                                    </>
                                    
                                    :
                                    <>
                                    <th className="">{t("gen.name", "Name")}</th>
                                    </>
                                    }
                                    
                                    {/* <th className="">{t("gen.category", "Category")}</th>
                                    <th className="">{t("gen.cost", "Cost")}</th> */}
                                    <th className="">{t("gen.variants", "Variants")}</th>
                                    <th className="" style={{minWidth: "100px"}}>{t("gen.action", "Action")}</th>
                                </tr>
                            </thead>
                            <MDBTableBody rows={!addAttributeActive ? renderAttributesList(attributes) : renderCreateAttribute().concat(renderAttributesList(attributes))} />
                            
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
                <DeleteAttributesModal isOpen={modals.deleteAttributes.isOpen} attributes={attributesToDelete} toggle={toggleModal} />
        </section>
    )
}