import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Products from "./pages/Products/Products";
import Categories from "./pages/Products/Categories";
import Attributes from "./pages/Products/Attributes";
import Distribute from "./pages/Products/Distribute";
import EditProduct from "./components/products/EditProduct";
import Tickets from "./pages/Support/Tickets";


import SettingsUsers from "./pages/Settings/Users";
import SettingsMarketplaces from "./pages/Settings/Marketplaces";
import SettingsFulfillers from "./pages/Settings/ShippingProviders";
import SettingsFulfillment from "./pages/Settings/Fulfillment";
import SettingsPartners from "./pages/Settings/Partners";
import SettingsAccounting from "./pages/Settings/Accounting";
import SettingsLocations from "./pages/Settings/Locations";

import FinancialBills from "./pages/Financial/Bills";
import CreateListings from "./pages/Multichannel/CreateListings";
import ActiveListings from "./pages/Multichannel/ActiveListings";

import PricingRules from "./pages/Pricing/Rules";

import OAuthEbay from "./pages/OAuth/ebay";
import OAuthShopify from "./pages/OAuth/shopify";
import OAuthShopify_Request_Install from "./pages/OAuth/Shopify_Request_Install";
import OAuthAmazon from "./pages/OAuth/amazon";
import ToolsProductEarnings from "./pages/Tools/ProductEarnings";

import Rules_Orders from "./pages/Settings/Rules/orders";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/orders" component={Orders} />
        <Route path="/products" exact component={Products} />
        <Route path="/products/categories" exact component={Categories} />
        <Route path="/products/attributes" exact component={Attributes} />
        <Route path="/products/distribute" exact component={Distribute} />
        <Route path="/products/edit/:id" component={EditProduct} />
        <Route path="/pricing/rules" exact component={PricingRules} />
        <Route path="/support/tickets" component={Tickets} />
        <Route path="/settings/users" component={SettingsUsers} />
        <Route path="/settings/marketplaces" component={SettingsMarketplaces} />
        <Route path="/settings/fulfillment" component={SettingsFulfillment} />
        <Route path="/settings/shipping" component={SettingsFulfillers} />
        <Route path="/settings/partners" exact component={SettingsPartners} />
        <Route path="/settings/accounting" exact component={SettingsAccounting} />
        <Route path="/settings/locations" exact component={SettingsLocations} />
        <Route path="/financial/bills" exact component={FinancialBills} />
        <Route path="/multichannel/createlistings" exact component={CreateListings} />
        <Route path="/multichannel/activelistings" exact component={ActiveListings} />

        <Route path="/settings/oauth/ebay" component={OAuthEbay} />
        <Route path="/settings/oauth/shopify" component={OAuthShopify} />
        <Route path="/shopify" component={OAuthShopify_Request_Install} />
        <Route path="/oauth/amazon" component={OAuthAmazon} />
        <Route path="/tools/product-earnings" component={ToolsProductEarnings} />


        <Route path="/settings/rules/orders" component={Rules_Orders} />

        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
