import {Auth} from "aws-amplify";

  export default {
    Auth: {
      mandatorySignIn: false,
      region: "eu-central-1",
      userPoolId: "eu-central-1_8m9tPF43r",
      userPoolWebClientId: "2lft9v2ikv6ol5c50aah82ja2o",
      identityPoolId: "eu-central-1:535d6d90-63eb-49a1-8679-07b6553af0dc"
    },
    API: {
      endpoints: [
        {
          name: "ConnectApi",
          endpoint: "https://api.cdn.eu.com" + "/ytcc-dev/",
          region: "eu-central-1",
          custom_header: async () => { 
            return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
          }
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'ytec-connect-media',
        region: 'eu-central-1',
        identityPoolId: "eu-central-1:535d6d90-63eb-49a1-8679-07b6553af0dc"
    }
    }
  }