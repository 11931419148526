import React, { Fragment, useState, useEffect } from 'react'
import { 
    CardHeader,
    Input,
    MDBRow,
    MDBCol,
    MDBCollapse ,
    MDBListGroup,
    MDBListGroupItem,
    MDBTabContent,
    MDBIcon,
    MDBTabPane,MDBTable, MDBTableBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBBtn, MDBInput, MDBInputGroup, MDBCard, MDBCardTitle, MDBCardBody, MDBCardText, MDBCardImage
 } from "mdbreact";
 import { useSelector, useDispatch } from 'react-redux'
 import {API} from "aws-amplify";
 import { addActiveMarketplace } from "../../../features/marketplaces";

export default function New_Marketplace(props) {
    const dispatch = useDispatch()
    // Get available marketplaces
    const [availableMarketplaces, setAvailableMarketplaces] = useState([])

    const getAvailableMarketplaces = async () => {
        try {
            const res = await API.get("ConnectApi", "system/availableproviders", {queryStringParameters: {type: "marketplaces"}})
            setAvailableMarketplaces(res.available)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if(availableMarketplaces.length === 0) {
            getAvailableMarketplaces()
        }
    }, [])

    const activeMarketplaces = useSelector((state) => state.marketplaces.active)

    const activateMarketplace = async (marketplace) => {
        
        try {
            await API.post("ConnectApi", "/settings/marketplaces/activate", {body: {marketplace}})
            dispatch(addActiveMarketplace(marketplace))
        } catch (error) {
            console.log(error)
        }
        
    }

  return (
    <Fragment>
        <h4 className="mt-5 mx-4">Connect new Marketplace</h4>
         <MDBRow className="p-2 p-md-4">
            {availableMarketplaces.length > 0 && availableMarketplaces.map(marketplace => {
                const isActive = activeMarketplaces.map(m => m.handle).indexOf(marketplace.handle) >= 0

                return <MDBCol size="6" md="4" className="mb-3">
                <MDBCard
                className={isActive ? "rgba-green-slight border border-success" : ""}
                onClick={() => {
                    if(!isActive) {
                        activateMarketplace(marketplace)
                    } else {
                        console.log("toggle");
                        const toggle = props.toggleMarketplace(marketplace.handle)
                        toggle()
                    }
                }}
                >
                    <MDBCardImage  className="mx-auto pt-3" style={{height: "50px"}}src={marketplace.logoUrl} />
                    <MDBCardBody className="mt-2 p-2">
                        <MDBCardText className="text-center">
                        <MDBBtn outline size="sm">{isActive ? "Configure" : "Activate"}</MDBBtn>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            })}
        </MDBRow>
    </Fragment>
  )
}
