import { Component } from 'react';
import { MDBDataTable, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol } from 'mdbreact';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export default class ViewBill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dueDays: 40
        }
    }
   

    handleChange = e => {
        const id = e.target.id;
        this.setState({
            [id]: e.target.value
        })
    }


    render= () => {
        let billD = this.props.bill && new Date(this.props.bill.invoiceDate.split('.').reverse().join('/'));
        //const dueDay = billD && new Date(billD.getTime() + this.state.dueDays * 86400000);
        //const remaining = dueDay && parseInt((dueDay.getTime() - new Date().getTime())/(24*3600*1000));
    return (
        <MDBModal isOpen={this.props.isOpen} toggle={() => this.props.toggle("viewBill")} centered size="xl">
          <MDBModalHeader toggle={() => {this.props.toggle("viewBill")}}>View Bill</MDBModalHeader>
          <MDBModalBody>
          
            <MDBRow className="mt-5">
                <MDBCol size="12" md="11" className="mx-auto">
                <MDBRow>
                    <MDBCol size= "12" md="7">
                        <MDBDataTable
                        autoWidth
                        striped
                        info={false}
                        searching={false}
                        paging={false}
                        data={{
                            columns: [
                                {
                                    label: 'Name',
                                    field: 'name',
                                    sort: 'asc'
                                },
                                {
                                    label: 'manufacturerSKU',
                                    field: 'manufacturerSKU',
                                    sort: 'asc'
                                },
                                {
                                    label: 'QTY',
                                    field: 'qty',
                                    sort: 'asc'
                                },
                                {
                                    label: 'EK',
                                    field: 'ek',
                                    sort: 'asc'
                                },
                                {
                                    label: '%',
                                    field: 'discount',
                                    sort: 'asc'
                                }
                            ],
                            rows: this.props.bill && this.props.bill.products.map(p => ({
                                name: p.name,
                                manufacturerSKU: p.manufacturerSKU,
                                qty: p.qty,
                                ek: p.ek + " €",
                                discount: p.discout && p.discout + "%",
                                ekSystem: this.props.bill.missmatches && this.props.bill.missmatches.filter(miss => miss.ek).length > 0 &&
                                            <span className="font-weight-bold">{this.props.bill.missmatches.filter(miss => miss.manufacturerSKU === p.manufacturerSKU)[0].ek} €</span>
                                }))
                        }}
                        />
                    </MDBCol>
                    <MDBCol size= "12" md="5">
                    {this.props.bill &&
                        <MDBRow>
                            <MDBCol size= "12" md="5">
                                Invoice ID:<br></br>
                                Order ID:<br></br>
                                Delivery ID:<br></br>
                                <br></br>
                                Invoice Date:<br></br>
                                Due Date:<br></br>
                                <br></br>
                                Arranger:<br></br>
                                <br></br>
                                <hr></hr>
                                Total Products:<br></br>
                                Shipping:<br></br>
                                Tax:<br></br>
                                Total:
                            </MDBCol>
                            <MDBCol size= "12" md="7">
                                {this.props.bill.invoiceId}<br></br>
                                {this.props.bill.orderId}<br></br>
                                {this.props.bill.deliveryId}<br></br>
                                <br></br>
                                {this.props.bill.invoiceDate}<br></br>
                                {billD && new Date(new Date(this.props.bill.invoiceDate.split('.').reverse().join('/')).getTime() + this.state.dueDays * 86400000).toISOString().substring(0,10).split("-").reverse().join(".")}<br></br>
                                <br></br>
                                {this.props.bill.arranger}<br></br>
                                <br></br>
                                <hr></hr>
                                {(this.props.bill.totalAmountNet - (this.props.bill.shippingCost || 0)).toFixed(2)} €<br></br>
                                {this.props.bill.shippingCost || 0} €<br></br>
                                {this.props.bill.totalVat} €<br></br>
                                {this.props.bill.totalAmount} €<br></br>
                            </MDBCol>
                        </MDBRow>
                    }
                    </MDBCol>
                </MDBRow>
                </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color=" rgba-red-strong" onClick={() => {this.props.toggle("viewBill")}}>Cancel</MDBBtn>
            <MDBBtn color=" rgba-grey-strong" onClick={() => {this.execute(true)}}>Draft</MDBBtn>
            <MDBBtn color=" rgba-stylish-strong" onClick={this.execute}>Save</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    )
    }
}