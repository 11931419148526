import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {API} from "aws-amplify";



export const fulfillers = createSlice({
    name: "fulfillers",
    initialState: {},
    reducers: {
        setFulfillers: (state, action) => {
            return action.payload //Object.keys(action.payload).map(key => ({...action.payload[key]}))
        },
        addActiveFulfiller: (state, action) => {
            state[action.payload.handle] = action.payload
        }
    },
    // extraReducers: (builder) => {
    //     // Add reducers for additional action types here, and handle loading state as needed
    //     builder.addCase(fetchActiveMarketplaces.fulfilled, (state, action) => {
    //       return action.payload
    //     })
    //   },
})

export const { setFulfillers, addActiveFulfiller } = fulfillers.actions

export default fulfillers.reducer