import React, { useState, useEffect } from 'react'
import {useParams} from "react-router-dom";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBIcon,
    MDBInput,
    MDBBtn
} from "mdbreact";
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';

import {useDropzone} from 'react-dropzone';


import TabGeneral from "./EditProduct/General"
import TabMedia from "./EditProduct/Media"
import TabPricing from "./EditProduct/Pricing"
import TabStockShip from "./EditProduct/StockShip"
import TabBundles from "./EditProduct/Bundles"
import TabVariants from "./EditProduct/Variants"
import TabMarketplaceListings from "./EditProduct/Marketplacelistings"



export default function EditProduct() {
    const { t } = useTranslation();
    const { id } = useParams();

    const [activeTab, setActiveTab] = useState("general")
    const toggleTab = tab => () => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const [product, setProductData] = useState({})
    const getProduct = async () => {
        const productRes = (await API.get("ConnectApi", "articles", {queryStringParameters: {id}}))[0]
        setProductData(productRes)
    }

    const handleChange = (path, value) => {
        setProductData(prevProduct => {
            let newProduct = {...prevProduct}
            const setPath = (object, path, value) => path.split('.').reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
            setPath(newProduct, path, value)
            return newProduct
        })
    }

    const updateProdukt = async () => {
        const updateRes = await API.post("ConnectApi", "articles/update", {queryStringParameters: {id}, body: product})
        console.log(updateRes)
    }



    useEffect(() => {
      getProduct()
    }, [])
    

    return (
        <>
            <MDBNav tabs className="teal">
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "general"}
                        onClick={toggleTab("general")}
                        role="tab">
                        General
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "media"}
                        onClick={toggleTab("media")}
                        role="tab">
                        Media
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "pricing"}
                        onClick={toggleTab("pricing")}
                        role="tab">
                        Pricing
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "stockship"}
                        onClick={toggleTab("stockship")}
                        role="tab">
                        Stock & Logistics
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "variants"}
                        onClick={toggleTab("variants")}
                        role="tab">
                        Variants
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "bundles"}
                        onClick={toggleTab("bundles")}
                        role="tab">
                        Bundles
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        active={activeTab === "marketplacelistings"}
                        onClick={toggleTab("marketplacelistings")}
                        role="tab">
                        Marketplace Listings
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem className="float-right" style={{marginLeft: "auto"}}>
                    <MDBNavLink
                        link
                        className="p-0 pt-1"
                        role="tab">
                        <MDBBtn outline size="sm" color="white" className="m-0 float-right" onClick={() => updateProdukt()}><MDBIcon icon="sync" /> Save</MDBBtn>
                    </MDBNavLink>
                </MDBNavItem>
            </MDBNav>
            <MDBTabContent className="card p-md-5" activeItem={activeTab}>
                <MDBTabPane tabId="general" role="tabpanel">
                    <TabGeneral product={product} handleChange={handleChange} />
                </MDBTabPane>
                <MDBTabPane tabId="media" role="tabpanel">
                    <TabMedia product={product} handleChange={handleChange} isActive={activeTab === "media"} />
                </MDBTabPane>
                <MDBTabPane tabId="pricing" role="tabpanel">
                    <TabPricing product={product} handleChange={handleChange} />
                </MDBTabPane>
                <MDBTabPane tabId="stockship" role="tabpanel">
                   <TabStockShip product={product} handleChange={handleChange} />
                </MDBTabPane>
                <MDBTabPane tabId="bundles" role="tabpanel">
                    <TabBundles product={product} handleChange={handleChange} />
                </MDBTabPane>
                <MDBTabPane tabId="variants" role="tabpanel">
                    <TabVariants product={product} handleChange={handleChange} />
                </MDBTabPane>
                <MDBTabPane tabId="marketplacelistings" role="tabpanel">
                    <TabMarketplaceListings product={product} handleChange={handleChange} />
                </MDBTabPane>
            </MDBTabContent>
        </>
    )
}
