import { MDBRow } from 'mdbreact';

export default () => {
  return (
    <>
    <MDBRow>
        <div className="mx-auto my-5">
                <div className="spinner-grow teal-text" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
        </div>
    </MDBRow>
    </>
  );
}