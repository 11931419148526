import { MDBBtn, MDBIcon } from 'mdbreact';
import React from 'react';

export default (props) => {
  const onDragStart = (event, nodeType, optionData) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('application/reactflow/optionData', JSON.stringify(optionData));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside>
      <div className="description">Available elements</div>
      {props.availableActions.map(a => 
      <div className="dndnode" onDragStart={(event) => onDragStart(event, a.handle, a)} draggable>
      <MDBIcon icon={a.icon} className="mr-2" /> {a.name}
      </div>)}
    </aside>
  );
};
