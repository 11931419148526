import { MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBCardTitle, MDBCardText, MDBInput, MDBBtn, MDBCardFooter } from 'mdbreact'
import React from 'react'
import { useState, useEffect } from 'react'
import {API} from "aws-amplify"

export default function Locations() {

    const [newLocationForm, updateNewLocationForm] = useState({})
    const [addNewLocationRes, setNewLocationRes] = useState({finished: false, success: null, message:""})
    const [isLoading, setIsLoading] = useState({locations: false, addNewLodation: false})

    const [locations, setLocations] = useState([])

    useEffect(() => {
        getLocations()
    }, [])


    const getLocations = async () => {
        setIsLoading({...isLoading, locations: true})
        try {
            const resLocations = await API.get("ConnectApi", "settings/component", {queryStringParameters: {component: "locations"}})
            if(resLocations) setLocations(resLocations.locations)
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, locations: false})
    }


    const addLocation = async () => {
        setIsLoading({...isLoading, addNewLodation: true})
        try {
            await API.post("ConnectApi", "settings/locations", {body: {data: newLocationForm}})
            setNewLocationRes({
                finished: true,
                success: true
            })
            updateNewLocationForm({
                name: "",
                companyName: "",
                contactPersonName: "",
                street: "",
                streetNumber: "",
                zip: "",
                city: "",
                countryCode: "",
                phone: ""
            })
            getLocations()
        } catch (error) {
            // console.log(error.response)
            setNewLocationRes({
                finished: true,
                success: false,
                message: "Error saving location"
            })
        }
        setIsLoading({...isLoading, addNewLodation: false})
    }

    const removeLocation = async (id) => {
        setIsLoading({...isLoading, ["remove-" + id]: true})
        try {
            await API.del("ConnectApi", "settings/locations", {queryStringParameters: {id}})
            setLocations(locations.filter(loc => loc.id !== id))
        } catch (error) {
            console.log(error)
        }
        setIsLoading({...isLoading, ["remove-" + id]: false})
    }

    const makeLocationDefault = async (id) => {
        setIsLoading({...isLoading, ["makeDefault-" + id]: true})
        try {
            await API.post("ConnectApi", "settings/locations/setdefault", {queryStringParameters: {id}})
            getLocations()
        } catch (error) {
            console.log(error.response)

        }
        setIsLoading({...isLoading, ["makeDefault-" + id]: false})
    }
   
    return (
    <MDBCard style={{maxWidth: "1000px"}} className="mx-auto text-center">
        <MDBCardBody>
            <h5 className="my-4">Locations:</h5>
            <MDBRow>
            {isLoading.locations ? 
                <MDBCol size="12" className="text-center"><div className="spinner-grow text-default" role="status">
                    <span className="sr-only">Loading...</span>
                </div></MDBCol>
                
            :
            locations.length > 0 && locations.map(location =><MDBCol size="6">
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle>{location.name}</MDBCardTitle>
                            <MDBCardText className="text-left">
                            {location.companyName}<br></br>
                            {location.contactPersonName}<br></br>
                            {location.street + " " + location.streetNumber}<br></br>
                            {location.zip + " " + location.city}<br></br>
                            {location.country}<br></br>
                            </MDBCardText>
                            <MDBCardFooter>
                                <MDBRow>
                                    <MDBCol size="6">
                                    {isLoading["makeDefault-" + location.id] ? 
                                        <div className="spinner-grow text-default" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    :
                                    <MDBInput label="Default" type="checkbox" checked={location.default} id={"makeDefault-" + location.id} onChange={(e) => e.target.checked && makeLocationDefault(location.id)} />
                                    }
                                    </MDBCol>
                                    <MDBCol size="6" className="text-right red-text">
                                    {isLoading["remove-" + location.id] ? 
                                        <div className="spinner-grow text-default" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    :
                                    <span onClick={(e) => removeLocation(location.id)}>Remove</span>
                                    }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardFooter>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>)
            }
            
                
            </MDBRow>
            <h5 className="my-4">Add new Location</h5>
            <MDBRow className="px-md-5">
                <MDBCol size="12"><MDBInput label="Location Name" value={newLocationForm.name} onChange={(e) => updateNewLocationForm({...newLocationForm, name: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="Company Name" value={newLocationForm.companyName} onChange={(e) => updateNewLocationForm({...newLocationForm, companyName: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="Contact Person" value={newLocationForm.contactPersonName} onChange={(e) => updateNewLocationForm({...newLocationForm, contactPersonName: e.target.value})} /></MDBCol>
                <MDBCol size="8"><MDBInput label="Street" value={newLocationForm.street} onChange={(e) => updateNewLocationForm({...newLocationForm, street: e.target.value})} /></MDBCol>
                <MDBCol size="4"><MDBInput label="Street Number" value={newLocationForm.streetNumber} onChange={(e) => updateNewLocationForm({...newLocationForm, streetNumber: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="Zip" value={newLocationForm.zip} onChange={(e) => updateNewLocationForm({...newLocationForm, zip: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="City" value={newLocationForm.city} onChange={(e) => updateNewLocationForm({...newLocationForm, city: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="Phone" value={newLocationForm.phone} onChange={(e) => updateNewLocationForm({...newLocationForm, phone: e.target.value})} /></MDBCol>
                <MDBCol size="6"><MDBInput label="Country" value={newLocationForm.countryCode} onChange={(e) => updateNewLocationForm({...newLocationForm, countryCode: e.target.value})} /></MDBCol>
                <MDBCol size="12" className="text-center mt-3 mt-md-4">
                {isLoading.addNewLodation ? 
                    <div className="spinner-grow text-default" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                :
                <MDBBtn size="sm" onClick={() => addLocation()}>Add Location</MDBBtn>
                }
                {addNewLocationRes.finished && !addNewLocationRes.success && <span className="text-danger">{addNewLocationRes.message}</span> }
                </MDBCol>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
    )
}
